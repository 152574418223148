

.Resig-employ{
    width: 100%;
}

.Resig-employ input{
    width: 100% !important;
    /* border: 1ps solid #ced4da; */
}

