.leaveReqWrap{
    padding: 30px 0px;
}
.hiii_gap{
    gap: 10px;
}
.checkbox_ss{
    gap: 10px;
}
.ACTIVITYsss{
    display: flex;
    gap: 10px;
    align-items: center;
}