.permiCont{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.applyBtn{
    background-color: black;
    color: white;
    font: 500;
    width: 100px;
    
}

/* NEW CSS  */
.editrolewrap{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.editrolewrap h1{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;

    }
    .udpateBtns span{
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
color:#FFFFFF;        
    }
    .udpateBtns{
        width: fit-content;
        margin: 0 auto;
        padding: 5px 10px;
        background: #0B56E4;
        border: 1px solid #0B56E4;
        border-radius: 10px;
    }
    .inputfilabel{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
    }
    .inputfilabel p{
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.0015em;
        text-align: left;
        color: #1B2533;
    }
    .inputfilabel input{
        display: block;
        width: 100%;
        padding: 0.775rem 1rem;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        color: #1B2533;
        background-clip: padding-box;
        border: 1px solid #D0D4DC;
appearance: none;
        border-radius: 0.475rem;
        box-shadow: false;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    }
.allroleswrap{
    display: flex;
  flex-wrap: wrap;
    gap: 20px;
justify-content: center;
}

.lead_head{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.leadroleCont{
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    max-width: 350px;
    height: 260px;
    width: 100%;
    overflow-y: scroll;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #e1e3ea !important;
    border-top: 3px solid #3595F6 !important;
}

.leadroleCont::-webkit-scrollbar{
    display: none;
}
.leadroleCont h2{
    font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: left;
color: #1B2533;
}

.leadroelscond{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.singlead{
    display: flex !important;
    align-items: center !important;
    gap: 20px;
    /* background-color: red; */
    
}
.singlead span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;

}

.editrolehead{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.editrolehead button{
    width: 138px;
height: 40px;
border-radius: 10px;
background: #0B56E4;
border: 1px solid #0B56E4;
}
.editrolehead button span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
     color: #FFFFFF;
}


/* ====== properly assigned the edit and delete button under Action  */

.makethisflex {
    display: flex;
    align-items: center;
    justify-content: center; /* Ensures buttons are horizontally centered */
    gap: 15px; /* Adjust spacing between icons */
}

.makethisflex svg {
    cursor: pointer;
    color: #1B2533; /* Consistent icon color */
    transition: transform 0.2s ease; /* Smooth hover effect */
}

.makethisflex svg:hover {
    transform: scale(1.2); /* Slightly enlarges icon on hover */
}
