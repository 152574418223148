.leadLe{
    display: flex;
    align-items: center;
    gap: 10px;
}

.lTITL h2{
color: #101820;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    
}

 .uplaodin{
    width: 87px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;
    margin-top: 10px;
    
}
 .uplaodin span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
color: white;        
}
.lTITL p{
color: #49515C;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    

}

.firsSec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeLineCont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}

.timeLOverBtns{
    display: flex;
    align-items: center;

}
.timeline{
    width: 134px;
height: 42px;
border-radius: 31px ;
display: flex;
align-items: center;
justify-content: center;
background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
border: 1px solid #0B56E4;
cursor: pointer;
z-index: 20;
}
.timeline p{
    font-family: Inter;
    color: #0B56E4;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    
}
.timelin1{
    width: 174px;
height: 42px;
border-radius: 29px ;
background: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid #B3CBF7;
transform: translateX(-40px);
z-index: 10;
cursor: pointer;

}
.timelin1 p{
    font-family: Inter;
    color: #49515C;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    

}
.timelin12{
    width: 134px;
height: 42px;
border-radius: 31px ;
background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
border: 1px solid #0B56E4;
display: flex;
align-items: center;
justify-content: center;
z-index: 20;
cursor: pointer;

}
.timelin12 p{
    font-family: Inter;
    color: #0B56E4;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    
}
.noTimeline{
    width: 174px;
height: 42px;
border-radius: 29px ;
background: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
transform: translateX(40px);
z-index: 10;
cursor: pointer;
border: 1px solid #B3CBF7;

}
.noTimeline p{
    font-family: Inter;
    color: #49515C;
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    
}

.timeCount{
    display: flex;
    flex-direction: column;
    
}
.timeCount .singleTimeline{
  display: flex;
  gap: 20px;

}
.tiemLeft{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.tiemLeft p{
    font-family: Inter;
    color: #49515C;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: right;
    
}
.leadCreate{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.leadCreate .timeLeadcREA{
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    
}
.leadCreate .timeByINFO{
    font-family: Inter;
    color: #0B56E4;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}
.timeelineWrap{
    width: 100%;
min-height: 437px;
height: fit-content;
padding: 20px;
border-radius: 18px ;
background: #FFFFFF;
border: 1px solid #E8E9EB;
margin-top: 16px;
display: flex;
flex-direction: column;
gap: 16px;
}
.timeelineWrap h2{
    font-family: Inter;
    color: #101820;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    
}
.leadClonse{
    width: 118px;
height: 40px;
border-radius: 10px;
background-color: #0B56E4;
}

.leadClonse span{
    font-family: Inter;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    
}
.sendBtn{
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.005em;
text-align: left;
height: 40px;

}
.sendBtn2{
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.005em;
height: 40px;
width:144px;

}

.laedRight{
    display: flex;
    align-items: center;
    gap: 16px;

}

.refresh1{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80px;
    border: 1px solid #0B56E4;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  }

  .leadWRAP{
    display: flex;
    flex-direction: column;
    gap: 16px;

  }

  .leadFirs{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
    background-color: white;
    padding: 20px 30px;
  }

  .fornotewrap{
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .doColumn{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .leadInfowrap{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }


  .leadInfowrap p{
font-family: Inter;
color: #49515C;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.005em;
text-align: right;

  }

  .addNew{
    width: 103px;
height: 40px;
border-radius: 10px;
background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
border: 1px solid #0B56E4;

  }
  .addNew span{
    font-family: Inter;
    color: #0B56E4;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

  }

  .attcont{
    display: flex;
    justify-content: flex-start;
  }
  .noteInpuCont{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .noteInpuCont textarea{
    width: 709px;
    height: 76px;
    padding: 8px 16px ;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;    
    resize: none;
font-family: Inter;
color: #969BA1;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;

  }

  .noBtns{
     display: flex;
     align-items: center;
     justify-content: flex-end;
     gap: 16px;
     max-width: 709px;
     width: 100%;
  }

  .noBtns .ccnel{
    width: 86px;
height: 40px;
border-radius: 10px;
border: 1px solid #B8BBC0;
  }
  .noBtns .ccnel span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
color: #666D76;    
  }

  .noBtns .ssave{
    width: 70px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;
    
  }
  .noBtns .ssave span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
color: #FFFFFF;    
    
  }
  .ehading{
  
    font-family: Inter;
    color: #101820;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    }

    .eladinfoWrap{
        display: flex;
        justify-content: space-between;
    }

    .subPart{
        display: flex;
        gap: 20px;
        align-items: center;
    }
    .subPart h3{
        font-family: Inter;
        color: #1B2533;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: right;
        
    }
    .subPart p{
        font-family: Inter;
        color: #1B2533;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        
    }

    .lleaiFOlEFT{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .secondWRap p{
font-family: Inter;
color: #1B2533;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: right;

    }
    .secondWRap span{
        font-family: Inter;
        color: #1B2533;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
         margin-left: 20px;

    }

    .leadHead{
 display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

    }

    .indefy{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .indefy span{
        font-family: Inter;
        color: #0F141C;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        
    }
    .leadHead span{
        font-family: Inter;
        color: #0F141C;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        
    }

    .leadHead2{
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;
cursor: pointer;
padding: 0px 12px;

    }

    .leadWrapp2{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        padding: 0px 20px;
    }

    .mostwrap{
        display: flex;
        flex-direction: column;
    }

    .imprtleadCont{
        position: relative;
    }
    .createTaskWrap{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #4040404D ;
        z-index: 2001;
    }

    .cretTaskCont{
        width: 599px;
height: 490px;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
padding: 12px ;
border-radius: 18px ;
background: #FFFFFF;
display: flex;
flex-direction: column;
gap: 12px;

    }
    .allFolowup{
        display: flex;
        flex-direction: column;
        gap: 20px;

    }
    .allFolowup h2{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        
    }

    .singFol{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .singFol p{
font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 32px;

    }
    .cretTaskCont2{
        width: 599px;
height: 850px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
padding: 12px ;
border-radius: 18px ;
background: #FFFFFF;
display: flex;
flex-direction: column;
gap: 12px;

    }
    .addnewTx{
font-family: Inter;
width: 100%;
color: #0B56E4;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;

    }
    .cretTaskCont nav{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .cretTaskCont nav p{
        font-family: Inter;
        color: #1B2533;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.0015em;
        text-align: left;
        

    }
    .cretTaskCont2 nav{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .cretTaskCont2 nav p{
        font-family: Inter;
        color: #1B2533;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.0015em;
        text-align: left;
        

    }

    .taskForm{
        display: flex;
        flex-direction: column;
    gap: 16px;
 margin-top: 14px;
}
    .taskForm label{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
}
    .taskForm label p{
        font-family: Inter;
        color: #1B2533;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        text-align: left;
        
}
    .taskForm label input , select {
        width: 100%;
        height: 48px;
        padding: 8px 16px ;
        border-radius: 10px;
        background: #FFFFFF;
        border: 1px solid #D0D4DC;
        
}
    .taskForm .twoTask {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        
}

.btnstask{
    display: flex;
    align-items: center;
    gap: 16px;
}
.btnstask .creattk{
    width: 125px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;
    font-family: Inter;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.005em;
color: #FFFFFF;    
    
}
.btnstask .creatmt{
    width: 152px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
color: white;        
    
}
.btnstask .tkCnacel{
    width: 86px;
    height: 40px;
    border-radius: 10px;
    background: #F8F9FB;
    font-family: Inter;
    border: 1px solid #B8BBC0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.005em;
color: #666D76;        
}
/* ==Quotation cards css is here == */
.allCards{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.card {
    width: 300px;
    border-radius: 8px;
    max-height: 400px;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    position: relative;
  }
.card img {
    width: 100%;
    height: 200px;
    display: block;
  }

  .card-content {
    padding: 16px 0px 0px 0px;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .card-meta {
    font-size: 14px;
    color: #888;
    margin-top: 4px;
  }
  