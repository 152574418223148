.creteEmpWrap2{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 16px 0px;
    border: 1px solid #E8E9EB !important;
    border-radius: 10px !important;
}

.table1 th{
    background-color: white !important;
}

.table1 th ,td{
    border: none !important;
    border-bottom: 1px solid #E8E9EB !important;
}

.checkboxes{
    width: 20px;
    height: 20px;
  
}

.index{
font-family: Inter;
color: #40444B;
font-size: 12px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.005em;
text-align: left;

}

.totalRecord{
    padding: 24px 0px 8px 0px;
font-family: inter !important;
color: #1B2533;
font-size: 12px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.004em;
text-align: left;

}

.personDetaCon{
    max-width: 1075px;
    width: 100%;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 20px;
background-color: white;
padding: 20px 40px;

}
.personDetaCon h2{
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
color: #060606;    

}

.PDAllFie{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.PDAllFie .PDFirs{
   display: flex;
   flex-wrap: wrap;
   gap: 16px;
   align-items: center;
}
.PDAllFie .empName{
    width: 246px;
    height: 44px;
    border-radius: 5px;
    border: 1px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 15px;
    
}
.PDAllFie .empDate{
    width: 167px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 15px;
    
}
.PDAllFie .empEmailId{
    width: 280px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #9499A0;
    opacity: 0.7px;
    color: #9499A0;
    padding: 15px;
    
}
.PDAllFie .empAdd{
    width: 320px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding-inline-start: 15px;
    
}

.PdThird{
    display: flex;
    align-items: center;
    gap: 50px;
}
.PdThird .PdthirLeft{
   display: flex;
   align-items: center;
   gap: 10px;
}
.PdThird .PdthirLeft .pdSelec{
    width: 90px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 10px;
    font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

    
}

.pdFourth{
    width: 290px;
height: 44px;
border-radius: 5px;
opacity: 0.7px;
color: #9499A0;
border: 1px solid #9499A0;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px;

}

.pdFourth .pdFoOpt{
  display: flex;
  align-items: center;
  gap: 10px;

}
.pdFourth .pdFoOpt label{
  display: flex;
  align-items: center;
  gap: 5px;

}

.companyDetCon{
    max-width: 1075px;
    width: 100%;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 20px;
background-color: white;
padding: 20px 40px;

}

.companyDetCon h2{
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
color: #060606;    

}
.companyDetCon .allComInputs{
   display: flex;
   gap: 20px;
   flex-direction: column;

}

.alComFirst{
    display: flex;
    align-items: center;
    gap: 14px;
}
.alComFirst .empId{
    width: 246px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 10px;
    font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

    
}
.alComFirst .empCoBranc{
    width: 167px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 10px;
    font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

    
}
.alComFirst .emComDepart{
    width: 282px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    border: 1px solid #9499A0;
    color: #9499A0;
    padding: 10px;
    font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

    
}

.docuDetCon{
    max-width: 1075px;
    width: 100%;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 20px;
background-color: white;
padding: 20px 40px;

}
.docuDetCon h2{
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
color: #060606;    

}
.allDocInputs{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.allDocInputs .documeFirst{
 display: flex;
 gap: 16px;
 align-items: center;
}
.allDocInputs .docNum{
    width: 322px;
    height: 44px;
    border-radius: 5px;
    opacity: 0.7px;
    color: #9499A0;
    border: 1px solid #9499A0;
padding: 10px; 
font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

}

 .uploadDoc{
    width: 322px;
    /* height: 44px; */
    padding: 12px 16px 12px 16px;
    border-radius: 5px;
    border: 1px solid #9499A0;
  display: flex;
  padding: 6px 18px;
  justify-content: space-between;
  align-items: center;
    
}
 .uploadDoc input{
   border: none;
   outline: none;
   width: fit-content;
   height: 100%;
   font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

}
 .uploadDoc button{
   background-color: #008ECC;
   width: 108px;
   height: 32px;
   border-radius: 5px;
   /* padding: 10px 0px 10px 0px; */


}
 .uploadDoc button span{
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
     color: #FFFFFF;
}


.bankAccDetCont{
    max-width: 1075px;
    width: 100%;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 20px;
background-color: white;
padding: 20px 40px;

}
.bankAccDetCont h2{
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
color: #060606;    

}

.bankAllinpts{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.bankAllinpts .bankFist{
   display: flex;
   align-items: center;
   gap: 16px;
}

.accName{
    width: 246px;
height: 44px;
border: 1px solid #9499A0;
border-radius: 5px;
opacity: 0.7px;
color: #9499A0;
padding: 10px;
font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;


}

.accIFSC{
    width: 201px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #9499A0;
    opacity: 0.7px;
    color: #9499A0;
padding: 10px;
font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

}

.bankBranch{
    width: 317px;
height: 44px;
border-radius: 5px;
opacity: 0.7px;
border: 1px solid #9499A0;
color: #9499A0;
padding: 10px;
font-family: Gilroy-Medium;
font-size: 12px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0px;
text-align: left;

}
