.plusSec {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

}

.plusSec h3 {
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;


}

.timerdives{
    background-color:white;
    width: 100px !important;
    /* height: 100px !important; */
    border-radius: 0px !important;

}

.timerdives p{
    font-size: 20px;
}

.adddsomflex{
    display: flex;
    align-items: center;
    gap: 4px;
}

.iconsss{
    font-size: 24px;
    color: green;
    cursor: pointer;
}
.iconsss2{
    font-size: 24px;
    cursor: pointer;
    color: red;
}

.plusSec button {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
    padding: 10px 16px 10px 12px;
    gap: 10px;
    border-radius: 10px;
    background: #0B56E4;


}

.plusSec button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: white;
}


.admin-main {

    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: transparent;
}

.admin-main .hrmShed {
    /* font-family: Gilroy-Medium; */
    font-family: inter !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F4254;
}

.admin-main .hrmDoHe {
    display: flex;
    gap: 10px;
    align-items: center;

}

.admin-main .hrmDoHe p {
    /* font-family: Gilroy-Regular; */
    font-family: inter !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1063FF;
}

.pono0 {
    max-width: 475px !important;
    max-height: 256px !important;
    gap: 16px;
    border-radius: 18px;
}

.pono10 {
    max-width: 475px !important;
    max-height: 426px !important;
    gap: 16px;
    border-radius: 18px;
}

.searchBox {
    width: 100% !important;
}

.optionContainer {
    max-height: 100px !important;
}


.highlightOption,
.option {
    display: flex !important;
    align-items: center !important;
}

.searchWrapper {
    border: none !important;
}

.multiselect {
    width: 300px;
}

.pono2 {
    max-width: 500px !important;
    max-height: 366px !important;
    gap: 16px;
    border-radius: 18px;
}


.popTitl {
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;

}

.pono {
    height: 400px !important;
    max-width: 500px !important;
    width: 100% !important;
}

.admin-main .hrmDoHe span {
    /* font-family: Gilroy-Medium; */
    font-family: inter !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F4254;
}


.hrmssystemsetup-parents {
    display: flex;
    width: 100%;
    gap: 30px;
    padding: 20px 0px;
}

.hrmssystemsetup-rightmenu {
    max-width: 300px;
    width: 100%;
    max-height: 410px;
    border-radius: 12px;
    background-color: white;
}

.hrmsystemsetup-subrightmenu {
    padding: 20px;
    width: 100%;
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid #E8E9EB;
    height: 64px;
}

.hrmsystemsetup-subrightmenu span {
    font-family: inter !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
}

.openItem {
    border-bottom: 2px solid #0B56E4
}

.openItem span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #0B56E4;
}


.hrmsystemsetup-subrightmenu img {
    max-width: 24px;
    max-height: 24px;

}

.plusSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plusSection .plusiCON {
    cursor: pointer;
}


.allPopupWrap {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom:0;
    right: 0;
    min-height: 100vh;
     height: fit-content;
    padding: 50px 0px;

}



.allform_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }

  
.popup_formdiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
    padding: 0px 10px 0px 0px;
  }
  
  .popup_formdiv::-webkit-scrollbar {
    width: 1em;
  }
  
  .popup_formdiv::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #cccccc;
  }
  
  .popup_formdiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #cccccc;
  }

.incheight{
    height: 100% ;
    min-height: 160vh !important;
}

.expensepop{
    background-color: white;
    max-width: 550px  !important;
    height: 1000px !important;
    margin: -100px 0px ;
    border-radius: 10px;

  
}

.awardpopupcont{
    max-width: 700px;
    width: 100%;
    background-color: white;
    padding: 20px 10px;
    display: flex;
    max-height: 550px;
    margin-top: 50px;
    flex-direction: column;
    gap: 6px;

}


.RxCross2_form{
    font-weight: 600;
    font-size: 24px;
    cursor: pointer;
  }
  

.awardpopupcont h2{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0px;
    line-height: 30px;
    text-align: left;
    color: black;
}

.lableawaiwrap{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.lableawaiwrap label{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    
}
.lableawaiwrap label p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: black;
}

.lableawaiwrap label select{
    width: 100% !important;
height: 44px !important;
border-radius: 10px !important;
background: #F8F9FB !important;
border: 1px solid #D0D4DC !important;
}
.lableawaiwrap label input{
    width: 100% !important;
height: 44px !important;
border-radius: 10px !important;
background: #F8F9FB !important;
border: 1px solid #D0D4DC !important;
padding: 0px 10px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: black;
}

.lableawaiwrap textarea{
    padding: 10px 10px;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
border: 1px solid #D0D4DC !important;
text-align: left;
color: black;
border-radius: 10px;
resize: none;
background: #F8F9FB !important;
}


.popup5{
    height: 270px;
}

.popup1 h2 {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.popNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popNav img {
    cursor: pointer;
}

.btnWrap {
    width: 100%;
    display: flex;
    /* margin-top: 30px; */
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    padding: 20px 0px;
}

.selectBRANCH {
    border: 1px solid #000000;
    width: 460px;
    height: 60px;
    border-radius: 5px;
    opacity: 0.2px;
    padding: px 20px;
}

.btnWrap .cencel {
    width: 86px;
    height: 40px;
    border-radius: 5px;
    opacity: 0.5px;
    border: 1px solid #000000;
    
}

.btnWrap .cencel span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;

    color: #49515C;
    
}

.btnWrap .create {
    width: 70px;
    height: 40px;
    border-radius: 5px;
    background: #1566FF;


}

.btnWrap .create span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: white;

}

.popup1 label {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.popup1 label input {
    border: 1px solid #000000;
    height: 54px;
    border-radius: 5px;
    opacity: 0.2px;
    padding: 0px 20px;

}

.adminFirt {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hrmsystemsetup-leftmenu {
    border-radius: 12px;
    border: 1px solid #E8E9EB;
    background-color: white;
    padding: 20px 20px 0px 20px;
    width: 100%;
}

.hrmsystemsetup-container {
    width: 100%;
    margin: auto;
}

.hrmActions {
    display: flex;
    gap: 20px;
    align-items: center;
}

.hrmsystemsetup-pagination {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    gap: 8px;

}

.hrmsystemsetup-pagination span {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.table3 th {
    background-color: white !important;
    border: none !important;
    border-bottom: 1px solid #E8E9EB !important;
    font-size: 13.5px !important;
    font-family: Inter !important;
    font-weight: 500 !important;
}

.tabl3had {
    font-family: Inter;
    color: #2B2B2B;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.004em;
    text-align: left;

}

.tabl3Titl {
    font-family: Inter;
    color: #0B56E4;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: left;

}


.tabl3Titl4 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #0F141C;
}


.hrmsystemsetup-search {
    float: right;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.hrmsystemsetup-search input {
    width: 100.62px;
    border: none;
    outline: none;
    padding: 4px;
    height: 24px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;

}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.action-buttons {
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 5px;
}

.view {
    background-color: blue;
}

.delete {
    background-color: red;
}

.docSetupWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.docSetupWrap nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.docSetupWrap nav h2 {
    color: #101820;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.docSetupWrap nav button {
    display: flex;
    align-items: center;
    width: 123px;
    height: 40px;
    border-radius: 10px;
    justify-content: center;
    background: #0B56E4;


}

.docSetupWrap nav button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}


.requiFild {
    width: fit-content;
    background: #FFFFFF;
    border: 1px solid #98A2B3 !important;
    max-width: 200px;
    padding: 6px 10px;
    border-radius: 27px;
    margin: 0px 5px;
}

.requiFild span {
    font-family: Inter;
    color: #545962;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.004em;
    text-align: left;

}

.ITRWRAP{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.ITRWRAP .heading{
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    
}
.incomeChoice{
    background-color: white;
    border-radius: 10px;
     padding: 10px;
     display: flex;
     flex-direction: column;
     gap: 20px;
     width: 1000px;
     height: 450px;
}
.incomeChoice .question{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.incomeChoice .question .questinput{
    font-family: Inter;
    color: #101820;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    
}
.incomeChoice .question .choices{
  display: flex;
  align-items: center;
  gap: 10px;
}
.incomeChoice .question .choices label{
  display: flex;
  align-items: center;
  gap: 10px;
}
.incomeChoice .question .choices label span{
    font-family: Inter;
    color: #101820;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    
}

.continueBtn{
    width: 143px;
height: 40px;
border-radius:10px;
background: #0B56E4;

}
.continueBtn span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
color: #FFFFFF;    

}

.uploaForms2{
    background-color: white;
    border-radius: 10px;
     padding: 20px;
     display: flex;
     flex-direction: column;
     gap: 20px;
     width: 1000px;
     height: 300px;
}
.itrpara{
    border: 1px solid #B7DFD5;
    background: #DEF2EB;
padding: 10px;
border-radius: 10px;
}
.itrpara p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
color: #1B2533;    
text-align: center;
}

.continwiform{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    justify-content: center;
}
.continwiform button{
    background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
    border: 1px solid #0B56E4;
    width: 230px;
height: 40px;

border-radius: 10px;
color: #0B56E4;
font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.005em;


}

.continwiform span{
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 58.09px;
    letter-spacing: 0.004em;
color: #0B56E4;    

}

.uploaForms{
    background-color: white;
    border-radius: 10px;
     padding: 20px;
     display: flex;
     flex-direction: column;
     gap: 20px;
     width: 1000px;
     height: 300px;
    gap: 20px;
    display: grid ;
    grid-template-columns: repeat(2 , 1fr);
    align-items: center;
}
.uploaForms .label{
    max-width: 400px;
    width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.uploaForms .label input{
    width: 295px;
    height: 48px;
    padding: 8px 16px ;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;    
}

.optiondiv{
    display: flex;
    align-items: center;
gap: 10px;
    
}
.optiondiv span{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
color: #1B2533;    
text-align: center;
}

