.totalEmpl {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 30px 0px;
    /* justify-content: center; */
}

.singl_Emply {
    width: 262px;
    height: 340px;
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px;
    gap: 15px;
}

.singl_Emply .singEmp_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}

.singl_Emply .singEmp_profile h2 {
    font-family: inter !important;
    font-size: 16px;
    font-weight: 400;
    /* line-height: 44px; */
    letter-spacing: 0px;
    text-align: left;
    color: #060606;

}

.singl_Emply .singEmp_profile span {
    font-family: inter !important;
}

.empEmail_phn p{
    font-family: inter !important;
}

.empEmail_phn span{
    font-family: inter !important;
}

.singl_Emply .singEmp_profile p {
    font-family: inter !important;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 44px; */
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
}

.singl_Emply .empEmail_phn {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F8F9FD;
    padding: 10px 16px 10px 16px;
    border-radius: 3px;
    gap: 10px;
}

.singl_Emply .empEmail_phn p {
    display: flex;
    gap: 10px;
    align-items: center;
}

.singl_Emply .empDep_join {
    max-width: 100%;
    width: 100%;
    left: 20px;
    padding: 10px 13px 10px 13px;
    border-radius: 3px;
    gap: 10px;
    background-color: #F8F9FD;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.singl_Emply .empDep_join p {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.singl_Emply .empDep_join p span {
    font-family: inter !important;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
}

.singl_Emply .empDep_join p .sns {

    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
}