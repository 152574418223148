@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;

}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");


body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
}

.action_box {
  position: absolute;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.114);
  border-radius: 5px;
  background-color: white;
  z-index: 1000;
  right: 35px;
}
.yasir{
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.such_thing {
  display: flex;
  align-items: center;
}


.employee_sal_card {
  background-color: #ffffff;
  border: 1px solid #d8dbdf;

  border-radius: 10px;
  box-shadow: rgba(208, 212, 216, 0.2) 0px 8px 24px;

  padding: 5px 0px 20px 0px;
}

.sklin {
  width: 100% !important;
}

.sklin th,
.sklin tr,
.sklin td {
  background-color: white !important;
}

.sipi th {
  color: #2B2B2B !important;
  font-weight: 500 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.fdf th,
.fdf td {
  font-family: inter !important;
  font-weight: 400 !important;
  color: #40444B !important;
}

.oklo {
  color: #0B56E4 !important;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.ghj {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ghj button {
  background-color: #E8E9EB;
  border: 1px solid #B8BBC0;
  width: 70px;
  height: 40px;
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: #666D76;
  border-radius: 5px;
}

.opos th,
.opos td {
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.salary_details {
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  height: 275px;
  max-height: 100%;
  background-color: #ffffff;
  /* padding: 20px; */
}

.salary_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E8E9EB !important;
  padding: 15px 20px;
}

.salary_head11 {
  display: flex;
  align-items: center;
}

.salary_head11 h2 {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.salary_body {
  padding: 20px;
  display: flex;
  align-items: center;
}

.salary_bn h3 {
  color: #0F141C;
  font-weight: 600;
  font-family: inter !important;
  font-size: 14px;
}

.salary_bn p {
  color: #545962;
  font-weight: 500;
  font-size: 12px;
  font-family: inter !important;
  margin-top: 5px;
}

.Employee_set {
  display: flex;
  align-items: center;
}

.salary_bn1 {
  margin-left: 100px;
}

.emp_selo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
}

.removePermsion {
  font-size: 20px;
  margin: 0 40px;
  font-weight: 500;
  font-family: inter !important;
}

.sonit {
  right: 20px;
  top: 15px;
}

.employee_set1 {
  width: 100% !important;
  margin: 0 10px;
}

.employee_set2 {
  width: 100% !important;
  margin: 0px 10px;
}

.salary_bdy11 {
  padding: 0px !important;
}

.sipi1 th {
  font-size: 12px !important;
}

.action_so {
  display: flex;
  align-items: center;
}

.action_so img {
  margin: 0px 5px;
}

.opos1 td {
  font-weight: 500 !important;
  font-size: 11px !important;
}

.lokig {
  height: 100vh !important;
  background-color: #4040404D;
}

.sekin {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 425px;
  width: 100%;
}

.sekin1 {
  max-width: 599px !important;
  width: 100% !important;
}

.basic_sel {
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  font-family: inter !important;
}

.okad {
  border-bottom: 1px solid #E8E9EB !important;
}

.somoi {
  font-family: inter !important;
  font-weight: 600;
}

.buttons_formo {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sarfros {
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  color: #ffffff;
}

.sarfros1 {
  border: 1px solid #B8BBC0 !important;
  color: #666D76;
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  margin-left: 10px;
}

.kinh label {
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400 !important;
  color: #1B2533;
}

.kinh select {
  font-weight: 400;
  font-size: 14px;
  font-family: inter !important;
  color: #1B2533 !important;
  height: 48px;
  border: 1px solid #D0D4DC !important;
  outline: none !important;
}

.kinh input {
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  font-family: inter !important;
  color: #1B2533 !important;
  border: 1px solid #D0D4DC !important;
  outline: none !important;
  padding: 0px 20px;
}

.emp_selo input {
  border: 1px solid #D0D4DC !important;
  width: 247px;
  height: 44px;
  color: #666D76 !important;
  font-size: 14px !important;
  outline: none !important;
  font-family: inter !important;
  margin-top: 10px;
  /* padding: 0px 20px !important; */
}

.loader {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: #189877;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader2 {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: #DD3409;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.colp {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Inter", sans-serif !important;
}

.yik {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

/* .hidden{
  display: block !important;
} */
.img_sing {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

.department_test {
  width: 100% !important;
}

.tuser_class {
  position: fixed !important;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}

.popup33 {
  width: 45%;
}

.login-page {
  width: 100%;
  padding: 60px 60px 80px 60px;
  gap: 8px;
  border-radius: 12px 0px 0px 12px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.testingk {
  width: 40% !important;
  border-radius: 10px !important;
}

.auth4 {
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-pageMain {
  max-width: 1069px;
  margin: 0 auto;
  width: 100%;
  padding: 60px 60px 80px 60px;
  gap: 8px;
  border-radius: 12px 0px 0px 12px;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;


}

.login-pageMain3 {
  max-width: 1069px;
  margin: 0 auto;
  width: 100%;
  padding: 60px 60px 80px 60px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 100px;
  max-height: 616px;


}




.termanduse {
  position: absolute;
  bottom: 20px;
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;


}

.buttonsWrap {
  width: 100%;
  transform: translateX(20px) translateY(20px);
  /* padding: 20px 20px; */
}

.leadThings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-page3 {
  height: 654px;
  max-height: 100%;
  border: 1px solid #f4f4f4;
}

.leads_btn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.leads_btn2 {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.actions {
  border: 1px solid #B8BBC0;
  width: 119px;
  height: 40px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border: 1px 0px 0px 0px;
  border-radius: 15px;
  font-family: Inter;
  font-size: 16px;
  color: #666D76;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;


}

.laed1 {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.leaftlead2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.leaftlead2 span {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.mylead {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.leaftlead2 input {
  width: 265px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border: 1px 0px 0px 0px;
  border-radius: 10px;
  color: #1B2533;


}

.leftlead1 {
  margin: 20px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.inptsearch {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 308px;
  height: 48px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid #D0D4DC;

}

.inptsearch input {
  width: 288px;
  height: 46px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border: 1px 0px 0px 0px;
  font-family: Inter;
  color: #666D76;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;


}

.inptsearch span {
  display: flex;
  align-items: center;
  gap: 16px;

}

.refresh {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 162px;
  border: 1px solid #0B56E4;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
}

.thebuttn {
  display: flex !important;
  justify-content: flex-start !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.lonj td {
  height: 100% !important;
}

.ref1 {
  color: #0B56E4;
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
}

.lead_content1 h2 {
  color: #101820;
  font-weight: 600;
  font-size: 24px;
  font-family: "Inter", sans-serif !important;
}

.lead_content1 p {
  color: #666D76;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  margin-top: 3px;
}

.lead_btn {
  background-color: #0B56E4;
  width: 123px;
  height: 40px;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
}

.lead_btn2 {
  background-color: #0B56E4;
  width: 186px;
  height: 40px;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  padding: 0px 2px 0px 10px;
  gap: 3px;

}

.singWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}


.login-page2 h2 {
  font-weight: 600;
  font-family: inter !important;
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 10px;
}

.forgetSumtBtn {
  width: 100%;
  max-height: 46px !important;
  gap: 8px;
  border-radius: 4px;
  background: #0B56E4;

}

.forgetSumtBtn span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;

}

.access {
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400;
}

.login-page2 p {
  color: #64686d;
  font-size: 17px;
  font-family: "Medium";
  opacity: 80%;
}

.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  display: inline-block;
  --field-padding: 12px;
}

.showpassicon{
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
}

.systSset img{
  max-width: 16px;
  max-height: 16px;
  width: 16px;
  height: 16px;
}
.dash-wrap img{
  max-width: 16px;
  max-height: 16px;
  width: 16px;
  height: 16px;
}
.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
  border-radius: 6px;
  height: 46px;
  max-height: 100%;
  font-family: inter !important;
  color: #666D76 !important;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  /* color: #aaa; */
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  font-family: inter !important;
  color: #666D76 !important;
}


.oveviewBtn {
  color: orange !important;
  cursor: pointer;
}

.silom{
  margin-left: 0px !important;
  padding: 0px 20px !important;
}

.prodetailTable th,
td {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E9EB !important;
  border-top: 1px solid #E8E9EB !important;
}

.prodetailTable th {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.prodetailTable td {
  font-family: Inter;
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.custom-field input.dirty+.placeholder,
.custom-field input:focus+.placeholder,
.custom-field input:not(:placeholder-shown)+.placeholder {
  top: -5px;
  font-size: 15px;
  color: #0b60ff;
  font-family: inter !important;
  color: #666D76 !important;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 2px solid #d1d1d1;
  transition: border-color 0.3s ease;
  display: inline;
  opacity: 0.8;
  width: 100%;
  background-color: #E8E9EB !important;
  border: 1px solid #D0D4DC;
  font-family: inter !important;
  color: #666D76 !important;
}


/* manish side bar admin dashboard  */
.side-dash-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  width: 100%;
  height: 44px;
  cursor: pointer;
  margin-left: 20px;
  /* padding: 0px 20px; */
}
.semo{
  color: #0B56E4 !important;
}
.sidemargin {
  margin-left: 0px !important;
}

.HRMS-dash-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  padding: 0px 20px;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.HRMS-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.HRMS-wrap p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299; */
}


.side-dash-box .dash-wrap {
  display: flex;
  align-items: center;
  gap: 12px;

}

.side-dash-box .dash-wrap p {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.allDasCon {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.allDasCon .setWrap {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  gap: 4px;
}

.adpading{
  padding: 0px 20px;
}

.allDasCon .setWrap p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #DEDEE6;
}

.systSset {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
  cursor: pointer;
}

.allDasCon .systSset {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
  cursor: pointer;
}

.allDasCon .systSset span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299;   */
}



.systSset span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299;   */
}



.adDasWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.alladminDash-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */

}


.sinADDasItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 12px;
  padding: 0px 20px;
  cursor: pointer;
}

.dITitl {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299; */
}

.dashItemp {
  /* color: #F3F6F8; */
  font-family: Inter;
  font-size: 14px;
}


/* manish side bar admin dashboard  */

.custom-field.one input+.placeholder {
  left: 8px;
  padding: 0 5px;
  font-size: 17px;
  top: 24px;
  color: #64686d !important;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #0b60ff;
  transition-delay: 0.1s;
}
input{
   outline: none !important;
}
.custom-field.one input.dirty+.placeholder,
.custom-field.one input:not(:placeholder-shown)+.placeholder,
.custom-field.one input:focus+.placeholder {
  top: 0;
  font-size: 15px;
  font-family: "Medium" !important;
  font-style: italic;
  color: #0b60ff !important;
  background: #fff;
  width: auto;
}
.no_data{
  font-size: 22px;
}
.login-page1 {
  max-width: 100%;

}

.kushel-logo2 {
  max-width: 174px;
  width: 100%;

}


.kushImg {
  max-width: 172px;
  max-height: 54px;
}


.login-page2 {
  width: 538px;
  position: relative;
  max-height: 600px;
  height: fit-content;
  padding: 30px 40px;
  gap: 8px;

}

.formgotPassWidht {
  margin-top: 10px;
  width: 100%;
  justify-content: flex-start;
}

.login-page2 button {
  width: 100%;

  height: 46px;
  border-radius: 6px;

  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 19.5px;
  margin-top: 35px;
}

.sign-information p {
  color: #64686d;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}

.singEmp_profileL img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.sign-information span {
  color: #0b60ff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 17px;
}

/* .okij{
  width: 100% !important;
}
.okij input{
  width: 100% !important;
} */
.okij {
  margin: 10px 0px !important;
}

.serad {
  padding: 20px;
}

.emm {
  margin-top: 80px !important;
  margin-right: 14px !important;
}

.taskans11 {
  white-space: nowrap !important;
}

.taskAns{
  text-align: center;
  text-align: left;
}

.forget {
  color: #0B56E4 !important;
  font-family: inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: inter !important;
}

/* .login_new_div {
  padding: 50px;
} */

.popup110 {
  height: 420px !important;
  max-height: 420px !important;
}

.popingson {
  height: 397px !important;
}

.indexest {
  position: relative;
  z-index: 100000000;
}

.otp-verify {
  width: 100%;
  margin-top: 40px;
}

.otp-verify .otp {
  border: 2px solid #0b60ff;
  height: 75px;
  width: 100px;
  max-width: 100%;
  border-radius: 5px;
  outline: none;
  text-align: center;
  position: relative;
  z-index: 2;
  font-family: "Semi Bold";
  font-size: 34px;
}

.next-btn {
  /* margin-top: 80px !important; */
}

.otp-verify .otp:hover {
  background-color: #bdd5f6;
  color: #0b60ff;
  border: none;
  transition: 0.5s;
}

.Employee-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* position: relative; */
  z-index: 2000;
  background-color: #ffffff;
  position: fixed;
  right: 0px;
  top: 0px;
}

.aka {
  color: #168A6C;
  font-weight: 500;
  font-size: 11px;
  font-family: "Inter", sans-serif !important;
}

.olo {
  width: 270px !important;
}

.olo1 {
  width: 270px !important;
}

/* manish admin navbar css  */

.logo-namewrap {
  display: flex;
  align-items: center;
  gap: 90px;
}

/* manish admin navbar css  */

.checkta {
  margin-left: 7px !important;
  height: 15px !important;
  margin-top: 12px !important;
  border: 1px solid #0b60ff !important;
}

.seri {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logo img {
  max-width: 160px;
  width: 100%;
}

.second-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}


.second-logo img {
  width: 12px;
}

.second-logo p {
  color: #000f2b;
  font-size: 21px;
  font-family: Inter !important;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.navProfiIcons {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navProfiIcons img {
  cursor: pointer;
}

.third-logo input {
  border: 2px solid rgb(236, 236, 236);
  outline: none;
  width: 400px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0px 20px;
  font-family: "Medium" !important;
  font-size: 15px;
  position: relative;
  /* color: #00112F;
  opacity: 30%; */
}

.unpaid {
  width: 61px !important;
  height: 28px !important;
  border: 1px solid #E45D3A !important;
  border-radius: 27px !important;
  background: #FCEBE6 !important;
  padding: 10px 20px;
  color: #E45D3A;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.MdDeletepaslip{
  font-size: 20px;
}

.paid {
  width: 61px !important;
  height: 28px !important;
  border: 1px solid #189877;
  border-radius: 27px !important;
  background: #E8F5F1;
  padding: 10px 20px;
  color: #189877;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.toglwCont {
  position: relative;
}

.paysliptdimages{
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.paysliptdimages img{
  max-width: 16px;
  cursor: pointer;
}
.togglewrap {
  position: absolute;
  background-color: white;
  width: 170px;
  z-index: 100;
  height: fit-content;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  top: 50px;
  right: 65px;
}

.togglewrap label{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
}

.togglewrap label img{
  max-width: 20px;
}
.togglewrap p {
  cursor: pointer;
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  padding: 10px 10px;
  border-bottom: 1px solid #E8E9EB;
  transition: 0.2s;

}

.togglewrap p:hover {
  background-color: #0B56E4;
  color: white;

}

.openPaywrap {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  background: #4040404D;
  height: 100%;
  z-index: 200;

}

.hidenOverflow {
  overflow: hidden !important;
}

.openPayCont {
  width: 814px;
  height: 580px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transform: translateX(300px) translateY(100px); */
}

.kdslogo {
  max-width: 150px;
  max-height: 45px;
}

.openPayCont nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.openPayCont nav h2 {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.paydetails {
  display: flex;
  gap: 50px;
  max-width: 600px;
  width: 100%;
  justify-content: space-between;
}

.paydetails .paydetailLeft {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.totalErWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-top: 24px;
}

.paidWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.paidWrap h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2B2B2B;
}

.paidWrap p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

}

.enrcont {
  display: flex;
  align-items: center;
  gap: 8px;

}


.paydetails .paydetailLeft label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paydetails .paydetailLeft label p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.paydetails .paydetailRight {
  display: flex;
  flex-direction: column;
  gap: 8px;

}

.paydetailRight h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

  color: #2B2B2B;
}

.d_slip {
  display: flex !important;
  gap: 10px;
}

.print_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.print_slip {
  width: 150px;
  max-width: 100%;
  display: block;
  margin: auto;
}

.payslip_text {
  font-family: inter !important;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.paydetails .paydetailRight p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.vhg th,
.vhg td {
  font-family: inter !important;
}

.loader54 {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 20px 400px;
}

.prevNextWrap {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.assettable th{
  white-space: nowrap !important;
  font-size: 11px !important;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none !important;
}
.prebBtN {
  border: 1px solid black;
  padding: 5px 20px;
  border-radius: 10px;
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fourth-logo button {
  border: 2px solid #085881;
  width: 180px;
  max-width: 100%;
  height: 40px;
  border-radius: 3px;
  font-size: 17px;
  font-family: "Semi Bold" !important;
  color: #085881;
}

.fifth-logo img {
  width: 45px;
}

.sixth-logo .john {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.sixth-logo .bottom {
  width: 12px;
}

.sixth-logo p {
  font-size: 14px;
  font-family: Inter !important;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* .sight{
  position: relative;
  top: 80px;
} */
.sidebars {
  /* background-color: #000f2b !important; */
  background-color: #ffffff;
  padding: 24px 0px 50px 0px;
  border-right: 1px solid #e8e6e6;
  /* margin-top: 69px; */
}


.sidebars1 {
  margin-top: 69px;
}

.allNavItem {
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* padding: 12px; */
}

.dashNW span {
  font-family: Inter;
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.most {

  display: flex;
  align-items: center;

}

.dashNW {
  display: flex;
  align-items: center;
  gap: 8px;
}

.most span {
  font-size: 13px !important;
  font-family: "Semi Bold" !important;
  opacity: 80% !important;
  letter-spacing: 0.5px !important;
  /* color: #ffffff !important; */
}

.most svg {
  color: #ffffff;
  opacity: 70% !important;
}

.red-box {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.white-box {
  position: absolute;
  bottom: 20px;
  background-color: #203a44;
  max-width: 180px;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  display: none;
  /* opacity: 15%; */
}

.white-box img {
  width: 70px;
  height: 70px;
  display: block;
  margin: auto;
  position: absolute;
  top: -40px;
  left: 54px;
}

.black-box h3 {
  color: #ffffff;
  font-family: "Semi Bold" !important;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  margin-top: 18px;
}

.black-box p {
  color: #ffffff;
  font-size: 11px;
  font-family: gilroy !important;
  text-align: center;
  opacity: 50%;
}

.black-box button {
  width: 130px;
  max-width: 100%;
  position: relative;
  z-index: 2;
  height: 38px;
  display: block;
  margin: auto;
  margin-top: 12px;
  color: #ffffff;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  font-size: 11px;
  border-radius: 8px;
}

.black-box button::before {
  background-color: #ffffff;
  opacity: 15%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* ====================hrms==================== */
/* .devta {
    width: 100%;
    margin-left: 16rem;
} */
.employee-dash {
  display: flex;
  position: relative;
  background-color: #f5f5f5;
}


.tm {
  width: 100%;
  /* background-color: #f0f6ff; */
  background-color: #f5f5f5;
}

.em {
  /* padding: 62px 70px; */
  padding: 32px 30px;
  margin-top: 69px;
  position: relative;
}

.em22 {
  padding: 32px 30px;
}

.emwrap {
  margin-top: 120px;
}

.em1 {
  padding: 0px 0px;
}

.logobar {
  background-color: #ffffff;
  padding: 10px 30px;
  box-shadow: rgba(149, 157, 165, 0.2);
}

.logobar img {
  max-width: 160px;
  width: 100%;
}

.bedge {
  display: flex;
  /* width: 100%; */
}

.bret {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-top: 3px solid #1e6dff;
}

.bret .btr {
  width: 40px;
}

.bret p {
  color: #000f2b;
  font-size: 21px;
  font-family: "Bold" !important;
  letter-spacing: 0.5px;
}

.bret .plus {
  width: 40px;
}

.attendence-yes {
  background-color: #ffffff;
  padding: 25px;
}

.attendence-yes h3 {
  color: #000f2b;
  opacity: 50%;
  font-size: 17.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.atten-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 5px 20px;
  padding-bottom: 15px;
  width: 100%;
  margin-top: 20px;
}

.atten-section::before {
  background-color: #000000;
  opacity: 5%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.atten-section p {
  color: #000000;
  font-family: "Bold" !important;
  font-size: 19.5px;
}

.atten-section h5 {
  color: #000000;
  opacity: 50%;
  font-size: 9px;
  font-family: "Semi Bold" !important;
}

.first-bedge {

  width: 100%;
  overflow-y: scroll;
  height: 690px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
}

.first-bedge::-webkit-scrollbar {
  display: none !important;
  width: 0px;
}

.first-bedge::-webkit-scrollbar-thumb {
  display: none !important;
  width: 0px;
}

.first-bedge::-webkit-scrollbar-track {
  display: none !important;
  width: 0px;
}

.metting_div_surbhi {
  padding: 35px 0px;
  display: flex;
  gap: 35px;
}

.time_emp_desh_flex {
  display: flex;
  gap: 47px;
}

.time_emp_desh_flex2 {
  display: flex;
  gap: 20px;
  align-items: center;
}

.time_emp_desh {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.time_emp_desh h5 {
  font-family: Inter;
  color: #0B56E4;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.001em;
  text-align: center;


}

.time_emp_desh p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;

}

.leaves_request_emp {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.leaves_request_emp button span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: white;
}

.leave_setion_emp {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.totel_leave_allowance1 {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: whitesmoke;
  width: 50%;
}

.hrLefThi22 {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 5px;
  margin: 35px 0px;

}

.totel_leave_allowance1 p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hrLefThi22 h2 {
  padding: 20px 20px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #060606;
}

.second-bedge {
  width: 100%;
}

.line img {
  height: 55px;
  width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.hiiihih {
  gap: 20px;
  align-items: center;
}

.celeberation {
  width: 60%;
  background-image: url("./Components/images/pong.png");
  /* height: 100%; */
  padding: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  margin-top: 35px;
}

.celeberation::before {
  content: "";
  background-color: #1e6dff;
  opacity: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.celeberation .cel-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cel-head h3 {
  color: #000f2b;
  font-size: 20px;
  font-weight: 400;
}

.cel-head p {
  color: #0b60ff;
  font-size: 18px;
  font-family: "Semi Bold" !important;
}

.cel-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.cel-box {
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  margin: 10px;
}

.cel-boxing {
  display: flex;
  align-items: flex-start;
}

.cel-boxing1 img {

  max-width: 100%;

}

.cel-boxing2 h2 {
  color: #0B60FF;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.5px;
}

.cel-boxing2 p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-family: "Medium" !important;
}

.cel-boxing2 button {
  width: 147px;
  max-width: 100%;
  border: 2px solid #000F2B80;
  font-size: 14px;
  height: 34px;
  font-family: "Semi Bold" !important;
  color: #000F2B;
  border-radius: 5px;
  margin-top: 15px;
}

.new-joiner {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 35px;
}

.joiners1 {
  width: 100%;
  position: relative;
  z-index: 2;
  margin-right: 30px;

  /* margin-left: 10px; */
}

.joiners1 img {
  display: block;
  margin: auto;
  width: 46px;
  margin-top: 20px;
}

.joiners1 h2 {
  text-align: center;
  font-size: 18px;
  color: #0b60ff;
  font-family: "Semi Bold" !important;
}

.joiners1 p {
  text-align: center;
  font-size: 14px;
  font-family: "Medium" !important;
  opacity: 50%;
}

.joiners2 {
  position: relative;
  z-index: 2;
  padding: 0px 30px;
  margin-right: 40px;
  margin-top: 10px;
  /* margin-left: 10px; */
}

.joiners2::before {
  content: "";
  border-left: 2px solid #707070;
  opacity: 20%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.joiners2 P {
  max-width: 100%;
  /* width: 24em; */
  font-family: gilroy;
  font-size: 14.5px;
  color: #000000;
  opacity: 60%;
}

.updateUser label {
  font-family: inter !important;
}

.new-joiner h3 {
  font-size: 18px;
  color: #000000;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
  margin-top: -10px;
}

.new-joiner span {
  color: #000000;
  font-size: 13px !important;
}

.hr .hr1 {
  border-bottom: 2px solid #707070;
  opacity: 20%;
  margin-top: 10px;
  margin-left: -25px;
  margin-right: -25px;
}

.calend {
  background-color: #ffffff;
  /* margin-top: 35px; */
  border-radius: 10px;
  padding: 25px 0px;
}

.calend1 {
  margin-top: 0px;
}

.calend-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.calend-head h2 {
  color: #1B2533;
  font-family: inter !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.calend-head p {
  color: #0b60ff !important;
  font-family: "Semi Bold" !important;
  font-size: 14.5px;
}

.react-calendar {
  width: 100% !important;
  max-width: 100% !important;
  border: none !important;
}

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__tile {
  padding: 24px 0px !important;
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none !important;
}

abbr:where([title]) {
  text-decoration: none !important;
}

.user-profile {
  position: absolute;
  top: 56px;
  padding: 10px 20px;
  border-radius: 5px;
}

.user-profile p {
  margin: 10px 0px;
}

.tys {
  display: block;
}

.kys {
  display: none;
}

.admin-main {
  min-height: calc(100vh-80px);
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0px 10px;
  position: relative;
}

.admin-mainnew {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.admin-main .plus1 {
  width: 180px;
  max-width: 100%;
  position: absolute;
  bottom: -5px;
  left: 85%;
  cursor: pointer;
}

.loj {
  position: relative;
  z-index: 2;
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loj::before {
  content: "";
  background-color: #0b60ff;
  opacity: 15%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.loj img {
  display: block;
  margin: auto;
  width: 28px;
}

.main-box {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 10px 15px;
  margin: 0px 25px;
  /* margin-top: 30px; */
  max-width: 400px;
  width: 100%;
  cursor: pointer;
}

.main-boxes {
  margin-top: 30px;
}

.main-box::before {
  content: "";
  background-color: #0b60ff;
  opacity: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.main-box2 {
  margin-left: 30px;
}

.main-box2 h3 {
  color: #000f2b;
  opacity: 80%;
  font-size: 18.5px;
  font-family: Inter !important;
  font-weight: bold;
}

.home-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
}

.popup-container {
  max-width: 650px;
  width: 100%;
  /* height: 100%; */
  background: white;
  padding: 70px 0px;
  border-radius: 10px;
  margin: 20px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .home-pop .cut{
  width: 100px;
} */
.home-pop .cut {
  width: 23px;
  /* height: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  z-index: 90;
  right: 1rem;
  cursor: pointer;
}

.pops-man h3 {
  color: #000f2b;
  text-align: center;
  font-size: 21px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.select-typ1 {
  margin-top: 30px;
}

.select-typ1 img {
  max-width: 90px;
  width: 100%;
  margin: 0px 35px;
}

.arrow-btn button {
  background-color: #0b60ff;
  width: 160px;
  height: 38px;
  display: block;
  margin: auto;
  margin-top: 60px;
  border-radius: 10px;
}

.arrow-btn img {
  display: block;
  margin: auto;
  width: 18px;
}

.sel-type p {
  margin-top: 15px;
  font-size: 14px;
  font-family: "Medium" !important;
  opacity: 50%;
  color: #000000;
}

.admin-form1 {
  background-color: white;
  max-width: 48%;
  width: 100%;
  padding: 20px 20px;
  border-radius: 10px;
}


.emmanformwrap1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0px;
}

.emmanformwrap1 label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.emmanformwrap1 label input {
  width: 100%;
}

.emmanformwrap1 label p {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.admfperh2 {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.admin-form {
  display: flex;
  /* margin: 0 25px; */
  width: 100%;
  /* background-color: orange; */
  justify-content: space-between;
}

.form-section form {
  width: 100%;
}

/* .form-section form input[type="email"]{
  color: #000000 !important;
} */
.admin-form1 .ladki {
  width: 75px;
  display: block;
  margin: auto;
}

.admin-form1 .kds {
  text-align: center;
  font-size: 17.5px;
  font-family: "Medium" !important;
  color: #0a2e57;
}

.form-section input,
.form-section select,
.form-section textarea {
  width: 100%;
  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;
  background: #F8F9FB;
  outline: none;
}

.form-section input placeholder {
  color: red;
}

.inputs-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.inputs-buttons .edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border: 2px solid #0b60ff;
  color: #0b60ff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  margin: 0 10px;
  border-radius: 5px;
}

.inputs-buttons .delete {
  border: 2px solid #EF5353;
  color: #EF5353;
}

.inputs-buttons span {
  font-size: 14px;
  font-family: "Semi Bold" !important;
  margin: 0 4px;
}

.inputs-buttons img {
  width: 14px;
  margin: 0 4px;
}

.inputs-buttons .save {
  background-color: #0b60ff !important;
  width: 110px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  height: 30px;
  border-radius: 5px;
  margin: 0px 10px;
}


.admin-form2 {
  margin-top: 30px;
  width: 100%;
  margin-left: 12px;
  height: 700px;
  max-height: 100%;
  overflow-y: scroll;
}

.admin-form ::-webkit-scrollbar {
  display: none !important;
}

.admin-form ::-webkit-scrollbar-track {
  display: none !important;
}

.admin-form ::-webkit-scrollbar-thumb {
  display: none !important;
}

.basic-information {
  background-color: white;
  padding: 15px 15px;
  border-radius: 10px;
  max-width: 49%;
  width: 100%;
}

.emregistbtn {
  background-color: #0B56E4;
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}

.alldocwwrap {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

}

.wrap1 {
  /* background-color: red; */
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}

.empmanagform {
  margin-top: 30px;
}

.makethisflex1 {
  display: flex;
  gap: 10px;
}

.basic-information2 {
  padding: 15px 0px;
  border-radius: 10px;
}

.addedinfowidth {
  max-width: 570px;
  width: 100%;
  padding: 20px 0px;
}

.bank1wap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.basics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.basics h3 {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.singbankWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.singbankWrap label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.singbankWrap input {
  height: 44px;
  width: 90%;

}

.basics img {
  width: 12px;
}

.upper {
  margin-top: 20px;
  opacity: 80%;
}

.form2-class input,
.form2-class select,
.form2-class textarea {

  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #D0D4DC;
  background-color: white;
}



.form2wrap {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.emmanageSec2 {
  display: flex;
  gap: 20px;
  width: 100%;
}

.addedGgap {
  gap: 12px;
  max-width: 530px;
}

.form2-class form input::before {
  color: #000000 !important;
  opacity: 60%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}




.form2-class label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.inputs-button1 {
  margin-top: 30px;
}

.inputs-button1 .edit {
  margin: 0px 0px;
  margin-right: 10px;
  width: 100% !important;
  max-width: 80px;
}

.inputs-button1 .save {
  margin: 0px 0px;
  margin-left: 10px;
  width: 100% !important;
}

.inputs-button2 {
  justify-content: flex-start;
}

/* ===============request css================== */
.first-request-bar {
  background-color: #26334b;
  max-width: 314px;
  width: 100%;
  padding: 20px 0px;
  margin: 0px 15px;
}

.request-search {
  padding: 0px 20px;
}

.request-search h2 {
  color: #ffffff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.request-search img {
  width: 16px;
  height: 16px;
}

.request-by {
  margin: 10px 0px;
  position: relative;
  z-index: 2;
  padding: 15px;
  width: 100%;
  cursor: pointer;
}

.request-by p {
  color: #ffffff;
  font-size: 13px;
  font-family: "Semi Bold";
}

.request-by .from {
  color: #ffffff;
  font-size: 13px;
  font-family: "Medium" !important;
  margin-top: 4px;
}

.request-by .datr {
  color: #ffffff;
  opacity: 50%;
  font-family: "Medium" !important;
  margin-top: 5px;
}

.request-by .blue {
  color: #9fc1ff;
  font-family: "Medium" !important;
  margin-top: 6px;
}

.request-by:hover {
  position: relative;
  z-index: 2;
}

.request-by:hover::before {
  background-color: #ffffff;
  opacity: 10%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
}

.second-request-bar {
  background-color: #ffffff;
  margin: 45px 80px;
  width: 100%;
  max-width: 100%;
  padding: 20px;
}

.pending {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending .pending1 {
  width: 65%;
  max-width: 100%;
  border-bottom: 2px solid #d9e1f0;
}

.pending button {
  background-color: #ffc107;
  width: 100%;
  height: 40px;
  font-size: 17.5px;
  font-family: "Semi Bold" !important;
  color: #ffffff;
  border-radius: 5px;
}

.request-flow h3 {
  font-family: "Semi Bold" !important;
  color: #000000;
  font-size: 15px;
  opacity: 60%;
  letter-spacing: 0.5px;
}

.request-flow .image-line {
  height: 90px;
  border-left: 2px solid #d9e1f0;
  display: block;
}

.request-flow .image-line1 {
  height: 83px;
  border-left: 2px solid #d9e1f0;
  display: block;
}

/* .view-form2{
  height: 45px;
} */
.request-flows {
  display: flex;
  align-items: flex-start;
}

.requests-flow1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.requests-flow1 img {
  display: block;
}

.requests-flow2 {
  width: 100%;
  margin: 0px 30px;
}

.view-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.view-form::before {
  border: 2px solid #829dcc;
  opacity: 20%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
}

.view-form1 h3 {
  color: #000000 !important;
  font-size: 13px;
  font-family: "Bold" !important;
  opacity: 100% !important;
}

.view-form1 h5 {
  color: #000000;
  font-family: "Medium" !important;
  font-size: 13px;
  opacity: 100%;
}

.view-form1 p {
  color: #000000;
  font-size: 13px;
  font-family: "Medium" !important;
  opacity: 50%;
  margin-top: 4px;
}

.view-form2 h3 {
  color: #085881;
  font-family: "ExtraBold" !important;
  opacity: 100%;
  font-size: 13px;
}

.request-flow .undo {
  border: 2px solid #085881;
  display: block;
  margin: auto;
  max-width: 180px;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  margin-top: 60px;
  color: #085881;
  font-size: 18px;
  font-family: "Semi Bold" !important;
  opacity: 100% !important;
}

.uploadedProfile {
  max-width: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cutImg {
  display: flex;
  justify-content: flex-end;

}

.cutImg img {
  cursor: pointer;

}

.updateUser {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.makethisfelxd {
  display: flex;
  gap: 20px;
}

.sabeupdabtn {
  width: 150px;
  /* margin: 0 auto; */
  height: 40px;
  border-radius: 5px;
  background-color: #0B56E4;
  color: white;
  font-weight: 600;
}

.makethisgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.updateUser::-webkit-scrollbar {
  display: none !important;
}

.updateUser::-webkit-scrollbar-thumb {
  display: none !important;
}

.updateUser input,
.updateUser select {
  background-color: #e8ecf5;
  outline: none;
  height: 55px;
  border-radius: 5px;
  padding: 10px;
  font-family: inter !important;
  width: 100%;
}

.silo {
  border: 1px solid #B8BBC0 !important;
  background-color: #E8E9EB !important;
  color: #6C737F !important;
  border-radius: 5px;
  font-family: inter !important;
  font-weight: 500;
  height: 40px !important;
  font-size: 16px !important;
}

.solaring {
  width: 199px;
  max-width: 100%;
  position: absolute;
  right: 63px;
  top: 155px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.faring a {
  color: #19222E;
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400 !important;
  padding: 10px;
}

.faring a:hover {
  color: #0B56E4 !important;
  background-color: #F5F9FF !important;
  transition: .5s;
}

.filter_lead {
  padding: 10px 10px;
  border-bottom: 1px solid #D0D4DC !important;
}

.filter_lead h2 {
  color: #2B2B2B !important;
  font-family: inter !important;
  font-weight: 600 !important;
  font-size: 14px;
}

.trt_things {
  padding: 12px 10px !important;
  border-bottom: 1px solid #D0D4DC !important;
}

.fg {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.fg span {
  font-weight: 400;
  font-size: 14px;
  color: #545962 !important;
  font-family: inter !important;
}

.in_the {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 15px;
}

.in_the select {
  border: 1.5px solid #D0D4DC !important;
  outline: none;
  height: 38px;
  margin-left: 10px;
  color: #545962 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.testo {
  border: 1.5px solid #D0D4DC !important;
  outline: none;
  height: 38px;
  margin-left: 10px;
  color: #545962 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.stoing {
  max-width: 49px;
  height: 38px;
  border: 1.5px solid #D0D4DC !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.stoing input {
  max-width: 49px;
  height: 38px;
  padding: 3px;
}

.aloy1 {
  width: 131px;
}

.aloy2 {
  width: 93px;
}

.touched_things {
  /* padding: 0px 10px !important; */
  display: flex !important;
  align-items: center !important;
}

.touched_things span {
  color: #2B2B2B;
  font-family: inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 5px;
}

.dart {
  position: absolute;
  z-index: 100;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-width: 337px;
  width: 100%;
  /* height: 800px; */
  overflow: auto;
  top: 233px;
  /* padding: 0px 20px; */
}

.some_things {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #D0D4DC !important;
}

.apply_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.apply button {
  width: 117px;
  height: 40px;
  background-color: #0B56E4;
  color: #ffffff !important;
  font-family: inter !important;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  margin: 0px 5px;
}

.cancel button {
  width: 117px;
  height: 40px;
  font-family: inter !important;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #0B56E4 !important;
  background-color: #D1E8FD;
  border-radius: 8px;
  margin: 0px 5px;
}

/* .setting{
  padding: 10px;
} */
.some_things input {
  width: 14px;
  height: 14px;
  border: 2px solid #BAC1CC;
}

.some_things span {
  color: #2B2B2B;
  font-weight: 500;
  font-family: inter !important;
  font-size: 14px;
  margin-left: 10px;
}

.CircularProgressbar {
  width: 100px !important;
  height: 40px !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000000 !important;
  font-size: 50px !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: none !important;
  stroke-linecap: initial !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: none !important;
}

.Jobdescription {
  height: 65px !important;
}

.drag-area {
  max-width: 252px;
  /* width: 252px; */
  width: 100%;
  height: 62px;
  border-radius: 12px;
  border: 1px solid #B7B7B7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.thiddrapgsingl {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

}

.thiddrapgsingl h4 {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.lstOrgText {
  font-family: Inter;
  color: #101820;
  padding: 20px 20px 0px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.drag-area p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #1B2533;
  text-decoration: underline;

}

.drag-area img {
  max-width: 29px;
  max-height: 29px;

}


.drag-area header {
  /* font-size: 30px; */
  font-size: 14px;
  font-family: "Medium" !important;
  font-weight: 500;
  color: #0B60FF;
  opacity: 60%;
}

.drag-area span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  font-family: "Medium" !important;
  opacity: 12%;
  margin-top: 5px;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.filesjila {
  position: absolute !important;
  opacity: 0;
  background-color: red;
}

.drag-area2 {
  width: 30% !important;
}

.status-box {
  background-color: #FFFFFF;
  /* max-width: 290px; */
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.status-sox {
  padding: 0px 15px;
}

.status-months {
  padding: 0 15px;
}

.status-sox h3 {
  color: #000000;
  font-size: 18px;
  font-family: "Bold" !important;
}

.status-sox img {
  width: 19px;
  height: 19px;
}

.status-months1 h5 {
  color: #000000;
  opacity: 50%;
  font-size: 12px;
  font-family: "Semi Bold" !important;
}

.status-months2 {
  position: relative;
}

.status-months h2 {
  color: #000000;
  font-family: "Semi Bold" !important;
  font-size: 33px;
  line-height: 40px;
}
.pono0,.pono2,.pono10{
  background-color: white !important;
  height: auto !important;
  max-width: 500px !important;
  padding: 20px !important;
  width: 100% !important;
}
.popup1 label{
  margin-top: 20px !important;
}
.popNav{
  padding: 10px 0px;
}
.expensepop{
  width: 100% !important;
  padding: 20px !important;
}
.allPopupWrap{
  z-index: 1000 !important;
}


.input{
  outline: none !important;
}
.status-months2 select {
  position: relative;
  z-index: 2;
  outline: none;
  padding: 4px 10px;
  background-color: #F0F0F0;
  opacity: 90%;
  font-size: 13px;
  color: #000000 !important;
  opacity: 60%;
}
.anMaRi input{
  outline: none !important;
}
.hr-months {
  border: 1px solid #000000;
  opacity: 10%;
}
.onHoldbg{
  color: white !important;
}
.onboarding {
  background-color: #FFFFFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* max-width: 290px; */
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;
  padding: 12px 0px;
}

.first-on {
  display: flex;
  align-items: center;
}

.gonBoarding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.first-o img {
  width: 27px;
}

.second-o p {
  color: #000000;
  font-size: 13px;
  font-family: "Semi Bold" !important;
  margin-left: 10px;
}

.second-on h4 {
  color: #000000;
  font-size: 13px;
  font-family: "Semi Bold" !important;
}

.onboard-hr {
  border: 1px solid #000000;
  border-style: dotted;
  color: #000000;
  opacity: 15%;
  margin-top: 12px;
}

.auth {
  background-color: #f5f5f5;
  max-height: 100vh;
}

.gon {
  margin-top: 12px;
}

.request-card {
  /* width: 600px; */
  max-width: 100%;
  width: 600px;
}

.hrStatus2 {
  width: 665px;
  max-width: 100%;
}

.request-card {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px 0px;
}

.see-req {
  padding: 0px 15px;
}

.see-req h2 {
  color: #000000;
  font-size: 18px;
  font-family: "Bold" !important;
}

.see-req p {
  color: #0B60FF;
  font-size: 13px;
  font-family: "Semi Bold" !important;
}

.hrDash-status2 {
  max-width: 370px;
  width: 100%;
}

.shubham-cards1 .goal {
  width: 13px;
  height: 13px;
}

.shubham-cards1 .shubham {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}

.mr {
  margin-left: 8px;
}

.mr h4 {
  color: #0A2E57;
  font-size: 12px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.mr p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
}

.shubham-cards4 {
  background-color: #F1F6FF;
  margin: 0px 15px;
  padding: 15px 12px;
  margin-top: 10px;
}

.shubham-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
}

.see-hr {
  border: 1px solid #000000;
  opacity: 10%;
  margin-top: 10px;
}

.tistar p {
  font-size: 11px;
  color: #0A2E57;
  font-family: "Semi Bold" !important;
}

.date1 h4 {
  color: #0A2E57;
  font-size: 11px;
  font-family: "Semi Bold" !important;
}

.date1 {
  margin-left: 8px;
}

.date1 p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
}

.interview {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 12px 0px;
}

.interview-img {
  padding: 0px 15px;
}

.interview-img img {
  width: 18px;
  height: 18px;
}

.test {
  border: 1px solid #000000;
  opacity: 10%;
  margin-top: 13px;
}

.calendar {
  margin: 0px 15px;
  margin-top: 10px;
}

.calendar .react-calendar {
  background-color: #F1F6FF;
  position: relative;
}

.calendar .react-calendar button {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000000 !important;
}

.calendar .react-calendar button abbr {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000000 !important;
}

.calendar .react-calendar__navigation__label__labelText {
  font-size: 13px !important;
  font-family: "Bold" !important;
  color: #000000 !important;
}

/* class=" react-calendar__navigation__next-button" */
.calendar .react-calendar__navigation__arrow {
  font-size: 25px !important;
  color: #F1F6FF !important;
}

.calendar .react-calendar__navigation {
  width: 46% !important;
  max-width: 100%;
}

.calendar .react-calendar__tile {
  line-height: 0px !important;
}

.interview-img h3 {
  color: #000000 !important;
  font-size: 18px !important;
  font-family: "Bold" !important;
  letter-spacing: .5px;
}

.col-akash {
  position: relative;
  background-color: #F1F6FF;
  padding: 10px;
  margin: 0px 15px;
  margin-top: 16px;
  border-radius: 5px;
}

.col-img img {
  width: 58px;
  max-width: 100%;
}

.col-para {
  margin-left: 16px;
}

.col-para h3 {
  color: #000000;
  font-family: "Bold" !important;
  font-size: 13.5px;
}

.col-para p {
  color: #000000;
  opacity: 70%;
  font-size: 9px;
  font-family: "Semi Bold" !important;
}

.col-para h5 {
  color: #000000;
  font-size: 9px;
  font-family: "Bold" !important;
}

.hr-bash {
  background-color: #FFFFFF;
  /* height: 80vh; */
  margin: 0px 5px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding: 35px 0px;
  border-radius: 5px;
}

.hr-box1 img {
  width: 52px;
  height: 52px;
}

.hr-box2 {
  margin-left: 20px !important;
}

.overhidding {
  margin: 0px 25px;
  margin-top: 30px;
  border-radius: 5px;
}

.overhidding1 {
  margin-top: 0px !important;
}

.reg-date img {
  width: 15px;
}

.status {
  display: flex;
  border: 1px solid #ececec;
  justify-content: space-between;
  max-width: 90px;
  width: 100%;
  border-radius: 3px;
  padding: 4px 10px;
}

.theads {
  position: relative;
  z-index: 2;
  height: 55px;
}

.theads::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-bottom: 1px solid #000000;
  opacity: 10%;
}

.sitar {
  position: relative;
  z-index: 2;
}

.sitar::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-right: 1px solid #000000;
  opacity: 10%;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.sitar h4 {
  color: #0A2E57;
  font-size: 16.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.sitar1 h4 {
  color: #0A2E57;
  font-size: 16.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.timer p {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.sno {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.reg-date h4 {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.reg-date p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
  line-height: 15px;
  opacity: .9;
}

.status p {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.tr-head {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #e3e1e1;
}

.theads1 {
  border-top: 1px solid #e3e1e1 !important;
}

.action {
  max-width: 90px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 2px;
  position: inherit;
  /* position: fixed; */
  /* position: absolute;
  top: -19px; */
  z-index: 1000;
}

.edit {
  padding: 7px 0px;
  border-bottom: 1px solid #D9E0E6;
}

.delete {
  padding: 7px 0px;
}

.login-buttons button {
  max-width: 150px;
  width: 100%;
  height: 46px;
}

.calendar1 .react-calendar button {
  border-radius: 50% !important;
}

.calendar1 .react-calendar button:hover {
  color: #ffffff !important;
}

/* .sesij{
  position: absolute;
  background-color: white;
  z-index: 100;
} */

.lesar {
  position: absolute;
  background-color: white;
  width: 170px;
  max-width: 100%;
  z-index: 2000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.calendar1 .react-calendar button abbr:hover {
  color: #FFFFFF !important;
}

.sfgh input {
  width: 252px !important;
}

.calendar1 .react-calendar__month-view__weekdays__weekday abbr {
  color: #000000;
  opacity: 30%;
}

.calendar1 abbr {
  font-size: 10px !important;
  font-weight: 500 !important;
  font-family: "Semi Bold" !important;
}

.calendar1 .react-calendar__tile {
  padding: 22px 0px !important;
}

.fourth-logo1 button {
  color: #0B60FF;
  border: 2px solid #0B60FF !important;
}

.distinguish2 {
  width: 36%;
  max-width: 100%;
  margin: 0 20px;
}

.total-timeCal {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.total-timeCal .total {
  color: #000000;
  font-size: 19px;
  font-family: inter !important;
  padding: 0px 20px;
  font-weight: bold;
}

.total-timeCal hr {
  border-bottom: 1.5px solid #000000;
  opacity: 10%;
  margin-top: 15px;
}

.clock {
  padding: 0px 20px;
  /* margin-top: 20px; */
}

.clock h3 {
  color: #707070 !important;
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 15px;
}

.fan {
  color: #0B56E4 !important;
}

.hh {
  background-color: #F5F9FF;
}

.clock1 {
  margin-top: 10px;
}

.clock1 h2 {
  color: #000000;
  font-family: inter !important;
  font-weight: 600 !important;
  font-size: 22px;
}

.clock-system {
  margin-top: 20px;
}

.clock2 {
  margin-top: 20px;
}

.distinguish-varding {
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px 0px;
  border-radius: 10px;
}

.distinguish-box {
  display: flex;
  background-color: #F1F6FF;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 15px 0px;
}

.distinguish-box img {
  width: 78px;
}

.akash {
  margin-left: 30px;
}

.akash h3 {
  color: #000000;
  font-size: 16px;
  font-family: "Bold" !important;
}

.akash p {
  color: #000000;
  font-size: 11px;
  opacity: 50%;
  font-family: "Semi Bold" !important;
  margin-top: 2px;
}

.akash h4 {
  font-size: 13px;
  font-family: "Bold" !important;
  margin-top: 3px;
}

/* .distinguish-varding */
.distinguish-varding .sasks {
  padding: 0px 20px;
  font-size: 18px;
  font-family: "Bold" !important;
}

.distinguish-varding hr {
  border-bottom: 1.5px solid #000000 !important;
  /* overflow: hidden; */
  opacity: 10%;
  margin-top: 10px;
}

.distinguish-fard {
  padding: 0px 20px;
  margin-top: 20px;
}

.distinguish1 .react-calendar__navigation__label__labelText {
  font-size: 18px !important;
  font-family: "Bold" !important;
}

.distinguish1 .react-calendar__navigation button {
  font-size: 30px;
}

.distinguish1 .react-calendar__month-view__weekdays__weekday abbr {
  color: #000000;
  opacity: 30%;
  font-family: "Semi Bold" !important;
  font-size: 14.5px;
}

.distinguish1 .react-calendar__tile {
  line-height: 12px;
}

.distinguish1 .react-calendar__tile abbr {
  color: #000000;
  font-size: 14.5px;
  font-family: "Semi Bold" !important;
}

.distinguish1 .calend1 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.clock-nav .sat {
  margin: 0 7px;
}

.clock-nav .hrs {
  margin: 0 7px;
}

.clock-nav .puts {
  margin: 0px 7px;
}

.clock-nav .min {
  margin: 0px 7px;
}

.clock-nav .sec {
  margin: 0px 7px;
}

.clock-nav h3 {
  color: #000F2B;
  font-size: 22px;
  font-family: "Bold" !important;
}

.clock-nav p {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000F2B;
  opacity: 50%;
  text-align: left;
}

.bottomji {
  margin: 0px 7px;
  margin-top: 2px;
  position: relative;
}

/* .sas{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
} */
.brake {
  position: absolute;
  background-color: #FFFFFF;
  width: 130px;
  top: 30px;
  right: -5px;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.bottomji .char {
  color: #000F2B;
  font-size: 12px;
  margin-top: 6px;
}

.brakes {
  width: 20px;
}

.logouts {
  width: 25px;
}

.brake p {
  /* margin-right: 10px; */
  white-space: nowrap;
  margin: 0 5px;
  font-size: 15px;
  font-family: "Medium" !important;
}

.bring {
  color: #D9AE00 !important;
  opacity: 100% !important;
}

.out {
  color: #FF5449 !important;
  opacity: 100% !important;
}

.bt {
  padding: 10px 0px;
}

.logout {
  padding: 8px 0px;
}

.brake {
  display: none;
}

.char1 {
  display: none;
  margin-top: 6px;
}

.fat-container img {
  width: 18px;
}

.fat-container p {
  margin-left: 10px;
}

.fat-container textarea {
  height: 500px;
  max-height: 100%;
  position: relative;
  resize: none;
  z-index: 2;
  background-color: #e6e3e3;
  outline: none;
  padding: 20px;
  color: #707070;
  font-family: "Medium" !important;
  font-size: 14px;
  opacity: 70%;
}

.form6 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.form6 button {
  background-color: #DF3333;
  font-family: "Semi Bold" !important;
  color: #FFFFFF;
  border-radius: 5px;
  max-width: 150px;
  width: 100%;
  display: block;
  margin: auto;
  height: 40px;
  margin-top: 40px;
}

.fat-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
}

.fat-container {
  background-color: #FFFFFF !important;
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  padding: 20px;
  margin-top: 80px;
}

.cheers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cheers1 {
  margin-right: 6px;
}

.cheer2 {
  margin-left: 6px;
}

.falend .react-calendar {
  background-color: #ffffff;
  margin-top: 20px;
}

.falend .react-calendar__viewContainer {
  position: relative;
  z-index: 2;
}

.falend .react-calendar__viewContainer::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-top: 1px solid #1479FF !important;
  opacity: 10%;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.falend .react-calendar__month-view__weekdays__weekday {
  margin-top: 10px;
}

.falend .react-calendar__navigation {
  width: 100% !important;
}

.falend .react-calendar__navigation__arrow {
  color: #05273E;
  opacity: 20% !important;
  font-size: 35px;
  font-weight: bold !important;
}

.falend .react-calendar__navigation__label__labelText {
  font-size: 13px !important;
  font-family: inter !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fff !important;
}

.falend hr {
  border-bottom: 2px solid #000000;
  opacity: 10%;
  margin-top: 20px;
}

.cals-dent {
  padding: 0px 20px;
}

.falend .react-calendar__tile {
  line-height: 8px !important;
  font-size: 11px;
  /* border-radius: 50% !important; */
}

.falend .react-calendar__tile abbr {
  color: #000000 !important;
  font-family: "Semi Bold" !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6 !important;
}

.cals-dent img {
  position: absolute;
  right: 30px;
  top: 20px;
}

.chhosemonth {
  margin-top: 30px;
  max-width: 180px;
}

.clock1 p {
  color: #707070;
  font-family: "Medium" !important;
  font-size: 15px;
  margin-left: 10px;
}

.show-all p {
  text-align: center;
  opacity: 50% !important;
  font-size: 14px;
  font-family: "Semi Bold" !important;
}

.santu {
  background-color: #1A6AFF !important;
  width: 32px;
  padding: 5px;
  border-radius: 6px;
  margin-left: 20px;
}

.santu img {
  width: 20px;
  display: block;
  margin: auto;
  max-width: 100%;
}

.theads1 h4 {
  color: #0A2E57 !important;
  font-size: 16px !important;
  letter-spacing: .5px;
  font-family: "Semi Bold" !important;
}

.tr-head1 p {
  color: #0A2E57 !important;
  font-size: 14px !important;
  font-family: "Medium" !important;
}

.dates-pick p {
  font-size: 10px !important;
  font-family: gilroy !important;
  font-weight: 400 !important;
  opacity: 80% !important;
}

.dates-pick h3 {
  color: #0A2E57 !important;
  font-size: 14px !important;
  font-family: "Medium" !important;
}

.tr-head1 .inr {
  color: #32CA91 !important;
}

.alert-min {
  margin-top: 80px !important;
  z-index: 200000;
}

.main_message {
  font-size: 16px !important;
}

#alert-border-3 {
  position: fixed;
  right: 0px;
  max-width: 600px;
}

#alert-border-2 {
  position: fixed;
  right: 0px;
  max-width: 600px;
}


.attend-ctiveWrapempp .hrmActRight55 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.attend-ctiveWrapempp .markAttWrap55 {
  background-color: white;
  width: 100%;
  height: 441px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0px;
}

.attend-ctiveWrapempp .markAttWrap55 .markAtt55 {
  display: flex;
  padding: 0px 20px;
  align-items: center;
}

.attend-ctiveWrapempp .myOficeWrap55 {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.attend-ctiveWrapempp .markAttWrap55 .markAtt55 p {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B2533;
  margin-left: 10px;
}

.attend-ctiveWrapempp .myOficeWrap55 .myOfText55 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .oficTime55 {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.attend-ctiveWrapempp .clockINOUTBtn55 {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}

.attend-ctiveWrapempp .ofSin55 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.attend-ctiveWrapempp .oficTime55 .singlTime55 {
  box-shadow: 2px 5px 15px 0px #0000001A;
  background-color: #F8F9FD;
  width: 90px;
  height: 90px;
  top: 373px;
  left: 996px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
  justify-content: center;
}

.attend-ctiveWrapempp .ofSin55 .day55 {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .oficTime55 .singlTime55 p {
  font-family: inter !important;
  font-size: 34px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .clockIN55 {
  width: 134px;
  height: 48px;
  top: 523px;
  left: 1058px;
  padding: 12px, 30px, 12px, 30px;
  border-radius: 6px;
  gap: 10px;
  /* background-color: #6FD943; */
  background-color: #189877;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attend-ctiveWrapempp .clockOUT55 {
  width: 134px;
  height: 48px;
  top: 523px;
  left: 1058px;
  padding: 12px, 30px, 12px, 30px;
  border-radius: 6px;
  gap: 10px;
  /* background-color: #6FD943; */
  /* background-color: #189877; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9002A;
}

.attend-ctiveWrapempp .clockIN55 span {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.attend-ctiveWrapempp .clockOUT55 span {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.attend-ctiveWrapempp {
  display: flex;
  gap: 42px;
  width: 100%;
  justify-content: space-between;
}


.attenRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;

}


.celWrap {
  width: 672px;
  border: 1px solid #B7DFD5;
  background: #E8F5F1;
  border-radius: 10px;
  max-height: 194px;
}

.celWrap nav {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 12px 12px 0px 0px;
  border: 0px 0px 1px 0px;


}

.celWrap nav span {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.partPeople {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.singcel {
  /* width:221px; */
  height: 94px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 20px 16px 20px 16px;
  gap: 10px;
  border-radius: 12px;

}

.singcel h3 {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;


}

.capWrap {
  position: relative;
}

.capWrap .phrofileimg {
  max-width: 60px !important;
  border-radius: 50%;
}

.capWrap .cap {
  position: absolute;
  top: -20px;
  left: -20px;


}

.singcel p {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: center;

}

.attenLeft {
  display: flex;
  flex-direction: column;
  gap: 32px;
}


.user_class {
  position: fixed;
  /* top: 9%; */
}

.leavewrapping {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(64, 64, 64, 0.3);
  justify-content: center;
  z-index: 1000;
}

.crelevecont {
  width: 455px;
  height: fit-content;
  padding: 20px;
  border-radius: 18px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  border: 1px solid #E3E3E3;
  z-index: 20;
}

.crelevecont2 {
  width: 555px;
  height: fit-content;
  padding: 20px;
  border-radius: 18px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  border: 1px solid #E3E3E3;
  z-index: 20;
}

.user_class_input3 {
  width: 50%;
}

.crelavetopsec {

  display: flex;
  align-items: center;
  justify-content: space-between;

}

.crelavetopsec h3 {

  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.levaecretaeform {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

.user_class_input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.levaecreflex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.levelreasons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.levelreasons label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.showann {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.showann label {
  font-family: Inter;
  color: #1B2533;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.showann span {
  font-family: Inter;
  color: #0B56E4 !important;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0025em;
  text-align: left;
  font-size: 14px;

}

.user_classleave {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user_classleave label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.levelreasons textarea {
  border: 1px solid #D0D4DC;
  background: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  resize: none;
  height: 100px;
  padding: 10px;
}

.leavebuttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.leaverqbtns {
  width: 120px;
  height: 40px;
  border-radius: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
  background: #0B56E4;

}

.levacanclebtns {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #B8BBC0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #666D76;


}

.user_class_input label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.user_class_input input {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.user_class_input textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.user_class_input3 label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.user_class_input3 input {
  height: 44px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;


}

.sk i {
  margin: 0px 6px;
  cursor: pointer;
}

.overall {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lead_dash_card {
  display: flex;
  align-items: center;
  margin-top: 30px;
  /* justify-content: center; */
}

.lead_dash_box {
  width: 100%;
  box-shadow: 0px 8px 32px -2px #1B25330F;
  background-color: #FFFFFF;
  max-width: 100%;
  /* height: 129px; */
  border-radius: 12px;

  /* margin: 0px 10px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 20px;
}

.sing111 {
  margin-left: 15px;
}

.sing1111 {
  margin-left: 15px;
}

.sing11111 {
  margin-left: 15px;
}

.lead_contens1 h3 {
  color: #1B2533;
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  /* white-space: nowrap; */
}

.lead_contens1 h1 {
  color: #1B2533;
  font-weight: 500;
  font-size: 24px;
  font-family: "Inter", sans-serif !important;
  text-align: right;
  margin-top: 15px;
}

.lonj tr,
.lonj td,
.lonj th {
  background-color: white;
  border-left: 0px;
  border-right: 0px;
  white-space: pre-wrap !important;
  font-family: "Inter", sans-serif !important;
  line-height: 16px !important;
  font-size: 11px !important;
}

.my_open1 {
  padding: 0px 20px !important;
}

.userFilterr {
  width: 196px;
  height: 34px;
  padding: 8px 16px;
  border-radius: 10px;
  background: white;
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  border: 1px solid #D0D4DC;
  height: 48px;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
}

.sunis {
  position: relative;
  display: flex;
  align-items: center;
}
.sunis h3{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  min-width: fit-content
}

.sunis img {
  position: absolute;
  left: 264px;
}
.react-tel-input .form-control{
  width: 100% !important;
}
.react-tel-input{
  margin: 5px 0px !important;
}
.sunis select {
  margin-left: 20px;
}

.permiCont select {
  font-family: inter !important;
  width: 100% !important;
}

.applyBtn {
  display: block;
  margin: 50px auto;
  height: 40px;
  width: 200px;
  border-radius: 5px;
}

.applyBtn span {
  font-family: inter !important;
  font-weight: 400;
}

.salay th,
.salay td {
  color: #545962 !important;
}

.taning {
  width: 180px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 32px 0px;
}

.floyu {
  float: right !important;
  cursor: pointer !important;
}

.felas th,
.felas td {
  /* width: 11.1% !important; */
}

.sysok a {
  display: flex;
  align-items: center;
  padding: 15px;
  /* margin: 0px 5px; */
}

.sysok span {
  margin-left: 10px;
  color: #2B2B2B;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.prev_next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.my_things {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.on1 {
  width: 26px;
  height: 26px;
  border: 1px solid #D8D8D8 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px 5px;
}

.on1:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.next button {
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  width: 55px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 5px;
}

.swer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.editleaFrom {
  max-width: 600px !important;
  margin: 0 auto;
  width: 100%;
}

.leave_ct {
  font-family: inter !important;
}

.next-button:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.fkl label {
  font-family: inter !important;
}

.fkl input {
  font-family: inter !important;
}

.safar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.safar button {
  font-family: inter !important;
  width: 200px;
  max-width: 100%;
}

.highlightOption {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: inter !important;
}

.option {
  font-family: inter !important;
  border-bottom: 1px solid #E8E9EB !important;
  padding: 4px 10px !important;
}

.option:hover {
  background: white !important;
  color: #000000 !important;
}

.next button:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.table11 {
  width: 100%;
  background-color: white;
  border: 1px solid #E8E9EB;
  box-shadow: 0px 8px 32px -2px #1B25330F;
  padding: 15px 0px;
  margin-top: 20px;
  min-height: 250px;
  /* max-height: 250px; */
  overflow-y: scroll;
  border-radius: 10px;
}

.table11::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.table11 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.table22::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.table22 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.saka th {}

.table22 {
  width: 100%;
  background-color: white;
  margin-left: 15px;
  overflow-y: scroll;
  min-height: 250px;
  /* max-height: 250px; */
  box-shadow: 0px 8px 32px -2px #1B25330F;
  border-radius: 15px;
  padding: 15px 0px;
  margin-top: 20px;
}

.table333 {
  margin-left: 0px !important;
}

.my_open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}

.checkderop {
  position: absolute;
  transform: translateX(-150px) translateY(-10px);
}

.my_open h3 {
  color: #1B2533;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
}

.thol th {
  /* white-space: nowrap !important; */
  color: #2B2B2B;
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
}

.myleadtit2 {
  font-family: Inter;
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.leadti {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.relt {
  color: #F58E07;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
}

.editLeadreq {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.duedatest {
  font-family: Inter;
  color: #545962;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.droos {
  position: absolute;
  width: 161px;
  height: 134px;
  padding: 8px 0px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  transform: translateX(-150px) translateY(-20px);
}

.droos p {
  cursor: pointer;
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.contactk {
  display: flex;
  align-items: center;
}

.contactk img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.contactk p {
  color: #545962;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  margin-left: 2px;
}

.lead_inp {
  display: flex;
  align-items: center;
  gap: 20px;

}

.lead_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.lead_inp label {
  display: block;
}

.lead_inp1 {
  max-width: 50%;
  width: 100%;
}

.makeitflexcol {
  display: flex;
  gap: 20px;
}

.exceptionwidht {
  width: 100%;
}

.exceptionwidht input {
  /* max-width: calc(100% - 81px ); */
  width: 100%;
}

.lead_inp1 input,
.lead_inp1 select {
  width: 100%;
  height: 48px !important;
  outline: none !important;
  border: 1px solid #D0D4DC;
  color: #666D76 !important;
  font-weight: 400 !important;
  font-family: inter !important;
  padding: 0px 10px;
  border-radius: 10px;
  margin: 5px 0px !important;
}

.tikra {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* height: 60vh; */
}

.selct_div {
  max-width: 736px !important;
  width: 100% !important;
  height: 176px !important;
  border: 1px dashed #8FB1F3;
  display: block;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FB;
}

.selis {
  max-width: 478px;
  width: 100%;
  height: 296px;
  border: 1px dashed #8FB1F3;
  display: block;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FB;
  margin-top: 15px;
}

.browse {
  width: 175px;
  height: 40px;
  /* background-color: #0B56E4; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.browse h3 {
  font-size: 16px;
  color: black;
  text-decoration: underline;
  font-family: inter !important;
  font-weight: 500 !important;
}

.opd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.opd input {
  position: absolute;
  opacity: 0;
}

.upload_an p {
  color: #1B2533;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.sini {
  text-align: center !important;
  color: #2B2B2B;
  font-weight: 500;
  font-size: 24px;
  font-family: inter !important;
}

.semik {
  font-weight: 600;
  font-size: 25px;
  color: #000000 !important;
  font-family: inter !important;
}

.lead_information h2 {
  font-size: 18px !important;
  font-weight: 600;
  color: #000000 !important;
  font-family: inter !important;
}

.lead_information label {
  color: #0F141C !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: inter !important;
}

.lead_inp11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 91px !important;
  width: 100%;
}

.gfg td {
  font-family: inter !important;
}

.gfg label {
  font-family: inter !important;
}

.thml {
  font-family: inter !important;
}

.ACTIVITYsss {
  font-family: inter !important;
}

.sinoid {
  grid-template-columns: repeat(1, 1fr) !important;
}

.inpo1 {
  /* width: 110px; */
}

.inpo2 {
  /* width: 30px; */
}

.font-wrapper {
  font-family: "Inter", sans-serif !important;
}

.tranking th,
.tranking td {
  font-family: inter !important;
}

.selr {
  width: 91px !important;
}

.lead_inp111 {
  display: flex;
  align-items: center;
}

.seng {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid #969BA1 !important;
  margin-right: 15px !important;
}

.jpo {
  margin: 0px 5px;
}

.importB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importB h2 {
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  font-family: inter !important;
}

.canlo {
  width: 86px !important;
  height: 40px !important;
}

.import_vhasa {
  background-color: #ffffff;
  margin-top: 30px;
  border-radius: 10px;
  padding: 40px 0px;
}

.form_filel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_filel h3 {
  color: #2B2B2B;
  font-weight: 500;
  font-size: 24px;
  font-family: inter !important;
  margin-left: 10px;
}

.download_gfg {
  margin-top: 25px;
}

.download_gfg h2 {
  font-size: 16px;
  font-weight: 500;
  color: #49515C;
  font-family: inter !important;
  letter-spacing: 1px;
  /* letter-spacing: 2px; */
}

.download_gfg span {
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
  color: #0B56E4;
}

.srop {
  color: #666D76;
  font-size: 16px;
  font-weight: 400;
  font-family: inter !important;
  text-align: center;
}

.leadLe .sio {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saya {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #0B56E4 !important;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  width: 147px;
  border-radius: 8px;
  height: 40px;
}

.saya p {
  font-size: 16px;
  font-weight: 500;
  color: #0B56E4;
  font-family: inter !important;
}

.user_login {
  background: #0b60ff;
  color: #ffffff;
  border: none;
}

.admin_login {
  border: 1px solid #B3CBF7;
  background: none;
  color: #49515C;
}

.yui {
  background: #0b60ff;
  color: #ffffff;
  border: none;
}

.lion {
  justify-content: center !important;
}

.toyotoyo {
  text-align: center !important;
  color: #1B2533;
  font-size: 14px;
  font-family: inter !important;
}

.react-confirm-alert-body {
  width: 441px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-confirm-alert-body>h1 {
  text-align: center !important;
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  color: #1B2533 !important;
}

.react-confirm-alert-button-group {
  margin-left: 26px !important;
  margin-top: 25px !important;
}

.react-confirm-alert-button-group>button {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: inter !important;
}

.react-confirm-alert-body {
  padding: 40px 30px !important;
  border-radius: 15px !important;
}

.react-confirm-alert-overlay {
  background-color: rgb(87 87 87 / 50%) !important;
}

.skm {
  width: 100% !important;
}

.tasklo {
  color: #0B56E4 !important;
  background-color: white;
}

.statussame {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px !important;
  height: 28px !important;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;

}

.followUp {
  width: 87px !important;
  border: 1px solid #46AD92 !important;
  background: #E8F5F1 !important;
  color: #189877 !important;

}

.Hot {
  width: 41px !important;
  background: #FCEBE6;
  border: 1px solid #E45D3A;
  color: #F58E07;
}

.Cold {
  width: 47px !important;
  background: #D2EBFB;
  border: 1px solid #1CA4FC;
  color: #1CA4FC;
}

.Warm {
  width: 53px !important;
  background: #FEF4E6;
  border: 1px solid #F58E07;
  color: #F58E07;
}

.LEADSsTunav {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.canccfdl {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #B8BBC0;
  background-color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #666D76;
}

.leadUPdateStsus {
  width: 140px !important;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #0B56E4;
  font-family: Inter;
  color:black;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.leadUPdateStsus2 {
  width: 150px;
}

.addNewCont {
  position: relative;
}

.addneEW {
  width: 131px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addneEW p {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.005em;
  text-align: left;

}

.litu {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.noteStaus {
  border: 1px solid #46AD92 !important;
  width: 77px;
  height: 28px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E8F5F1;
}

.noteStaus p {
  color: #189877;
  font-size: 12px;
  font-family: inter !important;
  font-weight: 400 !important;
}

.notedate {
  color: #666D76;
  font-size: 14px;
  font-weight: 500;
  font-family: inter !important;
}

.opeAnew {
  width: 199px;
  height: 104px;
  padding: 8px;
  border-radius: 8px;
  background: #FFFFFF;
  position: absolute;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  display: flex;
  flex-direction: column;
  right: 1px;
  gap: 20px;
  transform: translateY(-10px);
}

.opeAnew p {
  font-family: Inter;
  color: #495D71;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  cursor: pointer;

}

.noRecord {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.ladingstausw {
  font-family: Inter !important;
  border: 1px solid #46AD92 !important;
  width: 77px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8F5F1;

  color: #189877 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  height: 28px;
  border-radius: 27px;

}

.searchdeclose {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchclosde {
  width: 200px;
  height: 38px;
  border-radius: 10px;
  padding: 0px 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.allNotes {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.singlNoteDe {
  display: flex;
  align-items: center;
  gap: 24px;
}

.line_danda {
  height: 40px;
  border-left: 2px solid #189877 !important;
}

.noteTExt {
  max-width: 543px;
  width: 543px;
  color: #1B2533;
  font-weight: 500;
  font-family: inter !important;
  font-size: 16px;
  line-height: 22px;
}

#fentar {
  width: 165px;
  -webkit-appearance: none;
  appearance: none;
}

.doqn {
  position: absolute;
  right: 34px;
}

.doqn1 {
  right: 56px !important;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.test_filter {
  display: flex;
  align-items: center;
  justify-content: end;
}

.noteLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.testok {
  display: flex;
  align-items: center;
  gap: 10px;
  transform: translateX(50px);
}


.morevertimg{
  margin: 0 auto;
}
.makedivcent{
  text-align: center;
}

.noteLabel p {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.noteLabel textarea {
  resize: none;
  width: 100%;
  height: 76px;
  padding: 8px 16px 8px 16px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
  outline: none;
  border-radius: 10px;
  max-width: 709px;

}

.notePresent {
  display: flex;
  /* align-items: center; */
  gap: 40px;

}

.notePresent p {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;


}

.reatlnotecont {
  max-width: 543px;
}

.realNote {
  font-family: Inter;
  color: #101820;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;

}

.sakacont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eidtdel {
  display: flex;
  align-items: center;
  gap: 18px;
}

.eidtdel img {
  cursor: pointer;
}

.noteSaveBtn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  max-width: 709px;


}

.noteSaveBtn .noteSaveBtn2 {
  width: 118px;
  height: 40px;
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B56E4;

}

.noteSaveBtn .noteSaveBtn2 span {
  font-family: Inter;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;


}

.createQquot {
  width: 165px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: center;

}

.createQquot span {
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0B56E4 !important;
}

.invoiceNav {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.invoiceNav h2 {
  font-family: Inter;
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;


}

.invoiceNav .invoiceBtn {
  display: flex;
  align-items: center;
  gap: 16px;

}

.invoiceNav .invoiceCancel {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;

}

.invoiceNav .invoiceCancel span {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.invoiceForm {
  max-width: 1120px;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sinelinvoice {
  display: flex;
  gap: 30px;
  align-items: center;
}

.sinelinvoice label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.sinelinvoice label p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.sinelinvoice label input,
.sinelinvoice label select {
  max-width: 524px;
  width: 100%;
  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;
  background: #F8F9FB;
  outline: none;

}

.sinelinvoice label {
  color: #1B2533;
  font-family: inter !important;
  font-weight: 400;
  font-size: 14px;
}

.norecord {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.allQui {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.allQui .invId {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;

}

.allQui .inName {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: right;


}

.gfg th {
  background-color: white !important;
  font-family: inter !important;
}

.allQui .date {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;

}


.singlquot {
  display: flex;
  align-items: center;
  gap: 40px;
  border-left: 2px solid green;
  padding: 0px 0px 0px 16px;
}

.quaotitionBtn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.quaotitionBtn button {
  width: 118px;
  height: 40px;
  border-radius: 10px;

  background-color: #0B56E4;
}

.quaotitionBtn button span {
  font-family: Inter;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.fgg label {
  color: #1B2533 !important;
  font-weight: 500 !important;
}

.fggg {
  margin-left: 15px;
}

.fgg input,
.fgg select {
  color: #B8BBC0 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.soup {
  width: 61px;
  height: 28px;
  border: 1px solid #E45D3A !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FCEBE6;
}

.soup1 {
  width: 61px;
  height: 28px;
  border: 1px solid #189877 !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E8F5F1;
}

.soup1 p {
  color: #189877 !important;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.soup p {
  color: #E45D3A;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.emp_sino {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.type_date select {
  width: 196px;
  height: 46px;
  border: 1px solid #969BA1 !important;
  background-color: #E8E9EB !important;
  outline: none !important;
  color: #1B2533 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  border-radius: 6px;
  margin-left: 20px;
}

.type_year select {
  width: 196px;
  height: 46px;
  border: 1px solid #969BA1 !important;
  background-color: #E8E9EB !important;
  outline: none !important;
  color: #1B2533 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  border-radius: 6px;
  margin-left: 10px;
}

.btn_export button {
  border: 1px solid #189877 !important;
  width: 99px !important;
  height: 44px !important;
  background-color: #E8F5F1;
  border-radius: 6px;
  margin-left: 10px;
  color: #189877;
  font-weight: 500;
  font-size: 16px;
  font-family: inter !important;
}

.htmj {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.htmj button {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.holm {
  height: 100vh !important;
  background-color: #4040404D;
}

.sijk {
  border-bottom: 1px solid #E8E9EB;
}

.gd {
  font-size: 16px;
  font-weight: 600;
  color: #1B2533;
  font-family: inter !important;
}

.ipsd {
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  color: #1B2533 !important;
}

.secnoveview nav p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.navrighdiv {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.bulk {
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
  color: #ffffff;
}

.cancol {
  border: 1px solid #B8BBC0;
  color: #666D76 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.holm1 {
  max-width: 493px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thj {
  margin-top: 60px;
}

.secnoveview {
  background-color: white;
  width: 50%;
  padding: 20px;

}


.genrt {
  font-family: inter !important;
  font-weight: 500;
  font-size: 14px;
}

.dj {
  display: flex;
  align-items: center;
}

.dli {
  margin-left: 30px;
}

.projectOverView {
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 10px;

  max-width: 650px;
  width: 100%;
}

.secnoveview nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  width: 100%;
}

.noteinput textarea {
  width: 100%;
  min-height: 104px;
  height: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
  resize: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  color: #2B2B2B;
  font-weight: 600;
  letter-spacing: 0.004em;

}

.attsavebtn {
  width: 65px;
  height: 40px;
  gap: 10px;
  border-radius: 10px;
  background: #0B56E4;
  margin: 20px 0px;
}

.attsavebtn span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: white;
}

.showoffercont {
  width: 95%;
  background: #FFFFFF;
  border-radius: 18px;
  padding: 20px;
  margin-top: 20px;
}

.showoffercont h2 {
  font-family: Inter;
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;

}

.selectEmpl {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selectEmpl label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.selectEmpl select {
  font-family: Inter;
  color: #1B2533;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.doSaveBtn {
  width: 70px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.projectOverView .cont {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.projectOverView .cont .proleft {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.projectOverView .cont .proleft p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.Decr h3 {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.embers h3 {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.allMEmb {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.snglme {
  display: flex;
  align-items: center;
  gap: 16px;
}

.snglme img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.snglme p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.Decr {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.embers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Decr p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.allprogress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  margin-top: 30px;
}

.eachProgeer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.eachProgeer h4 {
  width: 100%;
  font-family: Inter;
  color: #101820;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.navbuttons {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.navbuttons button {
  width: 80px;
  height: 40px;
  gap: 10px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  color: #666D76;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;

}

.navbuttons span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  color: #666D76;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;

}

.expennav {
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.expennav button {
  width: 138px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;
  border: 1px solid #0B56E4;

}

.expennav button span {
  font-family: Inter;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;

}

.expencont {
  width: 100%;
  background-color: white;
  padding: 10px;
}

.expensetable th {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #2B2B2B;
}

.expensetable td {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #2B2B2B;
}

.docInputs {
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 12px;
  width: 95%;
  height: fit-content;
  overflow-y: auto;
}

.docInputs label {
  display: flex;
  align-items: center;
  gap: 20px;

}

.docInputs label input {
  width: 369px;
  height: 44px;
  padding: 8px 16px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;

}

.docInputs label button {
  width: 100px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-family: inter;
  height: 44px;
  background: #0B56E4;


}

.todaywords {
  width: 100%;
}

.todaywords input {
  border: 1px solid #D0D4DC;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 10px;
  font-family: Inter;
  color: #B8BBC0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.expedat {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 10px;
}

.same_bar {
  position: fixed;
  top: 50px;
  z-index: 10000;
  display: none;
  background-color: white;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.previewiamges {
  background-color: red;
  max-height: 250px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #D0D4DC;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px;
  justify-content: space-between;
}

.previewiamges nav {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

}

.previewiamges img {
  width: 100%;
  height: 85%;
}

.lodmorebtns {
  width: 123px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;

}

.lodmorebtns span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.allnotiftwrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 400px;
}

.allnotifywrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 400px;
}

.annoucewrapshow{
  width: 100%;
  background-color: white;
  margin: 20px 0px;
}
.annoucewrapshowcnt{
  max-width: 75vw;
  width: 100%;
}

.addpading{
  padding: 10px 0px;
}

.timelogscont{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}

.timelogscont span{
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.005em !important;
  color: black !important;
}

@media only screen and (min-width:1000px) {
  .oppl {
    display: block !important;
  }
}

@media only screen and (max-width:1000px) {
  .same_bar {
    display: block !important;
  }

  .attend-ctiveWrap {
    flex-direction: column-reverse;
  }

  .hrLefThi {
    width: 100% !important;
    max-width: 100% !important;
  }



  .hrmActLeft {
    width: 100% !important;
    max-width: 100% !important;
  }

  .attend-ctiveWrap .hrmActRight {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width:900px) {
  .hrLefFir {
    flex-wrap: wrap !important;
  }

  .holiHead {
    padding: 0px 20px;
  }

  .lead_dash_card {
    flex-wrap: wrap;
    gap: 15px;
  }

  .sing111 {
    margin-left: 0px;
  }

  .sing11111 {
    margin-left: 0px;
  }

  .my_things {
    flex-direction: column;
    margin-top: 0px;
  }

  .table22 {
    margin-left: 0px;
  }

}

@media only screen and (max-width:680px) {
  .leadThings {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .leads_btn {
    flex-wrap: wrap;
    gap: 15px;
  }

  .lead_btn {
    margin-left: 0px;
  }

  .lead_content1 h2 {
    text-align: center;
  }

  .lead_content1 p {
    text-align: center;
  }

  .em {
    padding: 32px 20px;
  }

  .fiterWrap {
    flex-wrap: wrap !important;
    height: auto !important;
    justify-content: center;
    align-items: center !important;
  }
}

@media only screen and (max-width:450px) {
  .fiterWrap {
    flex-direction: column !important;
  }

  .fiterWrap h3 {
    margin-top: 15px;
  }

  .hrmsFri h2 {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

@media only screen and (max-width:400px) {
  .time_emp_desh_flex {
    gap: 15px;
  }

  .attend-ctiveWrap .markAttWrap {
    height: auto !important;
  }

  .attend-ctiveWrap .oficTime .singlTime {
    width: 55px !important;
    height: 55px !important;
  }

  .attend-ctiveWrap .oficTime .singlTime p {
    font-size: 20px !important;
  }

  .clockINOUTBtn svg {
    display: none !important;
  }

  .sixth-logo p {
    font-size: 12px;
  }

  .dFirLef h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .sixth-logo .bottom {
    display: none;
  }

}

.emailvalidinput {
  border: 1px solid red !important;
}


.uploadprowrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
}

.uploadprowrap p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #101820;
  color: blue;
  text-decoration: underline;
}

.leadUpldProf {
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.FaUploadfds {
  cursor: pointer;
  color: #666D76;
  font-size: 23px;
}

.makethifles {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
}
.test_formis{
  margin-top: 100px;
}
.siubmitbtnlead {
  background-color: #0B56E4 !important;
  color: white !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 18px !important;
  width: 120px !important;
  border: none !important;

}


.imagewrapleac {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B3CBF7;
  border-radius: 50%;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  margin-left: 11px;

}
.syss{
  margin-left: 2.5px !important;
}
.makeitsticky {
  padding: 10px 0px;
  /* position: fixed;
  left: 0;
  background-color: white;
  z-index: 10; */
  position: fixed;
  background: white;
  width: 100%;
  justify-content: end;
  left: 0;
  top: 81px;
  height: 81px;
}

.taskassignwrap{
  height: 100% !important;
 width: 100% !important;
 background-color: white;

}

.taskassignwrap2{
  height: 100% !important;
}

.lessfontweight{
  font-weight: 400 !important;
}

.addonefont{
  font-size: 15px !important;
}


.connected{
  background: #FFD22E;
  color: black;

}
.Nurturing{
  background-color: #FF8F2C;
  color: black;
}
.Qualified{
  background-color: #35ff3e;
  color: black;
}
.Unqualified{
  
  background-color: #3342FF;
  color: white;
}
.Converted{
  background-color: #FF31ED;
  color: black;
}
.Converteds{
  background-color: #692DFE;
  color: white;
}
.Newleadstatus{
  background-color: #2FE7FF;
  color: white;
}
.Junk{
  background-color: #FF3033;
  color: white;
}

.lessfontparas{
  font-weight: 400 !important;
  font-size: 14px !important;
}

.anDesWrap.makefdufsld {
  grid-column: 1 / -1; 
  width: 100%; 
}


.givethissowidth{
min-width: 50%;
}

.adclient_form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  max-height: 400px;
  padding: 0px 10px 0px 0px;
}

.adclient_form::-webkit-scrollbar {
  width: 1em;
}

.adclient_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

.adclient_form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #cccccc;
}

/* Awards button css is written here  */
.adminsetupBtn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family:  sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.adminsetupBtn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.adminsetupBtn:focus {
  outline: none;
}

.thredotwrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.threedot_lead{
  cursor: pointer;
  min-width: 25px;
  max-width: 25px;
  font: 30px;

}

.dropswrap{
  width: 100px;
  height: fit-content;
  background-color: white;
  box-shadow: -1px -1px 19px -9px rgba(0,0,0,0.75);
position: absolute;
bottom: 60px;
right: 30px;

}

.dropswrap p{
  display: flex;
  align-items: center;
  padding: 10px 5px;
  gap: 5px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.dropswrap p span{
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
}

.dropswrap .lead_icon{
  font-size: 20px;
}

.opensharpopwra{
  background-color: rgb(0, 0, 0 , 0.4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

}

.opensharecont{
  background-color: white;
  width: 500px;
  max-height: 500px;
  height: fit-content;
  margin-top: 120px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
}


.opensharecont nav{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  
}
.opensharecont nav h2{
  font-size: 16px;
  font-weight: bold;
  font-family: inter !important;

}

.sharecross{
  font-size: 24px;
  cursor: pointer;
}


.popup_formdiv2{
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  padding: 0px 10px 0px 0px;
  /* height: ; */
}

.popup_formdiv2::-webkit-scrollbar {
  width: 1em;
}

.popup_formdiv2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

.popup_formdiv2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #cccccc;
}
.leadInFir2{
  width: 100% !important;
}
.docuSec{
  width: 100% !important;
}
.docInputs{
  width: 100% !important;
}
.showoffercont{
  width: 100% !important;
}
.docuThird{
  width: 100% !important;
}
.extpo{
  height: 450px !important;
}
.openformisk{
  height: 400px !important;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;

}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");


body {
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
}

.action_box {
  position: absolute;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.114);
  border-radius: 5px;
  background-color: white;
  z-index: 1000;
  right: 35px;
}
.yasir{
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.such_thing {
  display: flex;
  align-items: center;
}


.employee_sal_card {
  background-color: #ffffff;
  border: 1px solid #d8dbdf;

  border-radius: 10px;
  box-shadow: rgba(208, 212, 216, 0.2) 0px 8px 24px;

  padding: 5px 0px 20px 0px;
}

.sklin {
  width: 100% !important;
}

.sklin th,
.sklin tr,
.sklin td {
  background-color: white !important;
}

.sipi th {
  color: #2B2B2B !important;
  font-weight: 500 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.fdf th,
.fdf td {
  font-family: inter !important;
  font-weight: 400 !important;
  color: #40444B !important;
}

.oklo {
  color: #0B56E4 !important;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.ghj {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ghj button {
  background-color: #E8E9EB;
  border: 1px solid #B8BBC0;
  width: 70px;
  height: 40px;
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: #666D76;
  border-radius: 5px;
}

.opos th,
.opos td {
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.salary_details {
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  height: 275px;
  max-height: 100%;
  background-color: #ffffff;
  /* padding: 20px; */
}

.salary_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E8E9EB !important;
  padding: 15px 20px;
}

.salary_head11 {
  display: flex;
  align-items: center;
}

.salary_head11 h2 {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.salary_body {
  padding: 20px;
  display: flex;
  align-items: center;
}

.salary_bn h3 {
  color: #0F141C;
  font-weight: 600;
  font-family: inter !important;
  font-size: 14px;
}

.salary_bn p {
  color: #545962;
  font-weight: 500;
  font-size: 12px;
  font-family: inter !important;
  margin-top: 5px;
}

.Employee_set {
  display: flex;
  align-items: center;
}

.salary_bn1 {
  margin-left: 100px;
}

.emp_selo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
}

.removePermsion {
  font-size: 20px;
  margin: 0 40px;
  font-weight: 500;
  font-family: inter !important;
}

.sonit {
  right: 20px;
  top: 15px;
}

.employee_set1 {
  width: 100% !important;
  margin: 0 10px;
}

.employee_set2 {
  width: 100% !important;
  margin: 0px 10px;
}

.salary_bdy11 {
  padding: 0px !important;
}

.sipi1 th {
  font-size: 12px !important;
}

.action_so {
  display: flex;
  align-items: center;
}

.action_so img {
  margin: 0px 5px;
}

.opos1 td {
  font-weight: 500 !important;
  font-size: 11px !important;
}

.lokig {
  height: 100vh !important;
  background-color: #4040404D;
}

.sekin {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 425px;
  width: 100%;
}

.sekin1 {
  max-width: 599px !important;
  width: 100% !important;
}

.basic_sel {
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  font-family: inter !important;
}

.okad {
  border-bottom: 1px solid #E8E9EB !important;
}

.somoi {
  font-family: inter !important;
  font-weight: 600;
}

.buttons_formo {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sarfros {
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  color: #ffffff;
}

.sarfros1 {
  border: 1px solid #B8BBC0 !important;
  color: #666D76;
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  margin-left: 10px;
}

.kinh label {
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400 !important;
  color: #1B2533;
}

.kinh select {
  font-weight: 400;
  font-size: 14px;
  font-family: inter !important;
  color: #1B2533 !important;
  height: 48px;
  border: 1px solid #D0D4DC !important;
  outline: none !important;
}

.kinh input {
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  font-family: inter !important;
  color: #1B2533 !important;
  border: 1px solid #D0D4DC !important;
  outline: none !important;
  padding: 0px 20px;
}

.emp_selo input {
  border: 1px solid #D0D4DC !important;
  width: 247px;
  height: 44px;
  color: #666D76 !important;
  font-size: 14px !important;
  outline: none !important;
  font-family: inter !important;
  margin-top: 10px;
  /* padding: 0px 20px !important; */
}

.loader {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: #189877;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader2 {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: #DD3409;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.colp {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Inter", sans-serif !important;
}

.yik {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

/* .hidden{
  display: block !important;
} */
.img_sing {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
}

.department_test {
  width: 100% !important;
}

.tuser_class {
  position: fixed !important;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}

.popup33 {
  width: 45%;
}

.login-page {
  width: 100%;
  padding: 60px 60px 80px 60px;
  gap: 8px;
  border-radius: 12px 0px 0px 12px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.testingk {
  width: 40% !important;
  border-radius: 10px !important;
}

.auth4 {
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-pageMain {
  max-width: 1069px;
  margin: 0 auto;
  width: 100%;
  padding: 60px 60px 80px 60px;
  gap: 8px;
  border-radius: 12px 0px 0px 12px;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;


}

.login-pageMain3 {
  max-width: 1069px;
  margin: 0 auto;
  width: 100%;
  padding: 60px 60px 80px 60px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 100px;
  max-height: 616px;


}




.termanduse {
  position: absolute;
  bottom: 20px;
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;


}

.buttonsWrap {
  width: 100%;
  transform: translateX(20px) translateY(20px);
  /* padding: 20px 20px; */
}

.leadThings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-page3 {
  height: 654px;
  max-height: 100%;
  border: 1px solid #f4f4f4;
}

.leads_btn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.leads_btn2 {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.actions {
  border: 1px solid #B8BBC0;
  width: 119px;
  height: 40px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border: 1px 0px 0px 0px;
  border-radius: 15px;
  font-family: Inter;
  font-size: 16px;
  color: #666D76;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;


}

.laed1 {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.leaftlead2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.leaftlead2 span {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.mylead {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.leaftlead2 input {
  width: 265px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border: 1px 0px 0px 0px;
  border-radius: 10px;
  color: #1B2533;


}

.leftlead1 {
  margin: 20px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.inptsearch {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 308px;
  height: 48px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid #D0D4DC;

}

.inptsearch input {
  width: 288px;
  height: 46px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border: 1px 0px 0px 0px;
  font-family: Inter;
  color: #666D76;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;


}

.inptsearch span {
  display: flex;
  align-items: center;
  gap: 16px;

}

.refresh {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 162px;
  border: 1px solid #0B56E4;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
}

.thebuttn {
  display: flex !important;
  justify-content: flex-start !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.lonj td {
  height: 100% !important;
}

.ref1 {
  color: #0B56E4;
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
}

.lead_content1 h2 {
  color: #101820;
  font-weight: 600;
  font-size: 24px;
  font-family: "Inter", sans-serif !important;
}

.lead_content1 p {
  color: #666D76;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  margin-top: 3px;
}

.lead_btn {
  background-color: #0B56E4;
  width: 123px;
  height: 40px;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
}

.lead_btn2 {
  background-color: #0B56E4;
  width: 186px;
  height: 40px;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  padding: 0px 2px 0px 10px;
  gap: 3px;

}

.singWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}


.login-page2 h2 {
  font-weight: 600;
  font-family: inter !important;
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 10px;
}

.forgetSumtBtn {
  width: 100%;
  max-height: 46px !important;
  gap: 8px;
  border-radius: 4px;
  background: #0B56E4;

}

.forgetSumtBtn span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;

}

.access {
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400;
}

.login-page2 p {
  color: #64686d;
  font-size: 17px;
  font-family: "Medium";
  opacity: 80%;
}

.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  display: inline-block;
  --field-padding: 12px;
}

.showpassicon{
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
}

.systSset img{
  max-width: 16px;
  max-height: 16px;
  width: 16px;
  height: 16px;
}
.dash-wrap img{
  max-width: 16px;
  max-height: 16px;
  width: 16px;
  height: 16px;
}
.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
  border-radius: 6px;
  height: 46px;
  max-height: 100%;
  font-family: inter !important;
  color: #666D76 !important;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  /* color: #aaa; */
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  font-family: inter !important;
  color: #666D76 !important;
}


.oveviewBtn {
  color: orange !important;
  cursor: pointer;
}

.silom{
  margin-left: 0px !important;
  padding: 0px 20px !important;
}

.prodetailTable th,
td {
  background: #FFFFFF;
  border-bottom: 1px solid #E8E9EB !important;
  border-top: 1px solid #E8E9EB !important;
}

.prodetailTable th {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.prodetailTable td {
  font-family: Inter;
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.custom-field input.dirty+.placeholder,
.custom-field input:focus+.placeholder,
.custom-field input:not(:placeholder-shown)+.placeholder {
  top: -5px;
  font-size: 15px;
  color: #0b60ff;
  font-family: inter !important;
  color: #666D76 !important;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 2px solid #d1d1d1;
  transition: border-color 0.3s ease;
  display: inline;
  opacity: 0.8;
  width: 100%;
  background-color: #E8E9EB !important;
  border: 1px solid #D0D4DC;
  font-family: inter !important;
  color: #666D76 !important;
}


/* manish side bar admin dashboard  */
.side-dash-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  width: 100%;
  height: 44px;
  cursor: pointer;
  margin-left: 20px;
  /* padding: 0px 20px; */
}
.semo{
  color: #0B56E4 !important;
}
.sidemargin {
  margin-left: 0px !important;
}

.HRMS-dash-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  padding: 0px 20px;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.HRMS-wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.HRMS-wrap p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299; */
}


.side-dash-box .dash-wrap {
  display: flex;
  align-items: center;
  gap: 12px;

}

.side-dash-box .dash-wrap p {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.allDasCon {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.allDasCon .setWrap {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  gap: 4px;
}

.adpading{
  padding: 0px 20px;
}

.allDasCon .setWrap p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #DEDEE6;
}

.systSset {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
  cursor: pointer;
}

.allDasCon .systSset {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  gap: 12px;
  cursor: pointer;
}

.allDasCon .systSset span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299;   */
}



.systSset span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299;   */
}



.adDasWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.alladminDash-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */

}


.sinADDasItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  gap: 12px;
  padding: 0px 20px;
  cursor: pointer;
}

.dITitl {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #7E8299; */
}

.dashItemp {
  /* color: #F3F6F8; */
  font-family: Inter;
  font-size: 14px;
}


/* manish side bar admin dashboard  */

.custom-field.one input+.placeholder {
  left: 8px;
  padding: 0 5px;
  font-size: 17px;
  top: 24px;
  color: #64686d !important;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #0b60ff;
  transition-delay: 0.1s;
}
input{
   outline: none !important;
}
.custom-field.one input.dirty+.placeholder,
.custom-field.one input:not(:placeholder-shown)+.placeholder,
.custom-field.one input:focus+.placeholder {
  top: 0;
  font-size: 15px;
  font-family: "Medium" !important;
  font-style: italic;
  color: #0b60ff !important;
  background: #fff;
  width: auto;
}
.no_data{
  font-size: 22px;
}
.login-page1 {
  max-width: 100%;

}

.kushel-logo2 {
  max-width: 174px;
  width: 100%;

}


.kushImg {
  max-width: 172px;
  max-height: 54px;
}


.login-page2 {
  width: 538px;
  position: relative;
  max-height: 600px;
  height: fit-content;
  padding: 30px 40px;
  gap: 8px;

}

.formgotPassWidht {
  margin-top: 10px;
  width: 100%;
  justify-content: flex-start;
}

.login-page2 button {
  width: 100%;

  height: 46px;
  border-radius: 6px;

  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 19.5px;
  margin-top: 35px;
}

.sign-information p {
  color: #64686d;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}

.singEmp_profileL img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.sign-information span {
  color: #0b60ff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 17px;
}

/* .okij{
  width: 100% !important;
}
.okij input{
  width: 100% !important;
} */
.okij {
  margin: 10px 0px !important;
}

.serad {
  padding: 20px;
}

.emm {
  margin-top: 80px !important;
}

.taskans11 {
  white-space: nowrap !important;
}

.taskAns{
  text-align: center;
}

.forget {
  color: #0B56E4 !important;
  font-family: inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: inter !important;
}

.login_new_div {
  /* padding: 50px; */
}

.popup110 {
  height: 420px !important;
  max-height: 420px !important;
}

.popingson {
  height: 397px !important;
}

.indexest {
  position: relative;
  z-index: 100000000;
}

.otp-verify {
  width: 100%;
  margin-top: 40px;
}

.otp-verify .otp {
  border: 2px solid #0b60ff;
  height: 75px;
  width: 100px;
  max-width: 100%;
  border-radius: 5px;
  outline: none;
  text-align: center;
  position: relative;
  z-index: 2;
  font-family: "Semi Bold";
  font-size: 34px;
}

.next-btn {
  /* margin-top: 80px !important; */
}

.otp-verify .otp:hover {
  background-color: #bdd5f6;
  color: #0b60ff;
  border: none;
  transition: 0.5s;
}

.Employee-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* position: relative; */
  z-index: 2000;
  background-color: #ffffff;
  position: fixed;
  right: 0px;
  top: 0px;
}

.aka {
  color: #168A6C;
  font-weight: 500;
  font-size: 11px;
  font-family: "Inter", sans-serif !important;
}

.olo {
  width: 270px !important;
}

.olo1 {
  width: 270px !important;
}

/* manish admin navbar css  */

.logo-namewrap {
  display: flex;
  align-items: center;
  gap: 90px;
}

/* manish admin navbar css  */

.checkta {
  margin-left: 7px !important;
  height: 15px !important;
  margin-top: 12px !important;
  border: 1px solid #0b60ff !important;
}

.seri {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logo img {
  max-width: 100px;
  width: 100%;
}

.second-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}


.second-logo img {
  width: 12px;
}

.second-logo p {
  color: #000f2b;
  font-size: 21px;
  font-family: Inter !important;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.navProfiIcons {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navProfiIcons img {
  cursor: pointer;
}

.third-logo input {
  border: 2px solid rgb(236, 236, 236);
  outline: none;
  width: 400px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0px 20px;
  font-family: "Medium" !important;
  font-size: 15px;
  position: relative;
  /* color: #00112F;
  opacity: 30%; */
}

.unpaid {
  width: 61px !important;
  height: 28px !important;
  border: 1px solid #E45D3A !important;
  border-radius: 27px !important;
  background: #FCEBE6 !important;
  padding: 10px 20px;
  color: #E45D3A;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.MdDeletepaslip{
  font-size: 20px;
}

.paid {
  width: 61px !important;
  height: 28px !important;
  border: 1px solid #189877;
  border-radius: 27px !important;
  background: #E8F5F1;
  padding: 10px 20px;
  color: #189877;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;

}

.toglwCont {
  position: relative;
}

.paysliptdimages{
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.paysliptdimages img{
  max-width: 16px;
  cursor: pointer;
}
.togglewrap {
  position: absolute;
  background-color: white;
  width: 170px;
  z-index: 100;
  height: fit-content;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  top: 50px;
  right: 65px;
}

.togglewrap label{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
}

.togglewrap label img{
  max-width: 20px;
}
.togglewrap p {
  cursor: pointer;
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  padding: 10px 10px;
  border-bottom: 1px solid #E8E9EB;
  transition: 0.2s;

}

.togglewrap p:hover {
  background-color: #0B56E4;
  color: white;

}

.openPaywrap {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  background: #4040404D;
  height: 100%;
  z-index: 200;

}

.hidenOverflow {
  overflow: hidden !important;
}

.openPayCont {
  width: 814px;
  height: 580px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transform: translateX(300px) translateY(100px); */
}

.kdslogo {
  max-width: 150px;
  max-height: 45px;
}

.openPayCont nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.openPayCont nav h2 {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.paydetails {
  display: flex;
  gap: 50px;
  max-width: 600px;
  width: 100%;
  justify-content: space-between;
}

.paydetails .paydetailLeft {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.totalErWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-top: 24px;
}

.paidWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.paidWrap h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2B2B2B;
}

.paidWrap p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

}

.enrcont {
  display: flex;
  align-items: center;
  gap: 8px;

}


.paydetails .paydetailLeft label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paydetails .paydetailLeft label p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.paydetails .paydetailRight {
  display: flex;
  flex-direction: column;
  gap: 8px;

}

.paydetailRight h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

  color: #2B2B2B;
}

.d_slip {
  display: flex !important;
  gap: 10px;
}

.print_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.print_slip {
  width: 150px;
  max-width: 100%;
  display: block;
  margin: auto;
}

.payslip_text {
  font-family: inter !important;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.paydetails .paydetailRight p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.vhg th,
.vhg td {
  font-family: inter !important;
}

.loader54 {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 20px 400px;
}

.prevNextWrap {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.assettable th{
  white-space: nowrap !important;
  font-size: 11px !important;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none !important;
}
.prebBtN {
  border: 1px solid black;
  padding: 5px 20px;
  border-radius: 10px;
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fourth-logo button {
  border: 2px solid #085881;
  width: 180px;
  max-width: 100%;
  height: 40px;
  border-radius: 3px;
  font-size: 17px;
  font-family: "Semi Bold" !important;
  color: #085881;
}

.fifth-logo img {
  width: 45px;
}

.sixth-logo .john {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.sixth-logo .bottom {
  width: 12px;
}

.sixth-logo p {
  font-size: 14px;
  font-family: Inter !important;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* .sight{
  position: relative;
  top: 80px;
} */
.sidebars {
  /* background-color: #000f2b !important; */
  background-color: #ffffff;
  padding: 24px 0px 50px 0px;
  border-right: 1px solid #e8e6e6;
  /* margin-top: 69px; */
}


.sidebars1 {
  margin-top: 69px;
}

.allNavItem {
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* padding: 12px; */
}

.dashNW span {
  font-family: Inter;
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.most {

  display: flex;
  align-items: center;

}

.dashNW {
  display: flex;
  align-items: center;
  gap: 8px;
}

.most span {
  font-size: 13px !important;
  font-family: "Semi Bold" !important;
  opacity: 80% !important;
  letter-spacing: 0.5px !important;
  /* color: #ffffff !important; */
}

.most svg {
  color: #ffffff;
  opacity: 70% !important;
}

.red-box {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.white-box {
  position: absolute;
  bottom: 20px;
  background-color: #203a44;
  max-width: 180px;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  display: none;
  /* opacity: 15%; */
}

.white-box img {
  width: 70px;
  height: 70px;
  display: block;
  margin: auto;
  position: absolute;
  top: -40px;
  left: 54px;
}

.black-box h3 {
  color: #ffffff;
  font-family: "Semi Bold" !important;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  margin-top: 18px;
}

.black-box p {
  color: #ffffff;
  font-size: 11px;
  font-family: gilroy !important;
  text-align: center;
  opacity: 50%;
}

.black-box button {
  width: 130px;
  max-width: 100%;
  position: relative;
  z-index: 2;
  height: 38px;
  display: block;
  margin: auto;
  margin-top: 12px;
  color: #ffffff;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  font-size: 11px;
  border-radius: 8px;
}

.black-box button::before {
  background-color: #ffffff;
  opacity: 15%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* ====================hrms==================== */
/* .devta {
    width: 100%;
    margin-left: 16rem;
} */
.employee-dash {
  display: flex;
  position: relative;
  background-color: #f5f5f5;
}


.tm {
  width: 100%;
  /* background-color: #f0f6ff; */
  background-color: #f5f5f5;
}

.em {
  /* padding: 62px 70px; */
  padding: 32px 30px;
  margin-top: 69px;
  position: relative;
}

.em22 {
  padding: 32px 30px;
}

.emwrap {
  margin-top: 120px;
}

.em1 {
  padding: 0px 0px;
}

.logobar {
  background-color: #ffffff;
  padding: 10px 30px;
  box-shadow: rgba(149, 157, 165, 0.2);
}

.logobar img {
  max-width: 160px;
  width: 100%;
}

.bedge {
  display: flex;
  /* width: 100%; */
}

.bret {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-top: 3px solid #1e6dff;
}

.bret .btr {
  width: 40px;
}

.bret p {
  color: #000f2b;
  font-size: 21px;
  font-family: "Bold" !important;
  letter-spacing: 0.5px;
}

.bret .plus {
  width: 40px;
}

.attendence-yes {
  background-color: #ffffff;
  padding: 25px;
}

.attendence-yes h3 {
  color: #000f2b;
  opacity: 50%;
  font-size: 17.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.atten-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 5px 20px;
  padding-bottom: 15px;
  width: 100%;
  margin-top: 20px;
}

.atten-section::before {
  background-color: #000000;
  opacity: 5%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.atten-section p {
  color: #000000;
  font-family: "Bold" !important;
  font-size: 19.5px;
}

.atten-section h5 {
  color: #000000;
  opacity: 50%;
  font-size: 9px;
  font-family: "Semi Bold" !important;
}

.first-bedge {

  width: 100%;
  overflow-y: scroll;
  height: 690px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
}

.first-bedge::-webkit-scrollbar {
  display: none !important;
  width: 0px;
}

.first-bedge::-webkit-scrollbar-thumb {
  display: none !important;
  width: 0px;
}

.first-bedge::-webkit-scrollbar-track {
  display: none !important;
  width: 0px;
}

.metting_div_surbhi {
  padding: 35px 0px;
  display: flex;
  gap: 35px;
}

.time_emp_desh_flex {
  display: flex;
  gap: 47px;
}

.time_emp_desh_flex2 {
  display: flex;
  gap: 20px;
  align-items: center;
}

.time_emp_desh {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.time_emp_desh h5 {
  font-family: Inter;
  color: #0B56E4;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.001em;
  text-align: center;


}

.time_emp_desh p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;

}

.leaves_request_emp {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.leaves_request_emp button span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
  color: white;
}

.leave_setion_emp {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.totel_leave_allowance1 {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background: whitesmoke;
  width: 50%;
}

.hrLefThi22 {
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 5px;
  margin: 35px 0px;

}

.totel_leave_allowance1 p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hrLefThi22 h2 {
  padding: 20px 20px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #060606;
}

.second-bedge {
  width: 100%;
}

.line img {
  height: 55px;
  width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.hiiihih {
  gap: 20px;
  align-items: center;
}

.celeberation {
  width: 60%;
  background-image: url("./Components/images/pong.png");
  /* height: 100%; */
  padding: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  margin-top: 35px;
}

.celeberation::before {
  content: "";
  background-color: #1e6dff;
  opacity: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.celeberation .cel-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cel-head h3 {
  color: #000f2b;
  font-size: 20px;
  font-weight: 400;
}

.cel-head p {
  color: #0b60ff;
  font-size: 18px;
  font-family: "Semi Bold" !important;
}

.cel-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.cel-box {
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  margin: 10px;
}

.cel-boxing {
  display: flex;
  align-items: flex-start;
}

.cel-boxing1 img {

  max-width: 100%;

}

.cel-boxing2 h2 {
  color: #0B60FF;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.5px;
}

.cel-boxing2 p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-family: "Medium" !important;
}

.cel-boxing2 button {
  width: 147px;
  max-width: 100%;
  border: 2px solid #000F2B80;
  font-size: 14px;
  height: 34px;
  font-family: "Semi Bold" !important;
  color: #000F2B;
  border-radius: 5px;
  margin-top: 15px;
}

.new-joiner {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 35px;
}

.joiners1 {
  width: 100%;
  position: relative;
  z-index: 2;
  margin-right: 30px;

  /* margin-left: 10px; */
}

.joiners1 img {
  display: block;
  margin: auto;
  width: 46px;
  margin-top: 20px;
}

.joiners1 h2 {
  text-align: center;
  font-size: 18px;
  color: #0b60ff;
  font-family: "Semi Bold" !important;
}

.joiners1 p {
  text-align: center;
  font-size: 14px;
  font-family: "Medium" !important;
  opacity: 50%;
}

.joiners2 {
  position: relative;
  z-index: 2;
  padding: 0px 30px;
  margin-right: 40px;
  margin-top: 10px;
  /* margin-left: 10px; */
}

.joiners2::before {
  content: "";
  border-left: 2px solid #707070;
  opacity: 20%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.joiners2 P {
  max-width: 100%;
  /* width: 24em; */
  font-family: gilroy;
  font-size: 14.5px;
  color: #000000;
  opacity: 60%;
}

.updateUser label {
  font-family: inter !important;
}

.new-joiner h3 {
  font-size: 18px;
  color: #000000;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
  margin-top: -10px;
}

.new-joiner span {
  color: #000000;
  font-size: 13px !important;
}

.hr .hr1 {
  border-bottom: 2px solid #707070;
  opacity: 20%;
  margin-top: 10px;
  margin-left: -25px;
  margin-right: -25px;
}

.calend {
  background-color: #ffffff;
  /* margin-top: 35px; */
  border-radius: 10px;
  padding: 25px 0px;
}

.calend1 {
  margin-top: 0px;
}

.calend-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.calend-head h2 {
  color: #1B2533;
  font-family: inter !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.calend-head p {
  color: #0b60ff !important;
  font-family: "Semi Bold" !important;
  font-size: 14.5px;
}

.react-calendar {
  width: 100% !important;
  max-width: 100% !important;
  border: none !important;
}

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__tile {
  padding: 24px 0px !important;
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none !important;
}

abbr:where([title]) {
  text-decoration: none !important;
}

.user-profile {
  position: absolute;
  top: 56px;
  padding: 10px 20px;
  border-radius: 5px;
}

.user-profile p {
  margin: 10px 0px;
}

.tys {
  display: block;
}

.kys {
  display: none;
}

.admin-main {
  min-height: calc(100vh-80px);
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0px 10px;
  position: relative;
}

.admin-mainnew {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.admin-main .plus1 {
  width: 180px;
  max-width: 100%;
  position: absolute;
  bottom: -5px;
  left: 85%;
  cursor: pointer;
}

.loj {
  position: relative;
  z-index: 2;
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loj::before {
  content: "";
  background-color: #0b60ff;
  opacity: 15%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.loj img {
  display: block;
  margin: auto;
  width: 28px;
}

.main-box {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 10px 15px;
  margin: 0px 25px;
  /* margin-top: 30px; */
  max-width: 400px;
  width: 100%;
  cursor: pointer;
}

.main-boxes {
  margin-top: 30px;
}

.main-box::before {
  content: "";
  background-color: #0b60ff;
  opacity: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.main-box2 {
  margin-left: 30px;
}

.main-box2 h3 {
  color: #000f2b;
  opacity: 80%;
  font-size: 18.5px;
  font-family: Inter !important;
  font-weight: bold;
}

.home-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
}

.popup-container {
  max-width: 650px;
  width: 100%;
  /* height: 100%; */
  background: white;
  padding: 70px 0px;
  border-radius: 10px;
  margin: 20px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .home-pop .cut{
  width: 100px;
} */
.home-pop .cut {
  width: 23px;
  /* height: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  z-index: 90;
  right: 1rem;
  cursor: pointer;
}

.pops-man h3 {
  color: #000f2b;
  text-align: center;
  font-size: 21px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.select-typ1 {
  margin-top: 30px;
}

.select-typ1 img {
  max-width: 90px;
  width: 100%;
  margin: 0px 35px;
}

.arrow-btn button {
  background-color: #0b60ff;
  width: 160px;
  height: 38px;
  display: block;
  margin: auto;
  margin-top: 60px;
  border-radius: 10px;
}

.arrow-btn img {
  display: block;
  margin: auto;
  width: 18px;
}

.sel-type p {
  margin-top: 15px;
  font-size: 14px;
  font-family: "Medium" !important;
  opacity: 50%;
  color: #000000;
}

.admin-form1 {
  background-color: white;
  max-width: 48%;
  width: 100%;
  padding: 20px 20px;
  border-radius: 10px;
}


.emmanformwrap1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0px;
}

.emmanformwrap1 label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.emmanformwrap1 label input {
  width: 100%;
}

.emmanformwrap1 label p {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.admfperh2 {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.admin-form {
  display: flex;
  /* margin: 0 25px; */
  width: 100%;
  /* background-color: orange; */
  justify-content: space-between;
}

.form-section form {
  width: 100%;
}

/* .form-section form input[type="email"]{
  color: #000000 !important;
} */
.admin-form1 .ladki {
  width: 75px;
  display: block;
  margin: auto;
}

.admin-form1 .kds {
  text-align: center;
  font-size: 17.5px;
  font-family: "Medium" !important;
  color: #0a2e57;
}

.form-section input,
.form-section select,
.form-section textarea {
  width: 100%;
  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;
  background: #F8F9FB;
  outline: none;
}

.form-section input placeholder {
  color: red;
}

.inputs-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.inputs-buttons .edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border: 2px solid #0b60ff;
  color: #0b60ff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  margin: 0 10px;
  border-radius: 5px;
}

.inputs-buttons .delete {
  border: 2px solid #EF5353;
  color: #EF5353;
}

.inputs-buttons span {
  font-size: 14px;
  font-family: "Semi Bold" !important;
  margin: 0 4px;
}

.inputs-buttons img {
  width: 14px;
  margin: 0 4px;
}

.inputs-buttons .save {
  background-color: #0b60ff !important;
  width: 110px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  height: 30px;
  border-radius: 5px;
  margin: 0px 10px;
}


.admin-form2 {
  margin-top: 30px;
  width: 100%;
  margin-left: 12px;
  height: 700px;
  max-height: 100%;
  overflow-y: scroll;
}

.admin-form ::-webkit-scrollbar {
  display: none !important;
}

.admin-form ::-webkit-scrollbar-track {
  display: none !important;
}

.admin-form ::-webkit-scrollbar-thumb {
  display: none !important;
}

.basic-information {
  background-color: white;
  padding: 15px 15px;
  border-radius: 10px;
  max-width: 49%;
  width: 100%;
}

.emregistbtn {
  background-color: #0B56E4;
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}

.alldocwwrap {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

}

.wrap1 {
  /* background-color: red; */
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}

.empmanagform {
  margin-top: 30px;
}

.makethisflex1 {
  display: flex;
  gap: 10px;
}

.basic-information2 {
  padding: 15px 0px;
  border-radius: 10px;
}

.addedinfowidth {
  max-width: 570px;
  width: 100%;
  padding: 20px 0px;
}

.bank1wap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.basics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.basics h3 {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.singbankWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.singbankWrap label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.singbankWrap input {
  height: 44px;
  width: 90%;

}

.basics img {
  width: 12px;
}

.upper {
  margin-top: 20px;
  opacity: 80%;
}

.form2-class input,
.form2-class select,
.form2-class textarea {

  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #D0D4DC;
  background-color: white;
}



.form2wrap {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.emmanageSec2 {
  display: flex;
  gap: 20px;
  width: 100%;
}

.addedGgap {
  gap: 12px;
  max-width: 530px;
}

.form2-class form input::before {
  color: #000000 !important;
  opacity: 60%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
}




.form2-class label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.inputs-button1 {
  margin-top: 30px;
}

.inputs-button1 .edit {
  margin: 0px 0px;
  margin-right: 10px;
  width: 100% !important;
  max-width: 80px;
}

.inputs-button1 .save {
  margin: 0px 0px;
  margin-left: 10px;
  width: 100% !important;
}

.inputs-button2 {
  justify-content: flex-start;
}

/* ===============request css================== */
.first-request-bar {
  background-color: #26334b;
  max-width: 314px;
  width: 100%;
  padding: 20px 0px;
  margin: 0px 15px;
}

.request-search {
  padding: 0px 20px;
}

.request-search h2 {
  color: #ffffff;
  font-size: 14px;
  font-family: "Semi Bold" !important;
  letter-spacing: 0.5px;
}

.request-search img {
  width: 16px;
  height: 16px;
}

.request-by {
  margin: 10px 0px;
  position: relative;
  z-index: 2;
  padding: 15px;
  width: 100%;
  cursor: pointer;
}

.request-by p {
  color: #ffffff;
  font-size: 13px;
  font-family: "Semi Bold";
}

.request-by .from {
  color: #ffffff;
  font-size: 13px;
  font-family: "Medium" !important;
  margin-top: 4px;
}

.request-by .datr {
  color: #ffffff;
  opacity: 50%;
  font-family: "Medium" !important;
  margin-top: 5px;
}

.request-by .blue {
  color: #9fc1ff;
  font-family: "Medium" !important;
  margin-top: 6px;
}

.request-by:hover {
  position: relative;
  z-index: 2;
}

.request-by:hover::before {
  background-color: #ffffff;
  opacity: 10%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
}

.second-request-bar {
  background-color: #ffffff;
  margin: 45px 80px;
  width: 100%;
  max-width: 100%;
  padding: 20px;
}

.pending {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending .pending1 {
  width: 65%;
  max-width: 100%;
  border-bottom: 2px solid #d9e1f0;
}

.pending button {
  background-color: #ffc107;
  width: 100%;
  height: 40px;
  font-size: 17.5px;
  font-family: "Semi Bold" !important;
  color: #ffffff;
  border-radius: 5px;
}

.request-flow h3 {
  font-family: "Semi Bold" !important;
  color: #000000;
  font-size: 15px;
  opacity: 60%;
  letter-spacing: 0.5px;
}

.request-flow .image-line {
  height: 90px;
  border-left: 2px solid #d9e1f0;
  display: block;
}

.request-flow .image-line1 {
  height: 83px;
  border-left: 2px solid #d9e1f0;
  display: block;
}

/* .view-form2{
  height: 45px;
} */
.request-flows {
  display: flex;
  align-items: flex-start;
}

.requests-flow1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.requests-flow1 img {
  display: block;
}

.requests-flow2 {
  width: 100%;
  margin: 0px 30px;
}

.view-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.view-form::before {
  border: 2px solid #829dcc;
  opacity: 20%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 100%;
  height: 100%;
}

.view-form1 h3 {
  color: #000000 !important;
  font-size: 13px;
  font-family: "Bold" !important;
  opacity: 100% !important;
}

.view-form1 h5 {
  color: #000000;
  font-family: "Medium" !important;
  font-size: 13px;
  opacity: 100%;
}

.view-form1 p {
  color: #000000;
  font-size: 13px;
  font-family: "Medium" !important;
  opacity: 50%;
  margin-top: 4px;
}

.view-form2 h3 {
  color: #085881;
  font-family: "ExtraBold" !important;
  opacity: 100%;
  font-size: 13px;
}

.request-flow .undo {
  border: 2px solid #085881;
  display: block;
  margin: auto;
  max-width: 180px;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  margin-top: 60px;
  color: #085881;
  font-size: 18px;
  font-family: "Semi Bold" !important;
  opacity: 100% !important;
}

.uploadedProfile {
  max-width: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cutImg {
  display: flex;
  justify-content: flex-end;

}

.cutImg img {
  cursor: pointer;

}

.updateUser {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.makethisfelxd {
  display: flex;
  gap: 20px;
}

.sabeupdabtn {
  width: 150px;
  /* margin: 0 auto; */
  height: 40px;
  border-radius: 5px;
  background-color: #0B56E4;
  color: white;
  font-weight: 600;
}

.makethisgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.updateUser::-webkit-scrollbar {
  display: none !important;
}

.updateUser::-webkit-scrollbar-thumb {
  display: none !important;
}

.updateUser input,
.updateUser select {
  background-color: #e8ecf5;
  outline: none;
  height: 55px;
  border-radius: 5px;
  padding: 10px;
  font-family: inter !important;
  width: 100%;
}

.silo {
  border: 1px solid #B8BBC0 !important;
  background-color: #E8E9EB !important;
  color: #6C737F !important;
  border-radius: 5px;
  font-family: inter !important;
  font-weight: 500;
  height: 40px !important;
  font-size: 16px !important;
}

.solaring {
  width: 199px;
  max-width: 100%;
  position: absolute;
  right: 63px;
  top: 155px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.faring a {
  color: #19222E;
  font-size: 14px;
  font-family: inter !important;
  font-weight: 400 !important;
  padding: 10px;
}

.faring a:hover {
  color: #0B56E4 !important;
  background-color: #F5F9FF !important;
  transition: .5s;
}

.filter_lead {
  padding: 10px 10px;
  border-bottom: 1px solid #D0D4DC !important;
}

.filter_lead h2 {
  color: #2B2B2B !important;
  font-family: inter !important;
  font-weight: 600 !important;
  font-size: 14px;
}

.trt_things {
  padding: 12px 10px !important;
  border-bottom: 1px solid #D0D4DC !important;
}

.fg {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.fg span {
  font-weight: 400;
  font-size: 14px;
  color: #545962 !important;
  font-family: inter !important;
}

.in_the {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 15px;
}

.in_the select {
  border: 1.5px solid #D0D4DC !important;
  outline: none;
  height: 38px;
  margin-left: 10px;
  color: #545962 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.testo {
  border: 1.5px solid #D0D4DC !important;
  outline: none;
  height: 38px;
  margin-left: 10px;
  color: #545962 !important;
  font-family: inter !important;
  font-size: 14px !important;
}

.stoing {
  max-width: 49px;
  height: 38px;
  border: 1.5px solid #D0D4DC !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.stoing input {
  max-width: 49px;
  height: 38px;
  padding: 3px;
}

.aloy1 {
  width: 131px;
}

.aloy2 {
  width: 93px;
}

.touched_things {
  /* padding: 0px 10px !important; */
  display: flex !important;
  align-items: center !important;
}

.touched_things span {
  color: #2B2B2B;
  font-family: inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 5px;
}

.dart {
  position: absolute;
  z-index: 100;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  max-width: 337px;
  width: 100%;
  /* height: 800px; */
  overflow: auto;
  top: 233px;
  /* padding: 0px 20px; */
}

.some_things {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #D0D4DC !important;
}

.apply_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.apply button {
  width: 117px;
  height: 40px;
  background-color: #0B56E4;
  color: #ffffff !important;
  font-family: inter !important;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  margin: 0px 5px;
}

.cancel button {
  width: 117px;
  height: 40px;
  font-family: inter !important;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #0B56E4 !important;
  background-color: #D1E8FD;
  border-radius: 8px;
  margin: 0px 5px;
}

/* .setting{
  padding: 10px;
} */
.some_things input {
  width: 14px;
  height: 14px;
  border: 2px solid #BAC1CC;
}

.some_things span {
  color: #2B2B2B;
  font-weight: 500;
  font-family: inter !important;
  font-size: 14px;
  margin-left: 10px;
}

.CircularProgressbar {
  width: 100px !important;
  height: 40px !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000000 !important;
  font-size: 50px !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: none !important;
  stroke-linecap: initial !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: none !important;
}

.Jobdescription {
  height: 65px !important;
}

.drag-area {
  max-width: 252px;
  /* width: 252px; */
  width: 100%;
  height: 62px;
  border-radius: 12px;
  border: 1px solid #B7B7B7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

}

.thiddrapgsingl {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

}

.thiddrapgsingl h4 {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.lstOrgText {
  font-family: Inter;
  color: #101820;
  padding: 20px 20px 0px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.drag-area p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #1B2533;
  text-decoration: underline;

}

.drag-area img {
  max-width: 29px;
  max-height: 29px;

}


.drag-area header {
  /* font-size: 30px; */
  font-size: 14px;
  font-family: "Medium" !important;
  font-weight: 500;
  color: #0B60FF;
  opacity: 60%;
}

.drag-area span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  font-family: "Medium" !important;
  opacity: 12%;
  margin-top: 5px;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #fff;
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.filesjila {
  position: absolute !important;
  opacity: 0;
  background-color: red;
}

.drag-area2 {
  width: 30% !important;
}

.status-box {
  background-color: #FFFFFF;
  /* max-width: 290px; */
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.status-sox {
  padding: 0px 15px;
}

.status-months {
  padding: 0 15px;
}

.status-sox h3 {
  color: #000000;
  font-size: 18px;
  font-family: "Bold" !important;
}

.status-sox img {
  width: 19px;
  height: 19px;
}

.status-months1 h5 {
  color: #000000;
  opacity: 50%;
  font-size: 12px;
  font-family: "Semi Bold" !important;
}

.status-months2 {
  position: relative;
}

.status-months h2 {
  color: #000000;
  font-family: "Semi Bold" !important;
  font-size: 33px;
  line-height: 40px;
}
.pono0,.pono2,.pono10{
  background-color: white !important;
  height: auto !important;
  max-width: 500px !important;
  padding: 20px !important;
  width: 100% !important;
}
.popup1 label{
  margin-top: 20px !important;
}
.popNav{
  padding: 10px 0px;
}
.expensepop{
  width: 100% !important;
  padding: 20px !important;
}
.allPopupWrap{
  z-index: 1000 !important;
}


.input{
  outline: none !important;
}
.status-months2 select {
  position: relative;
  z-index: 2;
  outline: none;
  padding: 4px 10px;
  background-color: #F0F0F0;
  opacity: 90%;
  font-size: 13px;
  color: #000000 !important;
  opacity: 60%;
}
.anMaRi input{
  outline: none !important;
}
.hr-months {
  border: 1px solid #000000;
  opacity: 10%;
}
.onHoldbg{
  color: white !important;
}
.onboarding {
  background-color: #FFFFFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* max-width: 290px; */
  width: 100%;
  border-radius: 5px;
  margin-top: 12px;
  padding: 12px 0px;
}

.first-on {
  display: flex;
  align-items: center;
}

.gonBoarding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.first-o img {
  width: 27px;
}

.second-o p {
  color: #000000;
  font-size: 13px;
  font-family: "Semi Bold" !important;
  margin-left: 10px;
}

.second-on h4 {
  color: #000000;
  font-size: 13px;
  font-family: "Semi Bold" !important;
}

.onboard-hr {
  border: 1px solid #000000;
  border-style: dotted;
  color: #000000;
  opacity: 15%;
  margin-top: 12px;
}

.auth {
  background-color: #f5f5f5;
  max-height: 100vh;
}

.gon {
  margin-top: 12px;
}

.request-card {
  /* width: 600px; */
  max-width: 100%;
  width: 600px;
}

.hrStatus2 {
  width: 665px;
  max-width: 100%;
}

.request-card {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px 0px;
}

.see-req {
  padding: 0px 15px;
}

.see-req h2 {
  color: #000000;
  font-size: 18px;
  font-family: "Bold" !important;
}

.see-req p {
  color: #0B60FF;
  font-size: 13px;
  font-family: "Semi Bold" !important;
}

.hrDash-status2 {
  max-width: 370px;
  width: 100%;
}

.shubham-cards1 .goal {
  width: 13px;
  height: 13px;
}

.shubham-cards1 .shubham {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}

.mr {
  margin-left: 8px;
}

.mr h4 {
  color: #0A2E57;
  font-size: 12px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.mr p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
}

.shubham-cards4 {
  background-color: #F1F6FF;
  margin: 0px 15px;
  padding: 15px 12px;
  margin-top: 10px;
}

.shubham-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
}

.see-hr {
  border: 1px solid #000000;
  opacity: 10%;
  margin-top: 10px;
}

.tistar p {
  font-size: 11px;
  color: #0A2E57;
  font-family: "Semi Bold" !important;
}

.date1 h4 {
  color: #0A2E57;
  font-size: 11px;
  font-family: "Semi Bold" !important;
}

.date1 {
  margin-left: 8px;
}

.date1 p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
}

.interview {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 12px 0px;
}

.interview-img {
  padding: 0px 15px;
}

.interview-img img {
  width: 18px;
  height: 18px;
}

.test {
  border: 1px solid #000000;
  opacity: 10%;
  margin-top: 13px;
}

.calendar {
  margin: 0px 15px;
  margin-top: 10px;
}

.calendar .react-calendar {
  background-color: #F1F6FF;
  position: relative;
}

.calendar .react-calendar button {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000000 !important;
}

.calendar .react-calendar button abbr {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000000 !important;
}

.calendar .react-calendar__navigation__label__labelText {
  font-size: 13px !important;
  font-family: "Bold" !important;
  color: #000000 !important;
}

/* class=" react-calendar__navigation__next-button" */
.calendar .react-calendar__navigation__arrow {
  font-size: 25px !important;
  color: #F1F6FF !important;
}

.calendar .react-calendar__navigation {
  width: 46% !important;
  max-width: 100%;
}

.calendar .react-calendar__tile {
  line-height: 0px !important;
}

.interview-img h3 {
  color: #000000 !important;
  font-size: 18px !important;
  font-family: "Bold" !important;
  letter-spacing: .5px;
}

.col-akash {
  position: relative;
  background-color: #F1F6FF;
  padding: 10px;
  margin: 0px 15px;
  margin-top: 16px;
  border-radius: 5px;
}

.col-img img {
  width: 58px;
  max-width: 100%;
}

.col-para {
  margin-left: 16px;
}

.col-para h3 {
  color: #000000;
  font-family: "Bold" !important;
  font-size: 13.5px;
}

.col-para p {
  color: #000000;
  opacity: 70%;
  font-size: 9px;
  font-family: "Semi Bold" !important;
}

.col-para h5 {
  color: #000000;
  font-size: 9px;
  font-family: "Bold" !important;
}

.hr-bash {
  background-color: #FFFFFF;
  /* height: 80vh; */
  margin: 0px 5px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding: 35px 0px;
  border-radius: 5px;
}

.hr-box1 img {
  width: 52px;
  height: 52px;
}

.hr-box2 {
  margin-left: 20px !important;
}

.overhidding {
  margin: 0px 25px;
  margin-top: 30px;
  border-radius: 5px;
}

.overhidding1 {
  margin-top: 0px !important;
}

.reg-date img {
  width: 15px;
}

.status {
  display: flex;
  border: 1px solid #ececec;
  justify-content: space-between;
  max-width: 90px;
  width: 100%;
  border-radius: 3px;
  padding: 4px 10px;
}

.theads {
  position: relative;
  z-index: 2;
  height: 55px;
}

.theads::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-bottom: 1px solid #000000;
  opacity: 10%;
}

.sitar {
  position: relative;
  z-index: 2;
}

.sitar::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-right: 1px solid #000000;
  opacity: 10%;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.sitar h4 {
  color: #0A2E57;
  font-size: 16.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.sitar1 h4 {
  color: #0A2E57;
  font-size: 16.5px;
  font-family: "Semi Bold" !important;
  letter-spacing: .5px;
}

.timer p {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.sno {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.reg-date h4 {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.reg-date p {
  color: #0A2E57;
  font-size: 10px;
  font-family: gilroy !important;
  line-height: 15px;
  opacity: .9;
}

.status p {
  font-size: 13px;
  font-family: "Medium" !important;
  color: #0A2E57;
}

.tr-head {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #e3e1e1;
}

.theads1 {
  border-top: 1px solid #e3e1e1 !important;
}

.action {
  max-width: 90px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 2px;
  position: inherit;
  /* position: fixed; */
  /* position: absolute;
  top: -19px; */
  z-index: 1000;
}

.edit {
  padding: 7px 0px;
  border-bottom: 1px solid #D9E0E6;
}

.delete {
  padding: 7px 0px;
}

.login-buttons button {
  max-width: 150px;
  width: 100%;
  height: 46px;
}

.calendar1 .react-calendar button {
  border-radius: 50% !important;
}

.calendar1 .react-calendar button:hover {
  color: #ffffff !important;
}

/* .sesij{
  position: absolute;
  background-color: white;
  z-index: 100;
} */

.lesar {
  position: absolute;
  background-color: white;
  width: 170px;
  max-width: 100%;
  z-index: 2000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.calendar1 .react-calendar button abbr:hover {
  color: #FFFFFF !important;
}

.sfgh input {
  width: 252px !important;
}

.calendar1 .react-calendar__month-view__weekdays__weekday abbr {
  color: #000000;
  opacity: 30%;
}

.calendar1 abbr {
  font-size: 10px !important;
  font-weight: 500 !important;
  font-family: "Semi Bold" !important;
}

.calendar1 .react-calendar__tile {
  padding: 22px 0px !important;
}

.fourth-logo1 button {
  color: #0B60FF;
  border: 2px solid #0B60FF !important;
}

.distinguish2 {
  width: 36%;
  max-width: 100%;
  margin: 0 20px;
}

.total-timeCal {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.total-timeCal .total {
  color: #000000;
  font-size: 19px;
  font-family: inter !important;
  padding: 0px 20px;
  font-weight: bold;
}

.total-timeCal hr {
  border-bottom: 1.5px solid #000000;
  opacity: 10%;
  margin-top: 15px;
}

.clock {
  padding: 0px 20px;
  /* margin-top: 20px; */
}

.clock h3 {
  color: #707070 !important;
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 15px;
}

.fan {
  color: #0B56E4 !important;
}

.hh {
  background-color: #F5F9FF;
}

.clock1 {
  margin-top: 10px;
}

.clock1 h2 {
  color: #000000;
  font-family: inter !important;
  font-weight: 600 !important;
  font-size: 22px;
}

.clock-system {
  margin-top: 20px;
}

.clock2 {
  margin-top: 20px;
}

.distinguish-varding {
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px 0px;
  border-radius: 10px;
}

.distinguish-box {
  display: flex;
  background-color: #F1F6FF;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 15px 0px;
}

.distinguish-box img {
  width: 78px;
}

.akash {
  margin-left: 30px;
}

.akash h3 {
  color: #000000;
  font-size: 16px;
  font-family: "Bold" !important;
}

.akash p {
  color: #000000;
  font-size: 11px;
  opacity: 50%;
  font-family: "Semi Bold" !important;
  margin-top: 2px;
}

.akash h4 {
  font-size: 13px;
  font-family: "Bold" !important;
  margin-top: 3px;
}

/* .distinguish-varding */
.distinguish-varding .sasks {
  padding: 0px 20px;
  font-size: 18px;
  font-family: "Bold" !important;
}

.distinguish-varding hr {
  border-bottom: 1.5px solid #000000 !important;
  /* overflow: hidden; */
  opacity: 10%;
  margin-top: 10px;
}

.distinguish-fard {
  padding: 0px 20px;
  margin-top: 20px;
}

.distinguish1 .react-calendar__navigation__label__labelText {
  font-size: 18px !important;
  font-family: "Bold" !important;
}

.distinguish1 .react-calendar__navigation button {
  font-size: 30px;
}

.distinguish1 .react-calendar__month-view__weekdays__weekday abbr {
  color: #000000;
  opacity: 30%;
  font-family: "Semi Bold" !important;
  font-size: 14.5px;
}

.distinguish1 .react-calendar__tile {
  line-height: 12px;
}

.distinguish1 .react-calendar__tile abbr {
  color: #000000;
  font-size: 14.5px;
  font-family: "Semi Bold" !important;
}

.distinguish1 .calend1 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.clock-nav .sat {
  margin: 0 7px;
}

.clock-nav .hrs {
  margin: 0 7px;
}

.clock-nav .puts {
  margin: 0px 7px;
}

.clock-nav .min {
  margin: 0px 7px;
}

.clock-nav .sec {
  margin: 0px 7px;
}

.clock-nav h3 {
  color: #000F2B;
  font-size: 22px;
  font-family: "Bold" !important;
}

.clock-nav p {
  font-size: 11px;
  font-family: "Semi Bold" !important;
  color: #000F2B;
  opacity: 50%;
  text-align: left;
}

.bottomji {
  margin: 0px 7px;
  margin-top: 2px;
  position: relative;
}

/* .sas{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
} */
.brake {
  position: absolute;
  background-color: #FFFFFF;
  width: 130px;
  top: 30px;
  right: -5px;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.bottomji .char {
  color: #000F2B;
  font-size: 12px;
  margin-top: 6px;
}

.brakes {
  width: 20px;
}

.logouts {
  width: 25px;
}

.brake p {
  /* margin-right: 10px; */
  white-space: nowrap;
  margin: 0 5px;
  font-size: 15px;
  font-family: "Medium" !important;
}

.bring {
  color: #D9AE00 !important;
  opacity: 100% !important;
}

.out {
  color: #FF5449 !important;
  opacity: 100% !important;
}

.bt {
  padding: 10px 0px;
}

.logout {
  padding: 8px 0px;
}

.brake {
  display: none;
}

.char1 {
  display: none;
  margin-top: 6px;
}

.fat-container img {
  width: 18px;
}

.fat-container p {
  margin-left: 10px;
}

.fat-container textarea {
  height: 500px;
  max-height: 100%;
  position: relative;
  resize: none;
  z-index: 2;
  background-color: #e6e3e3;
  outline: none;
  padding: 20px;
  color: #707070;
  font-family: "Medium" !important;
  font-size: 14px;
  opacity: 70%;
}

.form6 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.form6 button {
  background-color: #DF3333;
  font-family: "Semi Bold" !important;
  color: #FFFFFF;
  border-radius: 5px;
  max-width: 150px;
  width: 100%;
  display: block;
  margin: auto;
  height: 40px;
  margin-top: 40px;
}

.fat-pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0008;
  z-index: 1000;
  overflow: auto;
}

.fat-container {
  background-color: #FFFFFF !important;
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0px;
  padding: 20px;
  margin-top: 80px;
}

.cheers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cheers1 {
  margin-right: 6px;
}

.cheer2 {
  margin-left: 6px;
}

.falend .react-calendar {
  background-color: #ffffff;
  margin-top: 20px;
}

.falend .react-calendar__viewContainer {
  position: relative;
  z-index: 2;
}

.falend .react-calendar__viewContainer::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-top: 1px solid #1479FF !important;
  opacity: 10%;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.falend .react-calendar__month-view__weekdays__weekday {
  margin-top: 10px;
}

.falend .react-calendar__navigation {
  width: 100% !important;
}

.falend .react-calendar__navigation__arrow {
  color: #05273E;
  opacity: 20% !important;
  font-size: 35px;
  font-weight: bold !important;
}

.falend .react-calendar__navigation__label__labelText {
  font-size: 13px !important;
  font-family: inter !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fff !important;
}

.falend hr {
  border-bottom: 2px solid #000000;
  opacity: 10%;
  margin-top: 20px;
}

.cals-dent {
  padding: 0px 20px;
}

.falend .react-calendar__tile {
  line-height: 8px !important;
  font-size: 11px;
  /* border-radius: 50% !important; */
}

.falend .react-calendar__tile abbr {
  color: #000000 !important;
  font-family: "Semi Bold" !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6 !important;
}

.cals-dent img {
  position: absolute;
  right: 30px;
  top: 20px;
}

.chhosemonth {
  margin-top: 30px;
  max-width: 180px;
}

.clock1 p {
  color: #707070;
  font-family: "Medium" !important;
  font-size: 15px;
  margin-left: 10px;
}

.show-all p {
  text-align: center;
  opacity: 50% !important;
  font-size: 14px;
  font-family: "Semi Bold" !important;
}

.santu {
  background-color: #1A6AFF !important;
  width: 32px;
  padding: 5px;
  border-radius: 6px;
  margin-left: 20px;
}

.santu img {
  width: 20px;
  display: block;
  margin: auto;
  max-width: 100%;
}

.theads1 h4 {
  color: #0A2E57 !important;
  font-size: 16px !important;
  letter-spacing: .5px;
  font-family: "Semi Bold" !important;
}

.tr-head1 p {
  color: #0A2E57 !important;
  font-size: 14px !important;
  font-family: "Medium" !important;
}

.dates-pick p {
  font-size: 10px !important;
  font-family: gilroy !important;
  font-weight: 400 !important;
  opacity: 80% !important;
}

.dates-pick h3 {
  color: #0A2E57 !important;
  font-size: 14px !important;
  font-family: "Medium" !important;
}

.tr-head1 .inr {
  color: #32CA91 !important;
}

.alert-min {
  margin-top: 80px !important;
  z-index: 200000;
}

.main_message {
  font-size: 16px !important;
}

#alert-border-3 {
  position: fixed;
  right: 0px;
  max-width: 600px;
}

#alert-border-2 {
  position: fixed;
  right: 0px;
  max-width: 600px;
}


.attend-ctiveWrapempp .hrmActRight55 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.attend-ctiveWrapempp .markAttWrap55 {
  background-color: white;
  width: 100%;
  height: 441px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0px;
}

.attend-ctiveWrapempp .markAttWrap55 .markAtt55 {
  display: flex;
  padding: 0px 20px;
  align-items: center;
}

.attend-ctiveWrapempp .myOficeWrap55 {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.attend-ctiveWrapempp .markAttWrap55 .markAtt55 p {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1B2533;
  margin-left: 10px;
}

.attend-ctiveWrapempp .myOficeWrap55 .myOfText55 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .oficTime55 {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.attend-ctiveWrapempp .clockINOUTBtn55 {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}

.attend-ctiveWrapempp .ofSin55 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.attend-ctiveWrapempp .oficTime55 .singlTime55 {
  box-shadow: 2px 5px 15px 0px #0000001A;
  background-color: #F8F9FD;
  width: 90px;
  height: 90px;
  top: 373px;
  left: 996px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
  justify-content: center;
}

.attend-ctiveWrapempp .ofSin55 .day55 {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .oficTime55 .singlTime55 p {
  font-family: inter !important;
  font-size: 34px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #293240;
}

.attend-ctiveWrapempp .clockIN55 {
  width: 134px;
  height: 48px;
  top: 523px;
  left: 1058px;
  padding: 12px, 30px, 12px, 30px;
  border-radius: 6px;
  gap: 10px;
  /* background-color: #6FD943; */
  background-color: #189877;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attend-ctiveWrapempp .clockOUT55 {
  width: 134px;
  height: 48px;
  top: 523px;
  left: 1058px;
  padding: 12px, 30px, 12px, 30px;
  border-radius: 6px;
  gap: 10px;
  /* background-color: #6FD943; */
  /* background-color: #189877; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9002A;
}

.attend-ctiveWrapempp .clockIN55 span {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.attend-ctiveWrapempp .clockOUT55 span {
  font-family: inter !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.attend-ctiveWrapempp {
  display: flex;
  gap: 42px;
  width: 100%;
  justify-content: space-between;
}


.attenRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;

}


.celWrap {
  width: 672px;
  border: 1px solid #B7DFD5;
  background: #E8F5F1;
  border-radius: 10px;
  max-height: 194px;
}

.celWrap nav {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 12px 12px 0px 0px;
  border: 0px 0px 1px 0px;


}

.celWrap nav span {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.partPeople {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.singcel {
  /* width:221px; */
  height: 94px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 20px 16px 20px 16px;
  gap: 10px;
  border-radius: 12px;

}

.singcel h3 {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;


}

.capWrap {
  position: relative;
}

.capWrap .phrofileimg {
  max-width: 60px !important;
  border-radius: 50%;
}

.capWrap .cap {
  position: absolute;
  top: -20px;
  left: -20px;


}

.singcel p {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: center;

}

.attenLeft {
  display: flex;
  flex-direction: column;
  gap: 32px;
}


.user_class {
  position: fixed;
  /* top: 9%; */
}

.leavewrapping {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(64, 64, 64, 0.3);
  justify-content: center;
  z-index: 1000;
}

.crelevecont {
  width: 455px;
  height: fit-content;
  padding: 20px;
  border-radius: 18px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  border: 1px solid #E3E3E3;
  z-index: 20;
}

.crelevecont2 {
  width: 555px;
  height: fit-content;
  padding: 20px;
  border-radius: 18px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  border: 1px solid #E3E3E3;
  z-index: 20;
}

.user_class_input3 {
  width: 50%;
}

.crelavetopsec {

  display: flex;
  align-items: center;
  justify-content: space-between;

}

.crelavetopsec h3 {

  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.levaecretaeform {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

.user_class_input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.levaecreflex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.levelreasons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.levelreasons label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.showann {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.showann label {
  font-family: Inter;
  color: #1B2533;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.showann span {
  font-family: Inter;
  color: #0B56E4 !important;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0025em;
  text-align: left;
  font-size: 14px;

}

.user_classleave {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user_classleave label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.levelreasons textarea {
  border: 1px solid #D0D4DC;
  background: #FFFFFF;
  border-radius: 10px;
  width: 100%;
  resize: none;
  height: 100px;
  padding: 10px;
}

.leavebuttons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.leaverqbtns {
  width: 120px;
  height: 40px;
  border-radius: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
  background: #0B56E4;

}

.levacanclebtns {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #B8BBC0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #666D76;


}

.user_class_input label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.user_class_input input {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.user_class_input textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.user_class_input3 label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.user_class_input3 input {
  height: 44px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;


}

.sk i {
  margin: 0px 6px;
  cursor: pointer;
}

.overall {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lead_dash_card {
  display: flex;
  align-items: center;
  margin-top: 30px;
  /* justify-content: center; */
}

.lead_dash_box {
  width: 100%;
  box-shadow: 0px 8px 32px -2px #1B25330F;
  background-color: #FFFFFF;
  max-width: 100%;
  /* height: 129px; */
  border-radius: 12px;

  /* margin: 0px 10px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 20px;
}

.sing111 {
  margin-left: 15px;
}

.sing1111 {
  margin-left: 15px;
}

.sing11111 {
  margin-left: 15px;
}

.lead_contens1 h3 {
  color: #1B2533;
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  /* white-space: nowrap; */
}

.lead_contens1 h1 {
  color: #1B2533;
  font-weight: 500;
  font-size: 24px;
  font-family: "Inter", sans-serif !important;
  text-align: right;
  margin-top: 15px;
}

.lonj tr,
.lonj td,
.lonj th {
  background-color: white;
  border-left: 0px;
  border-right: 0px;
  white-space: pre-wrap !important;
  font-family: "Inter", sans-serif !important;
  line-height: 16px !important;
  font-size: 11px !important;
}

.my_open1 {
  padding: 0px 20px !important;
}

.userFilterr {
  width: 196px;
  height: 34px;
  padding: 8px 16px;
  border-radius: 10px;
  background: white;
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  border: 1px solid #D0D4DC;
  height: 48px;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
}

.sunis {
  position: relative;
  display: flex;
  align-items: center;
}
.sunis h3{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  min-width: fit-content
}

.sunis img {
  position: absolute;
  left: 264px;
}
.react-tel-input .form-control{
  width: 100% !important;
}
.react-tel-input{
  margin: 5px 0px !important;
}
.sunis select {
  margin-left: 20px;
}

.permiCont select {
  font-family: inter !important;
  width: 100% !important;
}

.applyBtn {
  display: block;
  margin: 50px auto;
  height: 40px;
  width: 200px;
  border-radius: 5px;
}

.applyBtn span {
  font-family: inter !important;
  font-weight: 400;
}

.salay th,
.salay td {
  color: #545962 !important;
}

.taning {
  width: 180px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 32px 0px;
}

.floyu {
  float: right !important;
  cursor: pointer !important;
}

.felas th,
.felas td {
  /* width: 11.1% !important; */
}

.sysok a {
  display: flex;
  align-items: center;
  padding: 15px;
  /* margin: 0px 5px; */
}

.sysok span {
  margin-left: 10px;
  color: #2B2B2B;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

.prev_next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.my_things {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.on1 {
  width: 26px;
  height: 26px;
  border: 1px solid #D8D8D8 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px 5px;
}

.on1:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.next button {
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  width: 55px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 5px;
}

.swer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.editleaFrom {
  max-width: 600px !important;
  margin: 0 auto;
  width: 100%;
}

.leave_ct {
  font-family: inter !important;
}

.next-button:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.fkl label {
  font-family: inter !important;
}

.fkl input {
  font-family: inter !important;
}

.safar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.safar button {
  font-family: inter !important;
  width: 200px;
  max-width: 100%;
}

.highlightOption {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-family: inter !important;
}

.option {
  font-family: inter !important;
  border-bottom: 1px solid #E8E9EB !important;
  padding: 4px 10px !important;
}

.option:hover {
  background: white !important;
  color: #000000 !important;
}

.next button:hover {
  border: 1px solid #0B56E4 !important;
  transition: .5s;
  cursor: pointer !important;
  background-color: #D1E8FD !important;
}

.table11 {
  width: 100%;
  background-color: white;
  border: 1px solid #E8E9EB;
  box-shadow: 0px 8px 32px -2px #1B25330F;
  padding: 15px 0px;
  margin-top: 20px;
  min-height: 250px;
  /* max-height: 250px; */
  overflow-y: scroll;
  border-radius: 10px;
}

.table11::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.table11 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.table22::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.table22 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.saka th {}

.table22 {
  width: 100%;
  background-color: white;
  margin-left: 15px;
  overflow-y: scroll;
  min-height: 250px;
  /* max-height: 250px; */
  box-shadow: 0px 8px 32px -2px #1B25330F;
  border-radius: 15px;
  padding: 15px 0px;
  margin-top: 20px;
}

.table333 {
  margin-left: 0px !important;
}

.my_open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}

.checkderop {
  position: absolute;
  transform: translateX(-150px) translateY(-10px);
}

.my_open h3 {
  color: #1B2533;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
}

.thol th {
  /* white-space: nowrap !important; */
  color: #2B2B2B;
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
}

.myleadtit2 {
  font-family: Inter;
  color: #40444B;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.leadti {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.relt {
  color: #F58E07;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
}

.editLeadreq {
  font-family: Inter;
  color: #1B2533;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;

}

.duedatest {
  font-family: Inter;
  color: #545962;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;

}

.droos {
  position: absolute;
  width: 161px;
  height: 134px;
  padding: 8px 0px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  transform: translateX(-150px) translateY(-20px);
}

.droos p {
  cursor: pointer;
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.contactk {
  display: flex;
  align-items: center;
}

.contactk img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.contactk p {
  color: #545962;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  margin-left: 2px;
}

.lead_inp {
  display: flex;
  align-items: center;
  gap: 20px;

}

.lead_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.lead_inp label {
  display: block;
}

.lead_inp1 {
  max-width: 50%;
  width: 100%;
}

.makeitflexcol {
  display: flex;
  gap: 20px;
}

.exceptionwidht {
  width: 100%;
}

.exceptionwidht input {
  /* max-width: calc(100% - 81px ); */
  width: 100%;
}

.lead_inp1 input,
.lead_inp1 select {
  width: 100%;
  height: 48px !important;
  outline: none !important;
  border: 1px solid #D0D4DC;
  color: #666D76 !important;
  font-weight: 400 !important;
  font-family: inter !important;
  padding: 0px 10px;
  border-radius: 10px;
  margin: 5px 0px !important;
}

.tikra {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* height: 60vh; */
}

.selct_div {
  max-width: 736px !important;
  width: 100% !important;
  height: 176px !important;
  border: 1px dashed #8FB1F3;
  display: block;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FB;
}

.selis {
  max-width: 478px;
  width: 100%;
  height: 296px;
  border: 1px dashed #8FB1F3;
  display: block;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FB;
  margin-top: 15px;
}

.browse {
  width: 175px;
  height: 40px;
  /* background-color: #0B56E4; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.browse h3 {
  font-size: 16px;
  color: black;
  text-decoration: underline;
  font-family: inter !important;
  font-weight: 500 !important;
}

.opd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.opd input {
  position: absolute;
  opacity: 0;
}

.upload_an p {
  color: #1B2533;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.sini {
  text-align: center !important;
  color: #2B2B2B;
  font-weight: 500;
  font-size: 24px;
  font-family: inter !important;
}

.semik {
  font-weight: 600;
  font-size: 25px;
  color: #000000 !important;
  font-family: inter !important;
}

.lead_information h2 {
  font-size: 18px !important;
  font-weight: 600;
  color: #000000 !important;
  font-family: inter !important;
}

.lead_information label {
  color: #0F141C !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: inter !important;
}

.lead_inp11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 91px !important;
  width: 100%;
}

.gfg td {
  font-family: inter !important;
}

.gfg label {
  font-family: inter !important;
}

.thml {
  font-family: inter !important;
}

.ACTIVITYsss {
  font-family: inter !important;
}

.sinoid {
  grid-template-columns: repeat(1, 1fr) !important;
}

.inpo1 {
  /* width: 110px; */
}

.inpo2 {
  /* width: 30px; */
}

.font-wrapper {
  font-family: "Inter", sans-serif !important;
}

.tranking th,
.tranking td {
  font-family: inter !important;
}

.selr {
  width: 91px !important;
}

.lead_inp111 {
  display: flex;
  align-items: center;
}

.seng {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid #969BA1 !important;
  margin-right: 15px !important;
}

.jpo {
  margin: 0px 5px;
}

.importB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importB h2 {
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  font-family: inter !important;
}

.canlo {
  width: 86px !important;
  height: 40px !important;
}

.import_vhasa {
  background-color: #ffffff;
  margin-top: 30px;
  border-radius: 10px;
  padding: 40px 0px;
}

.form_filel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_filel h3 {
  color: #2B2B2B;
  font-weight: 500;
  font-size: 24px;
  font-family: inter !important;
  margin-left: 10px;
}

.download_gfg {
  margin-top: 25px;
}

.download_gfg h2 {
  font-size: 16px;
  font-weight: 500;
  color: #49515C;
  font-family: inter !important;
  letter-spacing: 1px;
  /* letter-spacing: 2px; */
}

.download_gfg span {
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
  color: #0B56E4;
}

.srop {
  color: #666D76;
  font-size: 16px;
  font-weight: 400;
  font-family: inter !important;
  text-align: center;
}

.leadLe .sio {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.saya {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #0B56E4 !important;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  width: 147px;
  border-radius: 8px;
  height: 40px;
}

.saya p {
  font-size: 16px;
  font-weight: 500;
  color: #0B56E4;
  font-family: inter !important;
}

.user_login {
  background: #0b60ff;
  color: #ffffff;
  border: none;
}

.admin_login {
  border: 1px solid #B3CBF7;
  background: none;
  color: #49515C;
}

.yui {
  background: #0b60ff;
  color: #ffffff;
  border: none;
}

.lion {
  justify-content: center !important;
}

.toyotoyo {
  text-align: center !important;
  color: #1B2533;
  font-size: 14px;
  font-family: inter !important;
}

.react-confirm-alert-body {
  width: 441px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-confirm-alert-body>h1 {
  text-align: center !important;
  font-size: 16px;
  font-family: inter !important;
  font-weight: 500;
  color: #1B2533 !important;
}

.react-confirm-alert-button-group {
  margin-left: 26px !important;
  margin-top: 25px !important;
}

.react-confirm-alert-button-group>button {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: inter !important;
}

.react-confirm-alert-body {
  padding: 40px 30px !important;
  border-radius: 15px !important;
}

.react-confirm-alert-overlay {
  background-color: rgb(87 87 87 / 50%) !important;
}

.skm {
  width: 100% !important;
}

.tasklo {
  color: #0B56E4 !important;
  background-color: white;
}

.statussame {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px !important;
  height: 28px !important;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;

}

.followUp {
  width: 87px !important;
  border: 1px solid #46AD92 !important;
  background: #E8F5F1 !important;
  color: #189877 !important;

}

.Hot {
  width: 41px !important;
  background: #FCEBE6;
  border: 1px solid #E45D3A;
  color: #F58E07;
}

.Cold {
  width: 47px !important;
  background: #D2EBFB;
  border: 1px solid #1CA4FC;
  color: #1CA4FC;
}

.Warm {
  width: 53px !important;
  background: #FEF4E6;
  border: 1px solid #F58E07;
  color: #F58E07;
}

.LEADSsTunav {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.canccfdl {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #B8BBC0;
  background-color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #666D76;
}

.leadUPdateStsus {
  width: 140px !important;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #0B56E4;
  font-family: Inter;
  color:black;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.leadUPdateStsus2 {
  width: 150px;
}

.addNewCont {
  position: relative;
}

.addneEW {
  width: 131px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addneEW p {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.005em;
  text-align: left;

}

.litu {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.noteStaus {
  border: 1px solid #46AD92 !important;
  width: 77px;
  height: 28px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E8F5F1;
}

.noteStaus p {
  color: #189877;
  font-size: 12px;
  font-family: inter !important;
  font-weight: 400 !important;
}

.notedate {
  color: #666D76;
  font-size: 14px;
  font-weight: 500;
  font-family: inter !important;
}

.opeAnew {
  width: 199px;
  height: 104px;
  padding: 8px;
  border-radius: 8px;
  background: #FFFFFF;
  position: absolute;
  border: 1px solid #E3E3E3;
  box-shadow: 0px 4px 12px 0px #1A1A1A33;
  display: flex;
  flex-direction: column;
  right: 1px;
  gap: 20px;
  transform: translateY(-10px);
}

.opeAnew p {
  font-family: Inter;
  color: #495D71;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  cursor: pointer;

}

.noRecord {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.ladingstausw {
  font-family: Inter !important;
  border: 1px solid #46AD92 !important;
  width: 77px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8F5F1;

  color: #189877 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  height: 28px;
  border-radius: 27px;

}

.searchdeclose {
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchclosde {
  width: 200px;
  height: 38px;
  border-radius: 10px;
  padding: 0px 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
}

.allNotes {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.singlNoteDe {
  display: flex;
  align-items: center;
  gap: 24px;
}

.line_danda {
  height: 40px;
  border-left: 2px solid #189877 !important;
}

.noteTExt {
  max-width: 543px;
  width: 543px;
  color: #1B2533;
  font-weight: 500;
  font-family: inter !important;
  font-size: 16px;
  line-height: 22px;
}

#fentar {
  width: 165px;
  -webkit-appearance: none;
  appearance: none;
}

.doqn {
  position: absolute;
  right: 34px;
}

.doqn1 {
  right: 56px !important;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.test_filter {
  display: flex;
  align-items: center;
  justify-content: end;
}

.noteLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.testok {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addtranslate{
  
  transform: translateX(50px);
}


.morevertimg{
  margin: 0 auto;
}
.makedivcent{
  text-align: center;
}

.noteLabel p {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.noteLabel textarea {
  resize: none;
  width: 100%;
  height: 76px;
  padding: 8px 16px 8px 16px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
  outline: none;
  border-radius: 10px;
  max-width: 709px;

}

.notePresent {
  display: flex;
  /* align-items: center; */
  gap: 40px;

}

.notePresent p {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;


}

.reatlnotecont {
  max-width: 543px;
}

.realNote {
  font-family: Inter;
  color: #101820;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;

}

.sakacont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eidtdel {
  display: flex;
  align-items: center;
  gap: 18px;
}

.eidtdel img {
  cursor: pointer;
}

.noteSaveBtn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  max-width: 709px;


}

.noteSaveBtn .noteSaveBtn2 {
  width: 118px;
  height: 40px;
  padding: 10px 16px 10px 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B56E4;

}

.noteSaveBtn .noteSaveBtn2 span {
  font-family: Inter;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;


}

.createQquot {
  width: 165px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: center;

}

.createQquot span {
  font-family: inter !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0B56E4 !important;
}

.invoiceNav {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.invoiceNav h2 {
  font-family: Inter;
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;


}

.invoiceNav .invoiceBtn {
  display: flex;
  align-items: center;
  gap: 16px;

}

.invoiceNav .invoiceCancel {
  width: 86px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  border: 1px solid #0B56E4;

}

.invoiceNav .invoiceCancel span {
  font-family: Inter;
  color: #0B56E4;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.invoiceForm {
  max-width: 1120px;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sinelinvoice {
  display: flex;
  gap: 30px;
  align-items: center;
}

.sinelinvoice label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.sinelinvoice label p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.sinelinvoice label input,
.sinelinvoice label select {
  max-width: 524px;
  width: 100%;
  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: 1px solid #D0D4DC;
  background: #F8F9FB;
  outline: none;

}

.sinelinvoice label {
  color: #1B2533;
  font-family: inter !important;
  font-weight: 400;
  font-size: 14px;
}

.norecord {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.allQui {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.allQui .invId {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;

}

.allQui .inName {
  font-family: Inter;
  color: #101820;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: right;


}

.gfg th {
  background-color: white !important;
  font-family: inter !important;
}

.allQui .date {
  font-family: Inter;
  color: #666D76;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: right;

}


.singlquot {
  display: flex;
  align-items: center;
  gap: 40px;
  border-left: 2px solid green;
  padding: 0px 0px 0px 16px;
}

.quaotitionBtn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.quaotitionBtn button {
  width: 118px;
  height: 40px;
  border-radius: 10px;

  background-color: #0B56E4;
}

.quaotitionBtn button span {
  font-family: Inter;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.fgg label {
  color: #1B2533 !important;
  font-weight: 500 !important;
}

.fggg {
  margin-left: 15px;
}

.fgg input,
.fgg select {
  color: #B8BBC0 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.soup {
  width: 61px;
  height: 28px;
  border: 1px solid #E45D3A !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FCEBE6;
}

.soup1 {
  width: 61px;
  height: 28px;
  border: 1px solid #189877 !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E8F5F1;
}

.soup1 p {
  color: #189877 !important;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.soup p {
  color: #E45D3A;
  font-size: 12px;
  font-weight: 400;
  font-family: inter !important;
}

.emp_sino {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.type_date select {
  width: 196px;
  height: 46px;
  border: 1px solid #969BA1 !important;
  background-color: #E8E9EB !important;
  outline: none !important;
  color: #1B2533 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  border-radius: 6px;
  margin-left: 20px;
}

.type_year select {
  width: 196px;
  height: 46px;
  border: 1px solid #969BA1 !important;
  background-color: #E8E9EB !important;
  outline: none !important;
  color: #1B2533 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  border-radius: 6px;
  margin-left: 10px;
}

.btn_export button {
  border: 1px solid #189877 !important;
  width: 99px !important;
  height: 44px !important;
  background-color: #E8F5F1;
  border-radius: 6px;
  margin-left: 10px;
  color: #189877;
  font-weight: 500;
  font-size: 16px;
  font-family: inter !important;
}

.htmj {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.htmj button {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.holm {
  height: 100vh !important;
  background-color: #4040404D;
}

.sijk {
  border-bottom: 1px solid #E8E9EB;
}

.gd {
  font-size: 16px;
  font-weight: 600;
  color: #1B2533;
  font-family: inter !important;
}

.ipsd {
  font-size: 14px;
  font-weight: 400;
  font-family: inter !important;
  color: #1B2533 !important;
}

.secnoveview nav p {
  font-family: Inter;
  color: #2B2B2B;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.navrighdiv {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.bulk {
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
  color: #ffffff;
}

.cancol {
  border: 1px solid #B8BBC0;
  color: #666D76 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: inter !important;
}

.holm1 {
  max-width: 493px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thj {
  margin-top: 60px;
}

.secnoveview {
  background-color: white;
  width: 50%;
  padding: 20px;

}


.genrt {
  font-family: inter !important;
  font-weight: 500;
  font-size: 14px;
}

.dj {
  display: flex;
  align-items: center;
}

.dli {
  margin-left: 30px;
}

.projectOverView {
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 10px;

  max-width: 650px;
  width: 100%;
}

.secnoveview nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  width: 100%;
}

.noteinput textarea {
  width: 100%;
  min-height: 104px;
  height: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;
  resize: none;
  outline: none;
  font-family: Inter;
  font-size: 14px;
  color: #2B2B2B;
  font-weight: 600;
  letter-spacing: 0.004em;

}

.attsavebtn {
  width: 65px;
  height: 40px;
  gap: 10px;
  border-radius: 10px;
  background: #0B56E4;
  margin: 20px 0px;
}

.attsavebtn span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: white;
}

.showoffercont {
  width: 100%;
  background: #FFFFFF;
  border-radius: 18px;
  padding: 20px;
  margin-top: 20px;
}

.showoffercont h2 {
  font-family: Inter;
  color: #101820;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;

}

.selectEmpl {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selectEmpl label {
  font-family: Inter;
  color: #1B2533;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.selectEmpl select {
  font-family: Inter;
  color: #1B2533;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;

}

.doSaveBtn {
  width: 70px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.projectOverView .cont {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.projectOverView .cont .proleft {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.projectOverView .cont .proleft p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;

}

.Decr h3 {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.embers h3 {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.allMEmb {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.snglme {
  display: flex;
  align-items: center;
  gap: 16px;
}

.snglme img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.snglme p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.Decr {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.embers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Decr p {
  color: #2B2B2B;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.allprogress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  margin-top: 30px;
}

.eachProgeer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.eachProgeer h4 {
  width: 100%;
  font-family: Inter;
  color: #101820;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.navbuttons {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.navbuttons button {
  width: 80px;
  height: 40px;
  gap: 10px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  color: #666D76;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;

}

.navbuttons span {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  color: #666D76;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;

}

.expennav {
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.expennav button {
  width: 138px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;
  border: 1px solid #0B56E4;

}

.expennav button span {
  font-family: Inter;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;

}

.expencont {
  width: 100%;
  background-color: white;
  padding: 10px;
}

.expensetable th {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #2B2B2B;
}

.expensetable td {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #2B2B2B;
}

.docInputs {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 20px;
  width: 100%;
  height: fit-content;
}

.docInputs label {
  display: flex;
  align-items: center;
  gap: 20px;

}

.docInputs label input {
  width: 415px;
  height: 44px;
  padding: 8px 16px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 1px solid #D0D4DC;

}

.docInputs label button {
  width: 100px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-family: inter;
  height: 44px;
  background: #0B56E4;


}

.todaywords {
  width: 100%;
}

.todaywords input {
  border: 1px solid #D0D4DC;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 10px;
  font-family: Inter;
  color: #B8BBC0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;

}

.expedat {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 10px;
}

.same_bar {
  position: fixed;
  top: 50px;
  z-index: 10000;
  display: none;
  background-color: white;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.previewiamges {
  background-color: red;
  max-height: 250px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #D0D4DC;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px;
  justify-content: space-between;
}

.previewiamges nav {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

}

.previewiamges img {
  width: 100%;
  height: 85%;
}

.lodmorebtns {
  width: 123px;
  height: 40px;
  border-radius: 10px;
  background: #0B56E4;

}

.lodmorebtns span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.allnotiftwrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 400px;
}

.allnotifywrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 400px;
}

.annoucewrapshow{
  width: 100%;
  background-color: white;
  margin: 20px 0px;
}
.annoucewrapshowcnt{
  max-width: 75vw;
  width: 100%;
}

.addpading{
  padding: 10px 0px;
}

.timelogscont{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
}

.timelogscont span{
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.005em !important;
  color: black !important;
}

@media only screen and (min-width:1000px) {
  .oppl {
    display: block !important;
  }
}

@media only screen and (max-width:1000px) {
  .same_bar {
    display: block !important;
  }

  .attend-ctiveWrap {
    flex-direction: column-reverse;
  }

  .hrLefThi {
    width: 100% !important;
    max-width: 100% !important;
  }



  .hrmActLeft {
    width: 100% !important;
    max-width: 100% !important;
  }

  .attend-ctiveWrap .hrmActRight {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width:900px) {
  .hrLefFir {
    flex-wrap: wrap !important;
  }

  .holiHead {
    padding: 0px 20px;
  }

  .lead_dash_card {
    flex-wrap: wrap;
    gap: 15px;
  }

  .sing111 {
    margin-left: 0px;
  }

  .sing11111 {
    margin-left: 0px;
  }

  .my_things {
    flex-direction: column;
    margin-top: 0px;
  }

  .table22 {
    margin-left: 0px;
  }

}

@media only screen and (max-width:680px) {
  .leadThings {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .leads_btn {
    flex-wrap: wrap;
    gap: 15px;
  }

  .lead_btn {
    margin-left: 0px;
  }

  .lead_content1 h2 {
    text-align: center;
  }

  .lead_content1 p {
    text-align: center;
  }

  .em {
    padding: 32px 20px;
  }

  .fiterWrap {
    flex-wrap: wrap !important;
    height: auto !important;
    justify-content: center;
    align-items: center !important;
  }
}

@media only screen and (max-width:450px) {
  .fiterWrap {
    flex-direction: column !important;
  }

  .fiterWrap h3 {
    margin-top: 15px;
  }

  .hrmsFri h2 {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

@media only screen and (max-width:400px) {
  .time_emp_desh_flex {
    gap: 15px;
  }

  .attend-ctiveWrap .markAttWrap {
    height: auto !important;
  }

  .attend-ctiveWrap .oficTime .singlTime {
    width: 55px !important;
    height: 55px !important;
  }

  .attend-ctiveWrap .oficTime .singlTime p {
    font-size: 20px !important;
  }

  .clockINOUTBtn svg {
    display: none !important;
  }

  .sixth-logo p {
    font-size: 12px;
  }

  .dFirLef h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .sixth-logo .bottom {
    display: none;
  }

}

.emailvalidinput {
  border: 1px solid red !important;
}


.uploadprowrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5px;
}

.uploadprowrap p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #101820;
  color: blue;
  text-decoration: underline;
}

.leadUpldProf {
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.FaUploadfds {
  cursor: pointer;
  color: #666D76;
  font-size: 23px;
}

.makethifles {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
}
.test_formis{
  margin-top: 100px;
}
.siubmitbtnlead {
  background-color: #0B56E4 !important;
  color: white !important;
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 18px !important;
  width: 120px !important;
  border: none !important;

}


.imagewrapleac {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B3CBF7;
  border-radius: 50%;
  background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
  margin-left: 11px;

}
.syss{
  margin-left: 2.5px !important;
}
.makeitsticky {
  padding: 10px 0px;
  /* position: fixed;
  left: 0;
  background-color: white;
  z-index: 10; */
  position: fixed;
  background: white;
  width: 100%;
  justify-content: end;
  left: 0;
  top: 81px;
  height: 81px;
}

.taskassignwrap{
  height: 100% !important;
 width: 100% !important;
 background-color: white;

}

.taskassignwrap2{
  height: 100% !important;
}

.lessfontweight{
  font-weight: 400 !important;
}

.addonefont{
  font-size: 15px !important;
}


.connected{
  background: #FFD22E;
  color: white;

}
.Nurturing{
  background-color: #FF8F2C;
  color: white;
}
.Qualified{
  background-color: #35ff3e;
  color: white;
}
.Unqualified{
  background-color: #3342FF;
  color: white;
}
.Converted{
  background-color: #FF31ED;
  color: white;
}
.Converteds{
  background-color: #692DFE;
  color: white;
}
.Newleadstatus{
  background-color: #2FE7FF;
  color: white;
}
.Junk{
  background-color: #FF3033;
  color: white;
}

.lessfontparas{
  font-weight: 400 !important;
  font-size: 14px !important;
}

.anDesWrap.makefdufsld {
  grid-column: 1 / -1; 
  width: 100%; 
}


.givethissowidth{
min-width: 50%;
}

.adclient_form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  max-height: 400px;
  padding: 0px 10px 0px 0px;
}

.adclient_form::-webkit-scrollbar {
  width: 1em;
}

.adclient_form::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

.adclient_form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #cccccc;
}

/* Awards button css is written here  */
.adminsetupBtn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family:  sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.adminsetupBtn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.adminsetupBtn:focus {
  outline: none;
}

.thredotwrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.threedot_lead{
  cursor: pointer;
  min-width: 25px;
  max-width: 25px;
  font: 30px;

}

.dropswrap{
  width: 100px;
  height: fit-content;
  background-color: white;
  box-shadow: -1px -1px 19px -9px rgba(0,0,0,0.75);
position: absolute;
bottom: 60px;
right: 30px;

}

.dropswrap p{
  display: flex;
  align-items: center;
  padding: 10px 5px;
  gap: 5px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.dropswrap p span{
  font-weight: 500 !important;
  font-family: Inter !important;
  font-size: 14px !important;
}

.dropswrap .lead_icon{
  font-size: 20px;
}

.opensharpopwra{
  background-color: rgb(0, 0, 0 , 0.4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

}

.opensharecont{
  background-color: white;
  width: 500px;
  max-height: 500px;
  height: fit-content;
  margin-top: 120px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
}


.opensharecont nav{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  
}
.opensharecont nav h2{
  font-size: 16px;
  font-weight: bold;
  font-family: inter !important;

}

.sharecross{
  font-size: 24px;
  cursor: pointer;
}


.popup_formdiv2{
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  padding: 0px 10px 0px 0px;
  /* height: ; */
}

.popup_formdiv2::-webkit-scrollbar {
  width: 1em;
}

.popup_formdiv2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

.popup_formdiv2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #cccccc;
}



.allowoverflow{
  overflow-x: scroll !important;
}


.allquotation_formtate{
  background-color: white;
  width: 100%;
  margin-top: 80px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.allquotation_formtate button{
  background: #0B56E4;
  width: fit-content;
border-radius: 6px;
padding: 5px 14px;  
}
.addedontihisbut{
  background: #0B56E4;
  width: fit-content;
border-radius: 6px;
padding: 5px 14px;  
}


.addedontihisbut span{
  color: white;
}


.notoherbutn{
  border: 1px solid #0B56E4 !important;
  background-color: transparent !important;
}
.notoherbutn span{
  color: #0B56E4 !important;
}

.totalCI_charges{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.totalCI_charges label{
 display: grid;
 grid-template-columns: repeat(2 , 0.2fr);
 gap: 10px;
 
}
.totalCI_charges label p{
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: black;
}
.totalCI_charges label input{
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: black;
  border-radius: 10px;
  width: 180px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #6A6A6A;
  padding: 0px 10px;
 

}



.previ_wrap {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  height: fit-content;
  display: flex;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  z-index: 1000;
}
.previ_wrap2 {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height:100%;
  height: fit-content;
  display: flex;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  z-index: 1000;
}

.prev_quotarioncont {
  max-width: 900px;
  width: 100%;
  height: fit-content;
  padding-bottom: 30px;
  background-color: white;
}


.qutaRightcont {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px;


}


.quota2_ref{
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-end;
}
.quota2_ref p{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.qut1_to{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.qut1_to p{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.quot2_dear{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.quot2_dear p{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.quot2_dear h4{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}


.talbeLike {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
}

.talbeLike nav {
  background: #0B56E3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
}

.talbeLike nav p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: #FFFFFF;
}


.aubdisolpara{
  background-color: orange;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  transform: translateY(22px);
}
.aubdisolpara2{
  background-color: #c00000;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  transform: translateY(22px);
}
.aubdisolpara3{
  background-color: black;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  transform: translateY(22px);
}


.quuo2_table_head {
  border: 1px solid black;
}
.quuo2_table_head2 {
  border: 1px solid black;
}


.quuo2_table_head th {
  background: rgb(64 ,  64 , 64);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  min-width: 50px;
  line-height: 22px;
  color: white !important;
  border: 1px solid black !important;

}
.quuo2_table_head2 th {
  background: gray;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  min-width: 50px;
  line-height: 22px;
  color: white !important;
  border: 1px solid black !important;

}

.quotabletr2 td {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #101820;
  border: 1px solid black !important;
}


.prewid1{
  width: 10px;
}
.prewid2{
  width: 80px;

}
.prewid3{
  width: 50px;

}
.prewid4{
  width: 60px;

}

.prewid5{
  width: 40px;
}


.quo1_termandcond{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.quo1_termandcond h4{
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.quo1_termandcond p{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0015em;
}
.quo1_extrapra{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0015em;
}


.totalcai_cont{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border: 1px solid black;
}

.totalcai_cont p{
  border: 1px solid black;
  padding: 8px 5px;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: black;
}

.eachprepara{
  border-bottom: 1px solid black;
  padding: 5px 5px;
}


.addsomethin1{
  padding: 0px !important;
}


.quotabl_wrap{
  overflow-x: scroll;
}

.shareopen{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: white;
  width: 270px;
  height: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
border-radius: 10px;
 overflow-y: scroll;
 display: flex;
 flex-direction: column;
 gap: 5px;
 padding: 10px 14px;
align-items: flex-start;

}

.singluser{
  display: flex;
  align-items: center;
  gap: 10px;
 
}

.sendbtns{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sendbtns button{
  background-color: #0056b3;
   color:white;
    width: 100px;
    height: 34px;
    border-radius: 10px;
    padding: 0px 10px;
  font-weight: 500;
}


.leanwlonwers{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.totalctc{
  width: 100%;
  display:flex;
  align-items: flex-end;
  padding: 10px 0px;
flex-direction: column;
}

.totalctc p{
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 500 !important;
}