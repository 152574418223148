.qutaWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* justify-content: space-between; */
}

.qutaLeft {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.allempid {
    display: flex;
    align-items: center;
    gap: 14px;
    flex-wrap: wrap;
}

.allwhitebg {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    margin-top: 100px;
}

.qutaRight {
    max-width: 900px;
    width: 100%;
    height: fit-content;
    padding-bottom: 30px;
    background-color: white;
    margin-top: 900px;
}

.techstackwrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.techstackwrap h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.allrows2wrao{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.costestimate h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.techsstack{
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.allstack{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.snglstack{
    display: flex;
    align-items: center;
    gap: 4px;
}
.snglstack p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #101820;
    
}
.snglstack span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #101820;
    
}

.userAppdds h2, h1,p,strong,span{
    font-family: Inter !important;
    color: #101820;
}
.techsstack h2{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101820;
}
.makeitflexri{
    display: flex;
    align-items: center;
    gap: 30px;
}
.givfulwidt{
    width: 300px;
}
.givfulwidt input{
    width:300px !important;
}
.givfulwidt2{
    width: 500px;
}
.givfulwidt2 input{
    width:500px !important;
}
.sinproid {
    display: flex;
    align-items: center;
    gap: 4px;
}

.sinproid p {
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

}

.qutLTo {
    display: flex;
    align-items: center;
    justify-content: end;
    position: fixed;
    left: 0;
    width: 100%;
    top: 80px;
    height: 80px;
    background-color: white;
    z-index: 1;
}
.expensepop{
    height: 600px !important;
    overflow-y: scroll !important;
   
    margin-bottom: 200px;
   
   
}
.extpo{
    height: 450px !important;
}
.allInputFileds label input, select{
    width: 100% !important;
}
.openform{
    max-width: 630px !important;
    width: 100% !important;
    position: fixed !important;
    margin-left: 15px;
    top: 0;
}


.lableawaiwrap{
    gap: 20px !important;
}
.lableawaiwrap label{
    margin-bottom: 15px !important;
}
.lableawaiwrap label p{
    font-size: 14px !important;
}
.lableawaiwrap label select {
    font-family: inter !important;
    font-size: 14px !important;
}
.lableawaiwrap label input{
    font-size: 14px !important;
}
.labwrapp{
    width: 100% !important;
    justify-content: center !important;
    gap: 60px !important;
}
.qutolobutons {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
}

.qutolobutons button {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;

}

.qutLTo h2 {
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.cnebuqo {
    background: #E8E9EB !important;
    border: 1px solid #B8BBC0 !important;
    color: #666D76 !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;


}

.qutLTo button {
    width: 90px;
    height: 40px;
    border-radius: 10px;
    background-color: #0B56E4;
}

.qutLTo button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.previwwraps {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-y: scroll;
}

.previwwraps::-webkit-scrollbar{
    display: none;
}

.qtoform {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 20px 40px;

}

.qtoform label {
    display: flex;
    align-items: center;
    max-width: 520px;
    gap: 30px;

}

.qtoform label p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: right;
    width: 240px;
    color: #1B2533;
    padding-bottom: 10px;
}

.adddbunelogo {
    display: flex;
    max-width: fit-content;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    background-color: #f7faff;
    margin-left: 20px;

}

.adddbunelogo p {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.0015em;
    text-align: center !important;
}

.comapnydetail {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comdetail {
    color: #000000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.qtoform label input {
    max-width: 300px;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #B4C2D5;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
}

.admorewrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.admorCont {
    display: flex;
    align-items: center;
    gap: 8px;
}

.admorCont span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.image {
    width: 198px;
    height: 86px;
    padding: 14px 26px;
    background: #F7FAFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

}

.quotablle {
    max-width: 100%;
    width: 100%;
}

.quotablle2 {
    width: 100%;
}

.theadqu {
    background: #ECF2FF;

}

.theadqu th {
    background: #ECF2FF !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: black !important;
}

.theadqu2 {
    background: #0B56E3;

}

.theadqu2 th {
    background: #0B56E3 !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: white !important;

}

.tabletr input {
    width: 180px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #6A6A6A;
    padding: 0px 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #101820;
}

.tabletr2 td {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.tabletr .inpu11 {
    width: 340px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #6A6A6A;
}


.qutaRightcont {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 20px;


}

.qrTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qrTop h3 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.hdquot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
}

.hdquot p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101820;

}

.hdquot img {
    width: 200px;
    height: 100px;
}

.cust {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #0B56E4;
}

.gridView {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 20px;
    width: 100%;
}

.gridView div {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.gridView p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #101820;
    width: 140px;
    /* text-align: right; */
    /* background-color: orange; */
}

.gridView span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: #2B2B2B;
}

.talbeLike {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
}

.talbeLike nav {
    background: #0B56E3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
}

.talbeLike nav p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    color: #FFFFFF;
}

.tabledata {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;

}

.tabledata p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #101820;
    text-align: center;
}

.services {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.services li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    color: #101820;
}

.userApp {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.userApp h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ulist li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ollist li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ollist span {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;

}

.rigthfot {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.rigthfotleft {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 270px;
}

.rigthfotleft .leftfist {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.rigthfotleft .seconle {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #101820;
}

.rigthfotleft .thirleft {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    line-height: 30px;
    color: #101820;
    max-width: 250px;

}

.rigthfotleft .thirleft span {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    color: #101820;

}

.rigthfotright {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 300px;
}

.rigthfotright p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B2B2B;
}

.prntBtn {
    width: 100%;
    display: flex;
    margin: 20px 0px 0px -20px;
    justify-content: flex-end;
    gap: 20px;

}

.prntBtn button {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.prntBtn button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

/* for task management  */
.tclwrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tclwrap2 {
    position: relative;
    display: flex;
    gap: 20px;
}

.tclwrap nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tclwrap nav h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.tclwrap nav .clibtns {
    display: flex;
    align-items: center;
    gap: 16px;

}

.clibtns .newcli {
    width: 137px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    background: #0B56E4;


}

.clibtns .impcli {
    width: 133px;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
    border: 1px solid #0B56E4;

}

.clibtns .expoclient {
    width: 133px;
    height: 40px;
    background: #E8E9EB;
    border-radius: 8px;
    border: 1px solid #B8BBC0;


}

.clibtns .backpro {
    width: 70px;
    height: 40px;
    background: #E8E9EB;
    border-radius: 8px;
    border: 1px solid #B8BBC0;


}

.clibtns .expoclient span {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.pronaheading {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pronaheading h2 {
    text-transform: uppercase;
}

.clibtns .backpro span {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.clibtns .impcli span {
    font-family: Inter;
    color: #0B56E4;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
}

.clibtns .newcli span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.allClients {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.avatarclient {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #F3F3F4 !important;

}

.singleProject {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 32%;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
    border-radius: 12px;
    position: relative;
}

.singleclient2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 275px;
    padding: 12px 30px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
    border-radius: 12px;
    position: relative;
}

.singleclient2 .navdiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.singleclient2 h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #1B2533;
}

.singleclient2 p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #666D76;
}

.showIndexcont {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 13px;
    left: 60px;
    top: 20px;
    width: 161px;
    height: 148px;
    padding: 8px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 12px 0px #1A1A1A33;

}

.showIndexcont2 {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 13px;
    right: 30px;
    top: 20px;
    width: 201px;
    /* height: 240px; */
    height: 95px;
    padding: 8px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 12px 0px #1A1A1A33;

}

.singlinpro {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

}

.singlinpro span {
    font-family: Inter;
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;

}

.singlinpro .delspan {
    color: #DD3409;

}

.addCliWrap {
    background: #40404066;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3000;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addClieCont {
    width: 599px;
    padding: 24px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 18px;
    background-color: white;

}

.noteInput {
    border: 1px solid #D0D4DC;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    outline: none;
}

.fitheight {
    height: fit-content !important;
}

.addClieCont3 {
    width: 599px;
    padding: 24px;
    height: 464px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 18px;
    background-color: white;
}

.addheight {
    min-height: 400px !important; 
    height: fit-content; 
   
}
/* mediaquery for form  */
.addClieCont nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addClieCont3 nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addClieCont nav p {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.addClieCont3 nav p {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.addClieCont label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

}

.addClieCont label select {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;


}

.addClieCont label textarea {
    width: 100%;
    height: 64px;
    resize: none;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;
    outline: none;

}

.addClieCont form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    height: 450px;

}

.addClieCont label p {
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;

}

.addClieCont label input {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;

}

.addClieCont .citstateCont {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.addClieCont .btnsss {

    display: flex;
    align-items: center;
    gap: 16px;
    /* margin: 20px 0px; */
    /* margin-bottom: 10px; */
}

.addClieCont .btnsss .saveclient {
    width: 119px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.addClieCont .btnsss .saveclient span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;

}

.addClieCont .btnsss .cancel {
    width: 86px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #B8BBC0;

}

.addClieCont .btnsss .cancel span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #666D76;

}

.selectOption {
    width: 338px;
    height: 42px;
    display: flex;
    align-items: center;

}

.singelPr {
    width: fit-content;
    height: 100%;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
}

.singelPr span {
    color: #777777;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;

}

.addBorder {
    border-radius: 0px 8px 8px 0px;
}

.addlefborder {
    border-radius: 8px 0px 0px 8px;
}

.adddbg {
    background: #3C78E9;
    border: 1px solid #0B56E4;
}

.adddbg span {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;

}

.projnav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.projnav .leftnav {
    display: flex;
    gap: 8px;
    align-items: center;
}

.projnav .leftnav p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    color: #1B2533;
}

.statusdue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.stapro {
    width: 68px;
    height: 28px;
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.stapro span {
    font-family: Inter;
    color: #121212;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.004em;

}

.finibg {
    background-color: #30D158;
}

.OnHoldbg {
    background-color: red;
    color: white !important;
}

.onHoldText {
    color: white !important;
}

.Ongoingbg {
    background-color: #F7A539;
}

.duedate {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;

}

.duedate span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;


}

.prodlefriwrap {
    width: 100%;
    height: 96px;
    padding: 24px 24px;
    border-radius: 12px;
    background: var(--Green-Green-40, #DEF8E4);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftprodetail {
    display: flex;
    align-items: center;
    gap: 60px;
}

.leftprodetail label {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.leftprodetail label .filn {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #666D76;
}

.leftprodetail label .proand {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #1B2533;
}

.righprodetail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.righprodetail div {
    width: 32px;
    height: 32px;
    background: var(--General-General-White, #FFFFFF);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.propara {
    text-align: left;
    max-width: 340px;
    width: 100%;
}

.propara p {

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    width: 100%;
    color: #666D76;
}

.protasjwon {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.mem {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;

}

.mem p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.proteast {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.uploadClient {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.uploadClient .topUpclien {
    display: flex;
    align-items: center;
    gap: 10px;
}

.uploadClient .topUpclien span {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #2B2B2B;
}

.chosefilesec {
    width: 100%;
    height: 184px;
    padding: 40px 0px;
    border-radius: 12px;
    background: #F8F9FB;
    border: 1px solid #8FB1F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.chosefilesec p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #666D76;
}

.chosefilesec button {
    width: 164px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.chosefilesec button span {
    font-family: Inter;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.uplbtns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.uplbtns .up {
    width: 87px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.uplbtns .up span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: white;

}

.uplbtns .clos {
    width: 76px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #B8BBC0;
    border-radius: 10px;

}

.uplbtns .clos span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #666D76;
}

/* for propsal  */
.promtopde {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
}

.promtopde label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}

.promtopde label h4 {
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
}

.promtopde label p {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
}

.logokushelquot{
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
}

.proprsaltitle{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: center;
margin-top: 50px;
margin-bottom:30px;

}

.procesforwar{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.procesforwar h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.procesforwar ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.procesforwar ul li{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0025em;
    text-align: left;
  color: #2B2B2B;
    
}

.wehoemesg{
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0.0015em;
text-align: left;
color: #1B2533;
}

.thaksmessge{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.thaksmessge h4{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: left;
color: #1B2533;

}

.frommesage{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.frommesage p,.frommesage span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
    
}

.frommesage span{
    color: #0B56E3;
}

.preparequtotion{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: flex-end;
}

.preparequtotion .presec{
    display: flex;
    flex-direction: column;
}
.preparequtotion .presec p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.0015em;
    text-align: right;
    color: #1B2533;
}
.preparequtotion .presec span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.0015em;
    text-align: right;
    color:#0B56E4;
}

.quot_contentt{
    display:flex;
    flex-direction: column;
    gap: 20px;
}

.qoformfooter{
width: 100%;
display: flex;
flex-direction: column;
gap: 5px;
align-items: center;
padding: 30px 0px;
}

.qoformfooter p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: right;
    color:black;
}

.qoformfooter span{
    color: #0B56E3;
}


.qutaWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* justify-content: space-between; */
}

.qutaLeft {
    max-width: calc(100vw - 330px);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.allempid {
    display: flex;
    align-items: center;
    gap: 14px;
    flex-wrap: wrap;
}

.allwhitebg {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    /* max-width: 500px; */
    /* margin-top: 100px; */
}

.qutaRight {
    max-width: 900px;
    width: 100%;
    height: fit-content;
    padding-bottom: 30px;
    background-color: white;
    margin-top: 900px;
}

.techstackwrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.techstackwrap h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.allrows2wrao{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.costestimate h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.techsstack{
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.allstack{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.snglstack{
    display: flex;
    align-items: center;
    gap: 4px;
}
.snglstack p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #101820;
    
}
.snglstack span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #101820;
    
}

.userAppdds h2, h1,p,strong,span{
    font-family: Inter !important;
    color: #101820;
}
.techsstack h2{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101820;
}
.makeitflexri{
    display: flex;
    align-items: center;
    gap: 30px;
}
.givfulwidt{
    width: 300px;
}
.givfulwidt input{
    width:300px !important;
}
.givfulwidt2{
    width: 500px;
}
.givfulwidt2 input{
    width:500px !important;
}
.sinproid {
    display: flex;
    align-items: center;
    gap: 4px;
}

.sinproid p {
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

}

.qutLTo {
    display: flex;
    align-items: center;
    justify-content: end;
    position: fixed;
    left: 0;
    width: 100%;
    top: 80px;
    height: 80px;
    background-color: white;
    z-index: 1;
}
.expensepop{
    height: 600px !important;
    overflow-y: scroll !important;
}
.allInputFileds label input, select{
    width: 100% !important;
}
.openform{
    max-width: 630px !important;
    width: 100% !important;
    position: sticky !important;
    top: 0;
}


.lableawaiwrap{
    gap: 20px !important;
}
.lableawaiwrap label{
    margin-bottom: 15px !important;
}
.lableawaiwrap label p{
    font-size: 14px !important;
}
.lableawaiwrap label select {
    font-family: inter !important;
    font-size: 14px !important;
}
.lableawaiwrap label input{
    font-size: 14px !important;
}
.labwrapp{
    width: 100% !important;
    justify-content: center !important;
    gap: 60px !important;
}
.qutolobutons {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
}

.qutolobutons button {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;

}

.qutLTo h2 {
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.cnebuqo {
    background: #E8E9EB !important;
    border: 1px solid #B8BBC0 !important;
    color: #666D76 !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;


}

.qutLTo button {
    width: 90px;
    height: 40px;
    border-radius: 10px;
    background-color: #0B56E4;
}

.qutLTo button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.previwwraps {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-y: scroll;
}

.previwwraps::-webkit-scrollbar{
    display: none;
}

.qtoform {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 20px 40px;

}

.qtoform label {
    display: flex;
    align-items: center;
    max-width: 520px;
    gap: 30px;

}

.qtoform label p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: right;
    width: 240px;
    color: #1B2533;
    padding-bottom: 10px;
}

.adddbunelogo {
    display: flex;
    max-width: fit-content;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    background-color: #f7faff;
    margin-left: 20px;

}

.adddbunelogo p {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.0015em;
    text-align: center !important;
}

.comapnydetail {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comdetail {
    color: #000000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.qtoform label input {
    max-width: 300px;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #B4C2D5;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
}

.admorewrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.admorCont {
    display: flex;
    align-items: center;
    gap: 8px;
}

.admorCont span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.image {
    width: 198px;
    height: 86px;
    padding: 14px 26px;
    background: #F7FAFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

}

.quotablle {
    max-width: 100%;
    width: 100%;
}

.quotablle2 {
    width: 100%;
}

.theadqu {
    background: #ECF2FF;

}

.theadqu th {
    background: #ECF2FF !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: black !important;
}

.theadqu2 {
    background: #0B56E3;

}

.theadqu2 th {
    background: #0B56E3 !important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: white !important;

}

.tabletr input {
    width: 180px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #6A6A6A;
    padding: 0px 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #101820;
}

.tabletr2 td {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.tabletr .inpu11 {
    width: 340px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid #6A6A6A;
}


.qutaRightcont {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 20px;


}

.qrTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qrTop h3 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.hdquot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
}

.hdquot p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101820;

}

.hdquot img {
    width: 200px;
    height: 100px;
}

.cust {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #0B56E4;
}

.gridView {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 20px;
    width: 100%;
}

.gridView div {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.gridView p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #101820;
    width: 140px;
    /* text-align: right; */
    /* background-color: orange; */
}

.gridView span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: #2B2B2B;
}

.talbeLike {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
}

.talbeLike nav {
    background: #0B56E3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
}

.talbeLike nav p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    color: #FFFFFF;
}

.tabledata {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;

}

.tabledata p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #101820;
    text-align: center;
}

.services {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.services li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    color: #101820;
}

.userApp {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.userApp h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ulist li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ollist li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.ollist span {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;

}

.rigthfot {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.rigthfotleft {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 270px;
}

.rigthfotleft .leftfist {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #101820;
}

.rigthfotleft .seconle {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #101820;
}

.rigthfotleft .thirleft {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    line-height: 30px;
    color: #101820;
    max-width: 250px;

}

.rigthfotleft .thirleft span {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    color: #101820;

}

.rigthfotright {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 300px;
}

.rigthfotright p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B2B2B;
}

.prntBtn {
    width: 100%;
    display: flex;
    margin: 20px 0px 0px -20px;
    justify-content: flex-end;
    gap: 20px;

}

.prntBtn button {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.prntBtn button span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

/* for task management  */
.tclwrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tclwrap2 {
    position: relative;
    display: flex;
    gap: 20px;
}

.tclwrap nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tclwrap nav h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.tclwrap nav .clibtns {
    display: flex;
    align-items: center;
    gap: 16px;

}

.clibtns .newcli {
    width: 137px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    background: #0B56E4;


}

.clibtns .impcli {
    width: 133px;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
    border: 1px solid #0B56E4;

}

.clibtns .expoclient {
    width: 133px;
    height: 40px;
    background: #E8E9EB;
    border-radius: 8px;
    border: 1px solid #B8BBC0;


}

.clibtns .backpro {
    width: 70px;
    height: 40px;
    background: #E8E9EB;
    border-radius: 8px;
    border: 1px solid #B8BBC0;


}

.clibtns .expoclient span {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.pronaheading {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.pronaheading h2 {
    text-transform: uppercase;
}

.clibtns .backpro span {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.clibtns .impcli span {
    font-family: Inter;
    color: #0B56E4;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
}

.clibtns .newcli span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.allClients {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.avatarclient {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #F3F3F4 !important;

}

.singleProject {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 373px;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
    border-radius: 12px;
    position: relative;
}

.singleclient2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: 275px;
    padding: 12px 30px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
    border-radius: 12px;
    position: relative;
}

.singleclient2 .navdiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.singleclient2 h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #1B2533;
}

.singleclient2 p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #666D76;
}

.showIndexcont {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 13px;
    left: 60px;
    top: 20px;
    width: 161px;
    height: 148px;
    padding: 8px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 12px 0px #1A1A1A33;

}

.showIndexcont2 {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 13px;
    right: 30px;
    top: 20px;
    width: 201px;
    /* height: 240px; */
    height: 95px;
    padding: 8px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 12px 0px #1A1A1A33;

}

.singlinpro {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

}

.singlinpro span {
    font-family: Inter;
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;

}

.singlinpro .delspan {
    color: #DD3409;

}

.addCliWrap {
    background: #40404066;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3000;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addClieCont {
    width: 599px;
    padding: 24px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 18px;
    background-color: white;

}

.noteInput {
    border: 1px solid #D0D4DC;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    outline: none;
}

.fitheight {
    height: fit-content !important;
}

.addClieCont3 {
    width: 599px;
    padding: 24px;
    height: 464px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 18px;
    background-color: white;
}

.addheight {
    min-height: 652px !important;
    height: fit-content;
}

.addClieCont nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addClieCont3 nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addClieCont nav p {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.addClieCont3 nav p {
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;

}

.addClieCont label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

}

.addClieCont label select {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;


}

.addClieCont label textarea {
    width: 100%;
    height: 64px;
    resize: none;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;
    outline: none;

}

.addClieCont form {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.addClieCont label p {
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;

}

.addClieCont label input {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;

}

.addClieCont .citstateCont {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.addClieCont .btnsss {

    display: flex;
    align-items: center;
    gap: 16px;
    margin: 20px 0px;
}

.addClieCont .btnsss .saveclient {
    width: 119px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.addClieCont .btnsss .saveclient span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #FFFFFF;

}

.addClieCont .btnsss .cancel {
    width: 86px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #B8BBC0;

}

.addClieCont .btnsss .cancel span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #666D76;

}

.selectOption {
    width: 338px;
    height: 42px;
    display: flex;
    align-items: center;

}

.singelPr {
    width: fit-content;
    height: 100%;
    padding: 10px 16px;
    background: #FFFFFF;
    border: 1px solid #E8E9EB;
}

.singelPr span {
    color: #777777;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;

}

.addBorder {
    border-radius: 0px 8px 8px 0px;
}

.addlefborder {
    border-radius: 8px 0px 0px 8px;
}

.adddbg {
    background: #3C78E9;
    border: 1px solid #0B56E4;
}

.adddbg span {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;

}

.projnav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.projnav .leftnav {
    display: flex;
    gap: 8px;
    align-items: center;
}

.projnav .leftnav p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    color: #1B2533;
}

.statusdue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.stapro {
    width: 68px;
    height: 28px;
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.stapro span {
    font-family: Inter;
    color: #121212;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.004em;

}

.finibg {
    background-color: #30D158;
}

.OnHoldbg {
    background-color: red;
    color: white !important;
}

.onHoldText {
    color: white !important;
}

.Ongoingbg {
    background-color: #F7A539;
}

.duedate {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;

}

.duedate span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;


}

.prodlefriwrap {
    width: 100%;
    height: 96px;
    padding: 24px 24px;
    border-radius: 12px;
    background: var(--Green-Green-40, #DEF8E4);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftprodetail {
    display: flex;
    align-items: center;
    gap: 60px;
}

.leftprodetail label {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.leftprodetail label .filn {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #666D76;
}

.leftprodetail label .proand {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #1B2533;
}

.righprodetail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.righprodetail div {
    width: 32px;
    height: 32px;
    background: var(--General-General-White, #FFFFFF);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.propara {
    text-align: left;
    max-width: 340px;
    width: 100%;
}

.propara p {

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    width: 100%;
    color: #666D76;
}

.protasjwon {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.mem {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;

}

.mem p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.proteast {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
}

.uploadClient {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.uploadClient .topUpclien {
    display: flex;
    align-items: center;
    gap: 10px;
}

.uploadClient .topUpclien span {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #2B2B2B;
}

.chosefilesec {
    width: 100%;
    height: 184px;
    padding: 40px 0px;
    border-radius: 12px;
    background: #F8F9FB;
    border: 1px solid #8FB1F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.chosefilesec p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #666D76;
}

.chosefilesec button {
    width: 164px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.chosefilesec button span {
    font-family: Inter;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;

}

.uplbtns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

.uplbtns .up {
    width: 87px;
    height: 40px;
    border-radius: 10px;
    background: #0B56E4;

}

.uplbtns .up span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: white;

}

.uplbtns .clos {
    width: 76px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #B8BBC0;
    border-radius: 10px;

}

.uplbtns .clos span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #666D76;
}

/* for propsal  */
.promtopde {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    width: 100%;
}

.promtopde label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}

.promtopde label h4 {
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
}

.promtopde label p {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
}

.logokushelquot{
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
}

.proprsaltitle{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: center;
margin-top: 50px;
margin-bottom:30px;

}

.procesforwar{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.procesforwar h2{
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #101820;

}

.procesforwar ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.procesforwar ul li{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0025em;
    text-align: left;
  color: #2B2B2B;
    
}

.wehoemesg{
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0.0015em;
text-align: left;
color: #1B2533;
}

.thaksmessge{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.thaksmessge h4{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: left;
color: #1B2533;

}

.frommesage{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.frommesage p,.frommesage span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #1B2533;
    
}

.frommesage span{
    color: #0B56E3;
}

.preparequtotion{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: flex-end;
}

.preparequtotion .presec{
    display: flex;
    flex-direction: column;
}
.preparequtotion .presec p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.0015em;
    text-align: right;
    color: #1B2533;
}
.preparequtotion .presec span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.0015em;
    text-align: right;
    color:#0B56E4;
}

.quot_contentt{
    display:flex;
    flex-direction: column;
    gap: 20px;
}

.qoformfooter{
width: 100%;
display: flex;
flex-direction: column;
gap: 5px;
align-items: center;
padding: 30px 0px;
}

.qoformfooter p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: right;
    color:black;
}

.qoformfooter span{
    color: #0B56E3;
}

