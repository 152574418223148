.annDash {
    display: flex;
    background-color: #f0f6ff;
    position: relative;
    min-height: 100vh;
}

.anNav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.halfLabel {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 250px;
    width: 100%;
}

.halfLabel p {
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;

}

.halfLabel input {
    width: 100%;
    height: 44px;
    padding: 8px 16px 8px 16px;
    gap: 8px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #D0D4DC;
    background: #FFFFFF;


}

.halfLabel select {
    width: 100%;
    height: 44px;
    padding: 8px 16px 8px 16px;
    gap: 8px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #D0D4DC;
    background: #FFFFFF;



}

.anMain {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 10px 20px;
}

.anmainTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.anMaRi{
    width:  247pxpx;
height:  44pxpx;
padding: 8px 16px 8px 16px;
gap: 8px;
border-radius: 10px;
background: #FFFFFF;
border: 1px solid #D0D4DC;
display: flex;
align-items: center;
}

.anMLef{
font-family: Inter;
color: #1B2533;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.0015em;
text-align: left;

}
.anMaRi input{
    font-family: Inter;
    color: #666D76;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: left;
    
}

.annFormwrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 50px 0px;
    align-items: center;
    justify-content: center;
}

.annFormwrap2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 50px 0px;
    align-items: center;
    justify-content: center;
}



.allInputFileds {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 50px;
    overflow-y: auto;
    height: 400px;
}

.allInputFileds .halfLabel {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.allInputFileds .fullLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.allInputFileds .anotheLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.allInputFileds .anotheLabel h2 {
    font-family: inter !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;

}

.allInputFileds .anotheLabel .anWrap {
    display: flex;
    align-items: center;
    max-width: 400px;
    padding: 5px 0px;
    justify-content: space-between;
}

.allInputFileds .anotheLabel .anWrap p {
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #293240;

}

.allInputFileds .anotheLabel .rating {
    display: flex;
    gap: 5px;
    align-items: center;


}

.allInputFileds .fullLabel select {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #293240;
    padding: 10px;
}

.allInputFileds .fullStars {
    width: 100%;
    height: 44px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.allInputFileds .fullLabel input {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #293240;
    padding: 10px;
}

.allInputFileds .fullLabel textarea {

    padding: 10px;
    border: 1px solid black;
    height: 130px;
}

.allInputFileds .halfLabel select {
    width: 354px;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #293240;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #D0D4DC;
}

.anDesWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.anDesWrap p {

    font-family: inter !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
}

.anDesWrap textarea {
    width: 100%;
    height: 71px;
    resize: none;
    border-radius: 3px;
    border: 1px solid #293240;
    padding: 10px;

}



.showText {
    padding: 50px 0px;
    font-family: inter !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.noEntries {
    width: 90%;
    position: absolute;
    overflow: hidden;
    text-align: center;
    margin-top: 10px;
}

.noEntries span {
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.indictortable th{
    background-color: transparent !important;
}

.indith{
font-family: Inter;
color: #2B2B2B;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;

}

.inditd{
font-family: Inter;
color: #40444B;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.004em;
text-align: left;

}

.viewOne2 {
    position: absolute;
    top: 40px;
    min-width: 131px;
    height: 108px;
    border: 1px solid #E3E3E3;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 12px 0px #1A1A1A33;
    padding: 8px 0px 8px 0px;
    gap: 10px;
    border-radius: 8px 0px 0px 0px;
    z-index: 20;
    border: 1px 0px 0px 0px;
    background-color: white;
    right: 40px;
}