.myselfNav{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.myselfNav h2{
    font-family: Inter;
    color: #101820;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    
}
.myselfNav select{
    width: 164px;
    height: 40px;    
    border: 1px solid #0B56E4;
    background: linear-gradient(131.78deg, #D1E8FD 6.87%, #EDEFFF 91.67%);
font-family: Inter;
color: #0B56E4;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.005em;
text-align: left;
outline: none;
    
}

.manem{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.myselfFirst{
    width: 100%;
/* height: 260px; */
padding: 20px 20px 30px 20px ;
border-radius: 18px ;
background: #FFFFFF;
border: 1px solid #E8E9EB;
display: flex;
flex-direction: column;
gap: 18px;
}

.myselfFirst h3{
    font-family: Inter;
    color: #101820;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
    
}

.allFristDe3tail{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 22px;
}

.singfirst{
    display: flex;
    grid-template-columns: repeat(2,1fr);

}

.adwwith{
     width: 220px;
     justify-content: space-between;

}
.singfirst p{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
width: 140px;
    text-align: left;
}
.singfirst span{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
}
.singfirst1{
    display: flex;
    grid-template-columns: repeat(2,1fr);

}
.singfirst1 p{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
width: 160px;
    text-align: left;
}
.singfirst1 span{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
}
.singfirst2{
    display: flex;
    grid-template-columns: repeat(2,1fr);

}
.singfirst2 p{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
width: 240px;
    text-align: left;
}
.singfirst2 span{
    font-family: Inter;
    color: #1B2533;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
}

.allFristDe3tail2{
    width: 100%;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.singleDoc{

    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.singleDoc h2{

    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: left;
color: #1B2533;    
}
.singleDoc img{
 max-width: 200px;
 max-height: 200px;  
}


.sidocLeft{
    display: flex;
    gap: 16px;
    align-items: center;

}

.ffwrap{
    display: flex;
    flex-direction: column;
    gap:4px;
}
.ffwrap .ff{
    font-family: Inter;
    color: #1B2533;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    
}
.ffwrap .dd{
    color: #666D76;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    
}

.singDocRight{
    color: #1B2533;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.0025em;
text-align: left;

}

.reqcahgng{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.rqbtn{
    width: 156px;
height: 40px;
border-radius: 10px;
background: #0B56E4;

}

.rqbtn span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
color: #FFFFFF;    

}